import React from "react";
import { get, post } from "../../libs/utils/request";
import { Button, message } from "antd";
import moment from "moment";
import User from "../../user/User";
import ReactGA from "react-ga4";

interface Props {
  match?: any; // TODO: any
  history?: any;
}
interface State {
  duration: any;
}
const trackingId = "G-G5B6N29N50"; // Replace with your Google Analytics tracking ID
ReactGA.initialize(trackingId);
const eventTrack = (category: string, action: string, label: string) => {
  ReactGA.event({
    category: category,
    action: action,
    label: label,
  })
}
class NotActivatedPage extends React.Component<Props> {
  state: State = {
    duration: null,
  };

  async check() {
    try {
      const user = await get<any>("/me");
      if (user.active === true && user.trial === false) {
        await sessionStorage.setItem("user", JSON.stringify(user));
        this.props.history.push("/profile");
      }
    } catch (e) { }
  }

  async resendEmail() {
    try {
      const response = await post<any>("/register/resendEmail");
      message.success(response.message);
    } catch (e) {
      message.error(e.response?.data?.message || "Network error");
    }
  }

  async continue() {
    try {
      if (localStorage.getItem("url")) {
        window.location.replace(localStorage.getItem("url"));
      } else {
        window.location.replace(
          window.location.protocol + "//" + window.location.host + "/dashboard"
        );
      }
    } catch (e) {
      message.error(e.response?.data?.message || "Network error");
    }
  }

  timer: any = null;

  startTimer() {
    if (this.timer !== null) {
      clearInterval(this.timer);
    }
    this.timer = setInterval(() => {
      this.check();
    }, 5000);
  }

  onBlur = () => {
    if (this.timer !== null) {
      clearInterval(this.timer);
    }
  };

  onFocus = () => {
    this.check();
    this.startTimer();
  };

  componentWillUnmount() {
    if (this.timer !== null) {
      clearInterval(this.timer);
      this.timer = null;
    }
    window.removeEventListener("blur", this.onBlur);
    window.removeEventListener("focus", this.onFocus);
  }

  async componentDidMount() {
    this.startTimer();
    window.addEventListener("blur", this.onBlur);
    window.addEventListener("focus", this.onFocus);
    const now = moment(new Date());
    const user = await get<User>("/me");
    const createdAt = moment(user.createdAt);
    const duration = now.diff(createdAt, "days");
    this.setState({ duration: 7 - duration });
  }

  render() {
    return (
      <div className="Content-container">
        <div
          className="NotActivatedPage"
          style={{ paddingTop: 150, textAlign: "center" }}
        >
          <h1 style={{ fontSize: 30, fontWeight: 900 }}>WELCOME</h1>
          <p style={{ fontSize: 18, fontWeight: 300, marginTop: 32 }}>
            Thank you for joining us. You have left{" "}
            <span className="remaining-days"> {this.state.duration} days </span>{" "}
            to confirm your registration. Please check out your emails or spam
            folder.
          </p>
          <p style={{ fontSize: 18, fontWeight: 300 }}>
            In case, you did not receive the confirmation email, contact us at
            hello@pozi.io.
            <br />
            <br />
            <p className="not-activated-button-container">
              <Button
                onClick={() => {
                  this.resendEmail();
                  eventTrack('Activation', 'Resend Email', 'button')
                }}
                type="primary"
              >
                Resend email
              </Button>
              <Button
                className="not-activated-continue"
                onClick={() => {
                  this.continue();
                  eventTrack('Activation', 'Continue', 'button')
                }}
              >
                Continue
              </Button>
            </p>
          </p>
        </div>
      </div>
    );
  }
}

export default NotActivatedPage;
