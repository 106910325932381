import React, { useState } from "react";
import { Button, message } from "antd";
import ga4 from "react-ga4";
import { ICompetition } from "../../Competition";
import "../../Competition.css";
import Themes from "./components/Themes";
import UserGrid from "../UserGrid";
import PartnerList from "./components/PartnerList";
import { user } from "../../../../../libs/utils/user";
import "./Summary.css";
import { get } from "../../../../../libs/utils/request";
import LoginWallModal from "../../../../common/LoginWallModal";


const styles = {
  root: {
    maxWidth: 1200,
    marginRight: "auto",
    marginLeft: "auto",
    marginTop: 67,
  },
  label: {
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: 800,
    color: "#282828",
    fontSize: 16,
  },
  description: {
    marginTop: 13,
    fontWeight: 300,
    whiteSpace: "pre-wrap" as const,
  },
  btnContainer: {
    width: "100%",
    display: "flex",
    flexDirection: "row" as const,
    flexWrap: "wrap" as const,
    gap: "10px 20px",
    marginTop: 38,
    marginBottom: 40,
  },
  btn: {
    fontWeight: "bold" as const,
    minWidth: 150,
  },
  footer: {
    width: "100%",
    display: "flex",
    flexDirection: "column" as const,
    alignItems: "center",
    marginTop: 100,
  },
};
const userFromStorage = sessionStorage.getItem('user')
let profileProgress = userFromStorage ? JSON.parse(userFromStorage).progress : 0



const Summary = ({
  competition,
  onApply,
  onWithdraw,
  withdrawButtonVisible,
  applyButtonVisible,
  youtubeVideo,
  vimeoVideo,
  vimeoEmbed,
  fbVideo,
}: Props) => {
  const [loginWallVisible, setLoginWallVisible] = useState(false);


  const checkProfile = async () => {
    if (user()) {
      try {
        console.log(window.location.href)
        onApply()
      } catch (err) {
        console.log(err)
      }
    } else {
      localStorage.setItem("url", window.location.href);
      setLoginWallVisible(true);
      ga4.ga('send', { 'hitType': 'pageview', 'page': 'competitions/regorlogin', 'title': 'To Reg. or Login popup' });
    }
  }
  return (
    <div style={styles.root}>
      <div className="embed-summary">
        {/* eslint-disable-next-line react/no-danger */}
        {competition.videoType === "iframe" && (
          <div className="embed-video" id="embed-video" >
            <div className="embed-video-vimeo" dangerouslySetInnerHTML={{ __html: vimeoEmbed }}></div>
          </div>
        )}
        {competition.videoType === "youtube" && (
          <div className="embed-video" id="embed-video">
            <iframe
              src={"https://www.youtube.com/embed/" + youtubeVideo}
              title="YouTube video player"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            ></iframe>
          </div>
        )}
        {competition.videoType === 'vimeo' &&
          <div className="embed-video" id="embed-video">
            <iframe
              src={"https://player.vimeo.com/video/" + vimeoVideo}
              title="{video_title}"
              allowFullScreen
              frameBorder="0"
            ></iframe>
          </div>
        }
        {competition.videoType==="facebook" && (
        <div className="embed-video" id="embed-video">
          <iframe
            src={"https://www.facebook.com/plugins/video.php?height=314&width=560&href=" + fbVideo + "&show_text=false&width=560&t=0"}
            width="560"
            height="314"
            style={{
              border: "none",
              overflow: "hidden"
            }}
            scrolling="no"
            allowFullScreen
            frameBorder="0"
            allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"
          ></iframe>
        </div>
      )}
      </div>
      <div style={styles.label}>Get to know it!</div>

      <div
        className="summary-description"
        style={{ fontSize: 16, marginTop: 20 }}
        dangerouslySetInnerHTML={{ __html: competition.description }}
      />

      <div style={{ ...styles.label, marginTop: 46, marginBottom: 18 }}>
        Themes of the competition
      </div>
      <Themes themes={competition.interests} />
      {(!user() || applyButtonVisible) && (
        <Button
          style={{ ...styles.btn, marginTop: 38, marginBottom: 40 }}
          type="primary"
          size="large"
          onClick={() => checkProfile()}
        >
          {competition.leadButtonText}
        </Button>
      )}
      {withdrawButtonVisible && (
        <div style={styles.btnContainer}>
          <Button style={styles.btn} type="primary" size="large" disabled>
            You are attending
          </Button>
          <Button
            style={styles.btn}
            type="danger"
            size="large"
            onClick={onWithdraw}
          >
            Withdraw my application
          </Button>
        </div>
      )}
      {!!competition?.mentors?.length && (
        <>
          <div style={{ ...styles.label, marginTop: 45, marginBottom: 12 }}>
            Mentors
          </div>
          <UserGrid users={competition.mentors.map((mentor) => mentor.user)} />
        </>
      )}
      {!!competition?.juries?.length && (
        <>
          <div style={{ ...styles.label, marginTop: 45, marginBottom: 12 }}>
            Jury
          </div>
          <UserGrid users={competition.juries.map((jury) => jury.user)} />
        </>
      )}
      {!!competition?.partnerOrganizers?.length && (
        <>
          <div style={{ ...styles.label, marginTop: 100, marginBottom: 14 }}>
            Organizers
          </div>
          <PartnerList partners={competition.partnerOrganizers} />
        </>
      )}
      {!!competition?.partners?.length && (
        <>
          <div style={{ ...styles.label, marginTop: 50, marginBottom: 14 }}>
            Supporters/Partners
          </div>
          <PartnerList partners={competition.partners} />
        </>
      )}
      {applyButtonVisible && (
        <div style={styles.footer}>
          <div style={{ ...styles.label, fontSize: 36, fontWeight: 900 }}>
            Are you ready?
          </div>
          <Button
            style={{ ...styles.btn, marginTop: 40 }}
            type="primary"
            size="large"
            onClick={() => onApply()}
          >
            {competition.leadButtonText}
          </Button>
        </div>
      )}
      <LoginWallModal
        title='Log in or Sign up!'
        description='For applying, first, you need to log in. If you do not have a Pozi account yet, feel free to sign up! The Pozi Team '
        visible={loginWallVisible}
        onCancel={() => setLoginWallVisible(false)}
      />
    </div>
  )
};

interface Props {
  competition: ICompetition;
  onApply: () => void;
  onWithdraw: () => void;
  withdrawButtonVisible: boolean;
  applyButtonVisible: boolean;
  youtubeVideo?: string;
  vimeoVideo?: string;
  vimeoEmbed?: string;
  fbVideo?: string;
}

export default Summary;
