import React from 'react'
import {Button, Input, Modal, message} from 'antd'
import {post} from '../../libs/utils/request'
import TextArea from "antd/lib/input/TextArea";

interface Props {
  onClose: () => void
  open: boolean
  onInvitations: (invitations: any) => void
}

interface State {
  loading: boolean
}

export class InvitationModal extends React.Component<Props, State> {

  defaultText: string = `Hi there,

I’d like to invite you to a brand new innovation meeting point, POZI.io. (You will love it!)

You can share your ideas, validate your project, find new friends, connect innovators, and other members of the Startup scene.
It takes only 2 minutes to join the startup community.`
  email: any = ''
  text: any = this.defaultText

  state: State = {
    loading: false
  }

  async invite() {
    this.setState({
      loading: true
    })

    const { email, text } = this

    try {
      const invitations = await post('/invitation/send', { email: email.trim(), message: text })
      this.props.onInvitations(invitations)
      this.email = ''
      this.text = this.defaultText
      this.props.onClose()
    } catch (e) {
      message.error(e.response?.data?.message || 'Network error')
    }

    this.setState({
      loading: false
    })
  }

  render() {
    return <Modal
      title="Invitation"
      visible={this.props.open}
      onOk={() => { }}
      onCancel={() => {
        this.email = ''
        this.text = this.defaultText
        this.props.onClose()
      }}
      footer={[]}
    >
      <Input
        placeholder="Email (only 5 can be sent per minute and 100 per day.)"
        value={this.email}
        size="large"
        onChange={(e) => {
          this.email = e.target?.value
          this.forceUpdate()
        }}
      />
      <TextArea
        style={{
          marginTop:'16px'
        }}
        value={this.text}
        autoSize
        maxLength={1000}
        onChange={(e) => {
          this.text = e.target?.value
          this.forceUpdate()
        }} />
      <Button
        onClick={() => {
          this.invite()
        }}
        loading={this.state.loading}
        type="primary"
        size="large"
        block
        style={{
          width: '200px',
          marginTop: '16px',
          marginBottom: '50px',
        }}>Invite</Button>
    </Modal>
  }
}
