import React, { Component } from 'react';
import { message, Select, Spin } from 'antd';
import debounce from 'lodash/debounce';
import { post } from '../../libs/utils/request';
import User from '../../user/User';
import { base64decode, base64encode } from '../../libs/utils/textUtils';
import './UserSearch.css';
import ConfirmationDialog from '../common/ConfirmationDialog';

const { Option } = Select;

interface State {
	value: { key: string, label: string, profileImage: string }[]
	data: { key: string, label: string, profileImage: string }[]
	fetching: boolean
	popup: boolean
}

interface Props {
	value?: User[]
	multiple?: boolean
	onChange?: (users: User[]) => void
	owner? :boolean
}

export class UserSearch extends Component<Props, State> {
	state: State = {
		value: [],
		data: [],
		fetching: false,
		popup: false
	}

	lastFetchId = 0

	constructor(props: Props) {
		super(props);
		this.onSearch = debounce(this.onSearch, 800);
	}

	async onSearch(name: any) {
		this.lastFetchId += 1;
		if (name.length < 3) {
			this.setState({ data: [], fetching: false });
			return;
		}
		const fetchId = this.lastFetchId;

		this.setState({ data: [], fetching: true });
		try {
			const users = await post<User[]>('/user/search', { name: name.trim().split(' ').join('').toLowerCase() });
			if (fetchId === this.lastFetchId) {
				this.setState({
					data: this.props.owner?  users.map((u) => ({ key: base64encode(JSON.stringify(u)), label: `${u.firstName} ${u.lastName}`, profileImage: u.profileImage })): users.map((u) => ({ key: base64encode(JSON.stringify(u)), label: `${u.firstName} ${u.lastName}`, profileImage: u.profileImage })),
					fetching: false,
				});
			}
		} catch (e) {
			console.error(e);
		}
	}

	onChange(value: any) {
		value = this.props.multiple !== true ? [value] : value;
		this.setState({
			value,
			data: [],
			fetching: false,
		});
		if (this.props.onChange) {
			const newValue = value.map((v: any) => JSON.parse(base64decode(v.key)));
			if(this.props.owner===true && newValue[0].progress<100){
				// message.warning("User's profile is completed only "+newValue[0].progress+"%")
				this.setState({popup:true})
			}
			else
			this.props.onChange(newValue);
		}
	}

	render() {
		const { fetching, data, value } = this.state;

		let propValue: { key: string, label: string, profileImage: string }[] | { key: string, label: string, profileImage: string };
		if (this.props.value !== undefined) {
			if (this.props.multiple === true) {
				propValue = this.props.value.map((u) => ({
					key: base64encode(JSON.stringify(u)),
					label: `${u.firstName} ${u.lastName}`,
					profileImage: u.profileImage,
				}));
			} else {
					propValue = {
						key: base64encode(JSON.stringify(this.props.value[0])),
						label: `${this.props.value[0].firstName} ${this.props.value[0].lastName}`,
						profileImage: this.props.value[0].profileImage,
					};
			}
		} else {
			propValue = [];
		}
		return (
			<>
				<Select
					showSearch
					mode={this.props.multiple === true ? 'multiple' : undefined}
					labelInValue
					value={propValue}
					placeholder={`Select user${this.props.multiple === true ? 's' : ''}`}
					notFoundContent={fetching ? <Spin size='small' /> : null}
					filterOption={false}
					onSearch={(value: any) => this.onSearch(value)}
					onChange={(value: any) => this.onChange(value)}
					style={{ width: '100%' }}
					showArrow={false}	
				>
					{data.map((u: any) => (
						<Option key={u.key}>
							<img
								style={{
									width: 28,
									height: 28,
									objectFit: 'cover',
									borderRadius: '50%',
									marginRight: 4,
								}}
								src={u.profileImage ? `${u.profileImage}?w=28` : '/images/user.svg'}
								alt=''
							/>
							{u.label}
						</Option>
					))}
				</Select>
				<ConfirmationDialog
					visible={this.state.popup}
					onOk={() => {
						this.setState({ popup: false });
					}}
					title='You cannot transfer ownership'
					htmlDescription={'You cannot transfer ownership to this user because their profile is not 100% complete. Please speak with the user when they have 100% completed their profile, and then try to transfer ownership again. If you have any problems, please contact us at <a href="mailto:hello@pozi.io" >hello@pozi.io</a> .'}
					confirmBtnText='Ok'
					confirmBtnType='primary'
				/>
			</>
		);
	}
}
