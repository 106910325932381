import React from 'react';
import { Button , message} from 'antd';
import { IEvent } from '../Event/Event';
import '../Event/Event.css';
import Themes from './components/Themes';
import UserGrid from './components/UserGrid';
import PartnerList from './components/PartnerList';
import { user } from '../../../libs/utils/user';

const styles = {
  root: {
    maxWidth: 1200,
    marginRight: 'auto',
    marginLeft: 'auto',
    marginTop: 67,
  },
  label: {
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontWeight: 800,
    color: '#282828',
    fontSize: 16,
  },
  description: { marginTop: 13, fontWeight: 300, whiteSpace: 'pre-wrap' as const },
  btnContainer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row' as const,
    flexWrap: 'wrap' as const,
    gap: '10px 20px',
    marginTop: 38,
    marginBottom: 40,
  },
  btn: {
    fontWeight: 'bold' as const,
    minWidth: 150,
  },
  footer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column' as const,
    alignItems: 'center',
    marginTop: 100,
  },
};
const userFromStorage = sessionStorage.getItem('user')
const profileProgress =  userFromStorage ? JSON.parse(userFromStorage).progress : 0

const Summary = ({
  event, onApply, applyButtonVisible, onWithdraw, withdrawButtonVisible,youtubeVideo, vimeoVideo, vimeoEmbed, fbVideo
}: Props) => (
  
  <div style={styles.root}>
    
    <div className="embed-summary-event">
      {/* eslint-disable-next-line react/no-danger */}
      {/* <div className='summary-description' style={{ fontSize: 16, marginTop: 20 }} dangerouslySetInnerHTML={{ __html: event.description }} /> */}
      {event.videoType==="iframe"&&(
          <div className="embed-video" id="embed-video" >
            <div className="embed-video-vimeo" dangerouslySetInnerHTML={{ __html: vimeoEmbed }}></div>
          </div>
      )}
      {event.videoType==="youtube" && (
        <div className="embed-video" id="embed-video">
          <iframe
            width="450"
            height="250"
            src={"https://www.youtube.com/embed/"+youtubeVideo}
            title="YouTube video player"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          ></iframe>
        </div>
      )}
      {event.videoType==="vimeo" && (
       <div className="embed-video" id="embed-video">
       <iframe
        width="450"
        height="250"
        src={"https://player.vimeo.com/video/"+vimeoVideo}
        title="{video_title}"
        allowFullScreen
        frameBorder="0"
      ></iframe>
    </div>
      )}
      {event.videoType==="facebook" && (
        <div className="embed-video" id="embed-video">
          <iframe
            src={"https://www.facebook.com/plugins/video.php?height=314&width=560&href=" + fbVideo + "&show_text=false&width=560&t=0"}
            width="560"
            height="314"
            style={{
              border: "none",
              overflow: "hidden"
            }}
            scrolling="no"
            allowFullScreen
            frameBorder="0"
            allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"
          ></iframe>
        </div>
      )}

    </div>
    <div style={styles.label}>Get to know it!</div>
    {/* eslint-disable-next-line react/no-danger */}
    <div className='summary-description' style={{ fontSize: 16, marginTop: 20 }} dangerouslySetInnerHTML={{ __html: event.description }} />
    <div style={{ ...styles.label, marginTop: 46, marginBottom: 18 }}>
      Themes of the event{console.log(!user() && applyButtonVisible, applyButtonVisible)}
    </div>
    <Themes themes={event.interests} />
    {!user() && (
      <Button style={{ ...styles.btn, marginTop: 38, marginBottom: 40 }} type='primary' size='large' 
      onClick={()=> 
        onApply()}>
        {event.leadButtonText}
      </Button>
    )}
    {(user() && applyButtonVisible && !event.needRedirect) && (
      <Button style={{ ...styles.btn, marginTop: 38, marginBottom: 40 }} type='primary' size='large' 
      onClick={()=> 
        onApply()}>
        {event.leadButtonText}
      </Button>
    )}
    {(user() && applyButtonVisible && event.needRedirect) && (
      <Button style={{ ...styles.btn, marginTop: 38, marginBottom: 40 }} type='primary' size='large' >
          <a href={event.redirectURL} target="_blank" >{event.leadButtonText}</a>
      </Button>
    )}
    
    {withdrawButtonVisible && (
      <div style={styles.btnContainer}>
        <Button style={styles.btn} type='primary' size='large' disabled>You are attending</Button>
        <Button style={styles.btn} type='danger' size='large' onClick={onWithdraw}>Withdraw my application</Button>
      </div>
    )}
    
    {!!event?.speakers?.length && (
      <>
        <div style={{ ...styles.label, marginTop: 45, marginBottom: 12 }}>Speakers</div>
        <UserGrid users={event.speakers.map((speaker:any) => speaker.user)} />
      </>
    )}
     {/* {!!event?.coorganizers?.length && (
      <>
        <div style={{ ...styles.label, marginTop: 45, marginBottom: 12 }}>Co-organizers</div>
        <UserGrid users={event.coorganizers.map((coorg:any) => coorg.user)} />
      </>
    )} */}
    {!!event?.partnerOrganizers?.length && (
      <>
        <div style={{ ...styles.label, marginTop: 100, marginBottom: 14 }}>
          Organizers
        </div>
        <PartnerList partners={event.partnerOrganizers} />
      </>
    )}
    {!!event?.partners?.length && (
      <>
        <div style={{ ...styles.label, marginTop: 50, marginBottom: 14 }}>
          Supporters/Partners
        </div>
        <PartnerList partners={event.partners} />
      </>
    )}
    {(user() && applyButtonVisible && !event.needRedirect) && (
      <div style={styles.footer}>
        <div style={{ ...styles.label, fontSize: 36, fontWeight: 900 }}>
          Are you ready?
        </div>
        <Button style={{ ...styles.btn, marginTop: 40 }} type='primary' size='large' 
        onClick={()=>
          onApply()}>
          {event.leadButtonText}
        </Button>
      </div>
    )}
    {(user() && applyButtonVisible && event.needRedirect) && (
      <div style={styles.footer}>
        <div style={{ ...styles.label, fontSize: 36, fontWeight: 900 }}>
          Are you ready?
        </div>
        <Button style={{ ...styles.btn, marginTop: 40 }} type='primary' size='large' >
          <a href={event.redirectURL} target="_blank" >{event.leadButtonText}</a>
        </Button>
      </div>
    )}
    {(!user() && applyButtonVisible) && (
      <div style={styles.footer}>
        <div style={{ ...styles.label, fontSize: 36, fontWeight: 900 }}>
          Are you ready?
        </div>
        <Button style={{ ...styles.btn, marginTop: 40 }} type='primary' size='large' 
        onClick={()=>
          onApply()}>
          {event.leadButtonText}
        </Button>
      </div>
    )}
  </div>
);

interface Props {
  event: IEvent;
  onApply: () => void;
  applyButtonVisible: boolean;
  onWithdraw: () => void;
  withdrawButtonVisible: boolean;
  youtubeVideo:string;
  vimeoVideo: string;
  vimeoEmbed: string;
  fbVideo?: string;

}

export default Summary;
