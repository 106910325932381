import React from 'react'
import { useEffect, useState } from 'react';
import { get } from '../../libs/utils/request';
import moment from 'moment';


const Stat = () => {
  const [stat, setStat] = useState(
    {
        "today": 0,
        "lastThreeDay": 0,
        "countOfAllUser": 0,
        "countOfAllActivatedUser": 0,
        "ideaCreatedToday": 0,
        "eventCreatedToday": 0,
        "competitionCreatedToday": 0,
        "organizationCreatedToday": 0,
        "startupCreatedToday": 0,

        "ideaCreatedLastThreeDay": 0,
        "eventCreatedLastThreeDay": 0,
        "competitionCreatedLastThreeDay": 0,
        "organizationCreatedLastThreeDay": 0,
        "startupCreatedLastThreeDay": 0,
    
        "countOfAllIdea": 0,
        "countOfAllEvent": 0,
        "countOfAllCompetition": 0,
        "countOfAllOrganization": 0,
        "countOfAllStartups": 0,

        "lastThreeStart": new Date(),
        "lastThreeEnd": new Date(),
    }
    );

  useEffect(() => {
    getStat();
    }, []);

    const getStat = async () => {
        console.log('stat called');
        const resp: any = await get('/stat');
        console.log('STAT RESP: ' + resp);
        setStat(resp);
        return resp;
        }
    
       
    return (
        <>
        <div className="wrapper">Registered today: {stat.today}</div>
        <div className="wrapper">Registered last three days ( { moment(stat.lastThreeStart).format('DD/MM/YYYY : h:mm a')} - {moment(stat.lastThreeEnd).format('DD/MM/YYYY : h:mm a')}): {stat.lastThreeDay}</div>
        <div className="wrapper">Number of all registered user: {stat.countOfAllUser}</div>
        <div className="wrapper">Number of all activated user: {stat.countOfAllActivatedUser}</div>
        <div>-------------------------------------------------------------------------------------</div>
        <div className="wrapper">Idea(s) created today: {stat.ideaCreatedToday}</div>
        <div className="wrapper">Idea(s) created last three days ( { moment(stat.lastThreeStart).format('DD/MM/YYYY : h:mm a')} - {moment(stat.lastThreeEnd).format('DD/MM/YYYY : h:mm a')}): {stat.ideaCreatedLastThreeDay}</div>
        <div className="wrapper">Number of all idea: {stat.countOfAllIdea}</div>
        <div>-------------------------------------------------------------------------------------</div>
        <div className="wrapper">Event(s) created today: {stat.eventCreatedToday}</div>
        <div className="wrapper">Event(s) created last three days ( { moment(stat.lastThreeStart).format('DD/MM/YYYY : h:mm a')} - {moment(stat.lastThreeEnd).format('DD/MM/YYYY : h:mm a')}): {stat.eventCreatedLastThreeDay}</div>
        <div className="wrapper">Number of all event: {stat.countOfAllEvent}</div>
        <div>-------------------------------------------------------------------------------------</div>
        <div className="wrapper">Competition(s) created today: {stat.competitionCreatedToday}</div>
        <div className="wrapper">Competition(s) created  last three days ( { moment(stat.lastThreeStart).format('DD/MM/YYYY : h:mm a')} - {moment(stat.lastThreeEnd).format('DD/MM/YYYY : h:mm a')}): {stat.competitionCreatedLastThreeDay}</div>
        <div className="wrapper">Number of all competition: {stat.countOfAllCompetition}</div>
        <div>-------------------------------------------------------------------------------------</div>
        <div className="wrapper">Organization(s) created today:{stat.organizationCreatedToday}</div>
        <div className="wrapper">Organization(s) created last three days ( { moment(stat.lastThreeStart).format('DD/MM/YYYY : h:mm a')} - {moment(stat.lastThreeEnd).format('DD/MM/YYYY : h:mm a')}): {stat.organizationCreatedLastThreeDay}</div>
        <div className="wrapper">Number of all organization: {stat.countOfAllOrganization}</div>
        <div>-------------------------------------------------------------------------------------</div>
        <div className="wrapper">Startup(s) created today:{stat.startupCreatedToday}</div>
        <div className="wrapper">Startup(s) created last three days ( { moment(stat.lastThreeStart).format('DD/MM/YYYY : h:mm a')} - {moment(stat.lastThreeEnd).format('DD/MM/YYYY : h:mm a')}): {stat.startupCreatedLastThreeDay}</div>
        <div className="wrapper">Number of all Startup: {stat.countOfAllStartups}</div>
        </>
        
    );
    
    };

export default Stat;