import React from 'react';
import ReactDOM from 'react-dom';
import ReactGA from 'react-ga4';
//import { ErrorHandling } from './app/components/common/ErrorHandling';
import Routes from './app/Routes';
import { AuthContextProvider } from './store/AuthContext';
import { UserContextProvider } from './store/UserContext';

// ErrorHandling.init();

ReactGA.initialize('G-G5B6N29N50');

ReactDOM.render(
  <AuthContextProvider>
    <UserContextProvider>
      <React.StrictMode>
        <Routes />
      </React.StrictMode>
    </UserContextProvider>
  </AuthContextProvider>,
  document.getElementById('root'),
);
