import React, { useEffect, useState } from 'react';
import {
  Alert,
  Button,
  Col, Form, Input, message, Modal, Row, Tooltip,
} from 'antd';
import { WrappedFormUtils } from 'antd/lib/form/Form';
import { Moment } from 'moment';
import moment from 'moment-timezone';
import { useHistory, Prompt, useParams } from 'react-router';
import { HtmlEditor, Toolbar, Editor } from '@aeaton/react-prosemirror';
import { plugins, schema, toolbar } from '@aeaton/react-prosemirror-config-default';
import { Helmet } from 'react-helmet';
import Cover from '../Competitions/components/Cover';
import GeneralCheckbox from '../common/form/GeneralCheckbox';
import {
  deleteReq, get, getBaseUrl, patch, post,
} from '../../libs/utils/request';
import Schedule, { sortSchedule } from '../Competitions/components/Schedule/Schedule';
import FormLabel from '../Competitions/components/FormLabel';
import FormInput from '../common/form/FormInput';
import { FormDateTimePicker } from '../common/form/DateTimePicker';
import { UserSearch } from '../User/UserSearch';
import PartnerLogos from '../Competitions/components/PartnerLogos/PartnerLogos';
import { FormTimezoneSelector } from '../common/form/TimezoneSelector';
import { FormLocationSelector } from '../common/form/LocationSelector';
import FormInterests from '../common/form/FormInterests';
import User from '../../user/User';
import FormValidatedInput from '../common/form/FormValidatedInput';
import { IEvent } from './Event/Event';
import { Loading } from '../common/Loading';
import ConfirmationDialog from '../common/ConfirmationDialog';
import './NewEvent.css';
import FormTextArea from '../common/form/FormTextArea';
import { FormControlLabel, Radio, RadioGroup, RadioProps, Switch } from '@material-ui/core';
import EventTitleCard from './components/EventTitleCard';
import { OrganizationSearch } from '../Competitions/Competition/components/OrganizationSearch';
import Partnership from '../Competitions/Competition/components/Partnership/Partnership';
import { InfoCircleOutlined } from '@ant-design/icons';
import { isAdmin } from '../../libs/utils/user';

const styles = {
  root: {
    flex: 1,
    display: 'flex',
    justifyContent: 'center',
    marginLeft: 20,
    marginRight: 20,
    marginBottom: 100,
  },
  content: { width: 1100 },
  durationLabels: {
    marginTop: 0,
    fontWeight: 300,
    fontSize: 16,
    lineHeight: '20px',
  },
  inputLabel: {
    fontFamily: 'Inter',
    fontWeight: 900,
    fontStyle: 'normal',
    fontSize: 18,
    lineHeight: '22px',
    color: '#282828',
  },
  googleLogo: {
    maxHeight: 12,
  },
  checkboxText: {
    fontFamily: 'Inter',
    fontWeight: 900,
    fontStyle: 'normal',
    fontSize: 15,
    color: '#282828',
  },
  formContent: {
    maxWidth: 1000,
    marginRight: 'auto',
    marginLeft: 'auto',
  },
  inputText: {
    fontSize: 16,
    color: '#282828',
  },
  submitContainer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column' as const,
    alignItems: 'center',
    marginTop: 100,
  },
  btnContainer: {
    display: 'flex',
    flexDirection: 'row' as const,
    flexWrap: 'wrap' as const,
    gap: '20px 20px',
  },
  submitText: {
    marginTop: 100,
    marginBottom: 40,
    fontFamily: 'Inter',
    fontWeight: 900,
    fontSize: 36,
    fontStyle: 'normal',
  },
  errorText: {
    fontSize: 20,
    marginTop: 72,
    textAlign: 'center' as const,
    fontWeight: 600,
    color: '#F00',
  },
  locationLabelContainer: {
    display: 'flex',
    flexDirection: 'row' as const,
    alignItems: 'center',
    marginTop: 32,
    marginBottom: 10,
    gap: '10px 10px',
  },
  descriptionContainer: {
    border: '1px solid #262626', borderRadius: 4, padding: 8, width: '100%', minHeight: 400,
  },
};

const DEFAULT_DESCRIPTION = '<H1><b>Add a detailed description of your event.</b></H1>'
  + '<ul>'
  + '<li>Introduce your event, what is the purpose, goals?</li>'
  + '<li>Add a detailed agenda (schedule, workshops, mentoring sessions)</li>'
  + '<li>Who can participate and how?</li>'
  + '<li>Why should they apply?</li>'
  + '<li>What are the rules and requirements?</li>'
  + '<li>What other tools/platforms are recommended for the participants?</li>'
  + '<li>Specific venue (if you organize an offline or hybrid event)</li>'
  + '<li>Introduce the organizers</li>'
  + '<li>Contact information (email address, website)</li>'
  + '<li>FAQ</li>'
  + '</ul>';

const convertDate = (d: Moment, timezone: string): string => (timezone ? (moment(d))
  .tz(timezone, true) : moment(d))
  .toISOString();

const InputRow = ({ children }: { children: any }) => (
  <Row>
    <Col xs={24} sm={24} md={16}>
      {children}
    </Col>
  </Row>
);

export const isFutureDateValidator = (dateToCompare: Moment) => (_: any, value: Moment) => {
  if (!isAdmin() && value && value <= dateToCompare) {
    return Promise.reject(new Error('Date must be a future date.'));
  }
  return Promise.resolve();
};

const imageLinkBase = `${getBaseUrl()}/image`;

const newEvent = ({ form, isEdit, isDraft, isCreating }: Props) => {
  const [coverImage, setCoverImage] = useState('');
  const [isPreregistration, setPreregistration] = useState(true);
  const [interestGroups, setInterestGroups] = useState([]);
  const [speakers, setSpeakers] = useState([]);
  const [organizers, setOrganizers] = useState([]);
  const [organizerLogos, setOrganizerLogos] = useState([]);
  const [partnerLogos, setPartnerLogos] = useState([]);
  const [organization, setOrganization] = useState(null);
  const [navigationBlocked, setNavigationBlocked] = useState(true);
  const [isSubmitting, setSubmitting] = useState(false);
  const [submitError, setSubmitError] = useState('');
  const [isTagEdited, setTagEdited] = useState(false);
  const [maxParticipants,setMaxParticipants]=useState(20)
  const [themes, setThemes] = useState([]);
  const [initialCount, setInitialCount] = useState(2);
  const [isFree, setIsFree]=useState('free');
  const [fee, setFee]=useState(0);
  const [event, setEvent] = useState(null);
  const [createdAt, setCreatedAt] = useState('');
  const [isLoading, setLoading] = useState(true);
  const [errorMessage, setErrorMessage] = useState('');
  const [videoUrl, setVideoUrl] = useState('')
  const [partner,setPartner] = useState([]);
  const [deadlineDate,setDeadlinDate] = useState(null);
  const [owner, setOwner] = useState(isCreating ? [JSON.parse(sessionStorage.getItem('user'))]: undefined)

  const [deleteDialogVisible, setDeleteDialogVisible] = useState(false);
  const [createOrganization,setCreateOrganization] = useState(false)

  const [description, setDescription] = useState('');
  const [isOnline,setIsOnline]=useState('')
  
  const { tag, draftId } = useParams();
  const history = useHistory();

  const userFromStorage = sessionStorage.getItem('user');
  const profileProgress =  userFromStorage ? JSON.parse(userFromStorage).progress : 0;

  useEffect(() => {
    if (!isEdit) {
      setDescription(DEFAULT_DESCRIPTION);
    }
  }, [isEdit]);

  useEffect(() => {
    // eslint-disable-next-line no-undefined
    window.onbeforeunload = navigationBlocked ? () => true : undefined;
    return () => {
      // eslint-disable-next-line no-undefined
      window.onbeforeunload = undefined;
    };
  }, [navigationBlocked]);

  useEffect(() => {
    (async () => {
      try {
        let startedOrganization = localStorage.getItem('organization')
        setErrorMessage('');
        setLoading(true);
        const regData = await get<any>('/register/init');
        if (Array.isArray(regData.interestGroups)) {
          setInterestGroups(regData.interestGroups);
        }
        setOrganization(startedOrganization !== null ? JSON.parse(startedOrganization) : '');
        localStorage.removeItem('organization')
        if (isEdit) {
          let event: IEvent;
          if (!isDraft) {
            event = await get<IEvent>(`/events/${tag}`);
          } else {
            event = await get<IEvent>(`/events/draft/all/${draftId}`);
            if (event.isOrganization) {
              setNavigationBlocked(false);
              history.push(event.isOrganization);
            }
          }
          const formatTime = (value: string) => {
            if (value) {
              return event.timezone ? moment(value).tz(event.timezone) : moment(value);
            }
            return null;
          };
          let ownerArray = [];
          if(!isEdit){
            ownerArray.push(JSON.parse(sessionStorage.getItem('user')))
            setOwner(ownerArray)
          }
          ownerArray.push(event.createdBy)
          setEvent(event);
          setCreatedAt(event.createdAt);
          setCoverImage(event.image);
          setVideoUrl(event.videoUrl);
          setPreregistration(event.isPreregistration);
          setSpeakers(event.speakers ? event.speakers.map((item) => item.user) : []);
          setOrganizers(event.coorganizers ? event.coorganizers.map((item) => item.user) : []);
          setOwner(ownerArray)
          setPartner(event.partnerOrganizations ? event.partnerOrganizations.map((item:any) => item) : []);
          setThemes(event.interests ? event.interests
            .map((item) => ({ id: item.id, name: item.name })) : []);
          setInitialCount(Array.isArray(event.schedules) ? Math.max(event.schedules.length, 2) : 2);
          setOrganizerLogos(event.partnerOrganizers ? event.partnerOrganizers
            .map((item) => ({ partnerUrl: item.partnerUrl, name: item.name, image: `${imageLinkBase}/${item.image.filename}` })) : []);
          setPartnerLogos(event.partners ? event.partners
            .map((item) => ({ partnerUrl: item.partnerUrl, name: item.name, image: `${imageLinkBase}/${item.image.filename}` })) : []);
          let applicationTypes = { startup: false, idea: false, user: false };
          setMaxParticipants(event.maxParticipant);
          // setEditorState(getEditorStateByHtml(comp.description));
          // const isUnderline=event.description.includes('<span style="text-decoration:underline">')
          setDescription(event.description);
          setIsFree(event.isFree);
          setFee(event.fee);
          setIsOnline(event.isOnline === true ? 'online' : 'offline');
          setOrganization(event.organization ? event.organization : "");
          form.setFieldsValue({
            maxNumberButtonText: event.maxParticipant,
            title: event.name,
            eventTag: event.tag,
            deadline: formatTime(event.deadline),
            firstDate: formatTime(event.durationFrom),
            lastDate: formatTime(event.durationTo),
            timezone: event.timezone,
            location: event.location ? `${event.location.city}, ${event.location.country}` : event.city,
            shortDescription: event.shortDescription ? event.shortDescription : '',
            // description: comp.description,
            leadButtonText: event.leadButtonText,
            interests: event.interests.map((item) => ({ id: item.id, name: item.name })),
            videoUrl: event.videoUrl ? event.videoUrl : '',
            redirectURL: event.redirectURL ? event.redirectURL : '',
          });
          event.schedules.forEach((item, i) => {
            form.setFieldsValue({
              [`schedule_date_${i}`]: formatTime(item.date),
              [`schedule_name_${i}`]: item.description,
            });
          });
          
        }
      } catch (error) {
        if(error.response.status === 403 && error.response.data.extendedUser){
          sessionStorage.setItem("user", JSON.stringify(error.response.data.extendedUser))
          if(!error.response.data.extendedUser.active && !error.response.data.extendedUser.trial){
            window.location.replace('/trial-ended')
          }}
        if (error.response?.data?.message) {
          setErrorMessage(error.response.data.message);
        } else {
          setErrorMessage(error.message);
        }
        if(error?.response?.status === 403 && !error.response.data.extendedUser){
          message.warning(`${profileProgress}% of your profile is completed. You need to get at least 70%`)
        }
        if(error?.response?.status === 404){
        setNavigationBlocked(false)
        message.error('We are sorry. This content might be deleted by the owners.', 5)
        history.push('/events')
      }
      } finally {
        setLoading(false);
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isEdit, tag]);

  const changeCover = (newImage: string) => {
    const url: string = coverImage;
    setCoverImage(newImage);
    if (url && !isEdit) {
      post('/image/delete', { url });
    }
  };

  const ownerChange = (e:any)=>{
    const myOrganizers = organizers.map(organizer=>{ return organizer});
    setOwner(e);

    let filteredOrgs = myOrganizers;
    let filteredIds = organizers.map(organizer=>{ return organizer.id})
    
    if(filteredOrgs.map(org=>org.id === e[0].id)){
      filteredOrgs = organizers.filter(org=>org.id !== e[0].id)
    }

    if(!filteredIds.includes(event?.createdBy.id) && event?.createdBy.deleted !== true){
      filteredOrgs.push(event ? event.createdBy : JSON.parse(sessionStorage.getItem('user')))
    }
    setOrganizers(filteredOrgs)

  }


  const validateDeadline = (dateToCompare: Moment) => (_: any, value: Moment) => {
    if (!isAdmin() && value && value <= dateToCompare) {
      return Promise.reject(new Error('Date must be a future date.'));
    }
    const lastDate = form.getFieldValue('lastDate');
    if (value && lastDate && value >= lastDate) {
      return Promise.reject(new Error('The deadline must be larger then last date.'));
    }
    return Promise.resolve();
  };

  const validateLastDate = (dateToCompare: Moment) => (_: any, value: Moment) => {
    if (!isAdmin() && value && value <= dateToCompare) {
      return Promise.reject(new Error('Date must be a future date.'));
    }
    const firstDate = form.getFieldValue('firstDate');
    if (value && firstDate && value <= firstDate) {
      return Promise.reject(new Error('The end date must be larger then first date.'));
    }
    return Promise.resolve();
  };

  const validateDateByField = (field: string) => {
    setTimeout(() => {
      if (form.getFieldValue(field)) {
        form.validateFields([field]);
      }
    }, 300);
  };

  const tagValidator = async (t: string) => {
    await post('/events/validate-tag', { tag: t });
  };

  const generateTag = async (name: string) => {
    if (!isTagEdited) {
      const resp: { tag: string } = await post('/events/generate-tag', { name });
      form.setFieldsValue({ eventTag: resp.tag.toUpperCase() });
    }
  };

  const deleteEvent = async () => {
    try {
      if (isDraft) {
        await deleteReq(`/events/draft/all/${draftId}`);
      } else {
        await deleteReq(`/events/${tag}`);
      }
      history.replace('/events');
    } catch (error) {
      message.error('Failed to delete event.');
    }
  };

  const submitForm = () => {
    form.validateFields((err, values) => {
      if (err) {
        let error = Object.values(err).sort();
        document.getElementById('errors').innerHTML = "";
        document.getElementById('errors').innerHTML = "<h1>Please check these fields</h1>";
        error.map((errorMsg: any) => {
          document.getElementById('errors').append(errorMsg.errors[0].field.replace(/([a-z](?=[A-Z]))/g, '$1 ').replace(/_/g, " ").replace("name", "description").toLowerCase().replace(/([0-9])/gi, function (x:string) {
              return parseInt(x)+1;
          }), "\n");
        })
      }

      let formattedDescription = description
      if(description.includes("span")){
        formattedDescription = description.replace(/span style="text-decoration:underline"/g, "u").replace(/span/g, 'u')
      }
      if(description.includes("<a href")){
        formattedDescription = description.replace(/<a href/g, "<a target='_blank' href")
      }

      if (!err) {
        const { timezone } = values;
        let placeId;
        try {
          placeId = JSON.parse(values.location).placeId;
        } catch (error) {
          if (isEdit) {
            placeId = event.location.placeId;
          }
        }
        const formatSchedules = () => {
          sortSchedule(form);
          const tmp = [];
          let n = 0;
          while (form.getFieldValue(`schedule_name_${n}`)) {
            tmp.push({
              date: convertDate(form.getFieldValue(`schedule_date_${n}`), timezone),
              description: form.getFieldValue(`schedule_name_${n}`),
            });
            n += 1;
          }
          return tmp;
        };
        const formatUsers = (list: User[]): string[] => list.map((item) => item.id);

        let redirectURL = values.redirectURL;
        if(redirectURL > 7 && !/^https?:\/\//.test(redirectURL)){
          redirectURL='http://'+redirectURL
        }
        const dto: any = {
          name: values.title,
          deadline: convertDate(values.deadline, timezone),
          durationFrom: convertDate(values.firstDate, timezone),
          durationTo: convertDate(values.lastDate, timezone),
          timezone,
          // country: location.country,
          // city: location.name,
          // description: values.description,
          location: {
            placeId,
          },
          shortDescription: values.shortDescription,
          description: formattedDescription,
          schedules: formatSchedules(),
          interests: values.interests.map((item: { id: string, name: string }) => item.id),
          image: coverImage,
          partners: partnerLogos,
          partnerOrganizers: organizerLogos,
          speakers: formatUsers(speakers),
          organizers: formatUsers(organizers),
          partnerOrganizations:formatUsers(partner),
          leadButtonText: values.leadButtonText,
          isPreregistration,
          maxParticipant:maxParticipants,
          isOnline:isOnline==='online'?true:false,
          isFree:isFree,
          fee: isFree == "free" ? 0 : fee,
          videoUrl: values.videoUrl,
          organizationId:organization?organization.id:'',
          createdBy: formatUsers(owner),
          redirectURL
        };
        if (isDraft) {
          dto.draftId = draftId;
        }
        setNavigationBlocked(false);
        setSubmitting(true);
        (async () => {
          setTimeout(async () => {
            try {
              if (isEdit) {
                if (isDraft) {
                  if(isAdmin()){
                    await post('/events/create-admin', { tag: values.eventTag, ...dto });
                    history.replace('/events');
                  }
                  else{
                    await post('/events/create', { tag: values.eventTag, ...dto });
                    history.replace('/events');
                  }
                } else {
                  await patch(`/events/${tag}`, { tag: tag, ...dto });
                  history.replace(`/events/${tag}`);
                }
              } else {
                if(isAdmin()){
                  await post('/events/create-admin', { tag: values.eventTag, ...dto });
                  history.replace('/events');
                }
                else{
                  await post('/events/create', { tag: values.eventTag, ...dto });
                  history.replace('/events');
                }
              }
            } catch (error) {
              if (error.response?.data?.name === 'ValidationError' && Array.isArray(error.response?.data?.details)) {
                const msg = Object.values(error.response.data.details[0]).join(', ');
                setSubmitError(`Validation failed: ${msg}.`);
              } else if (error.response?.data?.message) {
                setSubmitError(error.response.data.message);
              } else {
                setSubmitError(error.message);
              }
              setNavigationBlocked(false);
              setSubmitting(false);
            }
          }, 300);
        })();
      }
    });
  };

  let isNewOrganization=false;

  const saveDraft = (values: any) => {
    const { title } = values;
    if (!title || title.length < 3 || title.length > 50) {
      message.error('Please insert a valid title.');
    }
    else {
      const { timezone } = values;
      let placeId;
      try {
        const parse = JSON.parse(values.location)?.placeId;
        placeId = parse || null;
      } catch (error) {
        if (isEdit) {
          placeId = event.location?.placeId ? event.location.placeId : null;
        }
      }
      const formatSchedules = () => {
        sortSchedule(form);
        const tmp = [];
        let n = 0;
        while (form.getFieldValue(`schedule_name_${n}`)) {
          tmp.push({
            date: convertDate(form.getFieldValue(`schedule_date_${n}`), timezone),
            description: form.getFieldValue(`schedule_name_${n}`),
          });
          n += 1;
        }
        return tmp;
      };
      const formatUsers = (list: User[]): string[] => list.map((item) => item.id);

      let redirectURL = values.redirectURL;
      if(redirectURL.length > 7 && !/^https?:\/\//.test(redirectURL)){
        redirectURL='http://'+redirectURL
      }
      
      const dto = {
        name: values.title,
        deadline: values.deadline ? convertDate(values.deadline, timezone) : null,
        durationFrom: values.firstDate ? convertDate(values.firstDate, timezone) : null,
        durationTo: values.lastDate ? convertDate(values.lastDate, timezone) : null,
        timezone,
        // country: location.country,
        // city: location.name,
        // description: values.description,
        location: {
          placeId,
        },
        shortDescription: values.shortDescription,
        description,
        schedules: formatSchedules(),
        interests: values.interests.map((item: { id: string, name: string }) => item.id),
        image: coverImage,
        partners: partnerLogos,
        partnerOrganizers: organizerLogos,
        speakers: formatUsers(speakers),
        organizers: formatUsers(organizers),
        partnerOrganizations:formatUsers(partner),
        leadButtonText: values.leadButtonText,
        isPreregistration,
        maxParticipant:maxParticipants,
        isOnline:isOnline==='online'?true:false,
        isFree,
        fee,
        videoUrl:values.videoUrl,
        organizationId:organization?organization.id:"",
        createdBy: formatUsers(owner),
        redirectURL
      };
      setNavigationBlocked(false);
      setSubmitting(true);
      (async () => {
        setTimeout(async () => {
          try {
            if(isNewOrganization===true){
              if (isEdit) {
                await patch(`/events/draft/all/${draftId}`, { draftId, tag: values.eventTag, ...dto });
                history.replace('/new-organization');
              } else {
                await post('/events/draft', { tag: values.eventTag, ...dto });
                history.replace('/new-organization');
              }
            }else{
            if (isEdit) {
              await patch(`/events/draft/all/${draftId}`, { draftId, tag: values.eventTag, ...dto });
              history.replace('/events');
            } else {
              await post('/events/draft', { tag: values.eventTag, ...dto });
              history.replace('/events');
            }
          }
          } catch (error) {
            if (error.response?.data?.name === 'ValidationError' && Array.isArray(error.response?.data?.details)) {
              const msg = Object.values(error.response.data.details[0]).join(', ');
              setSubmitError(`Validation failed: ${msg}.`);
            }if (error.response?.data?.message) {
              setSubmitError(error.response.data.message);
            }if(error.response?.status === 403){
              message.warning(`${profileProgress}% of your profile is completed. To update the event you need to get 100%`)
            }else if(error.response?.data?.details[0].videoUrl){
              setSubmitError("Video url does not match any of the allowed types");
              message.error("Video url does not match any of the allowed types")
            }else if(error.response?.data?.details[0].description){
              setSubmitError(`Validation failed: Descripton length must be greater than 300 and less than or equal to 5000 characters long.`);
              message.error(`Validation failed: Descripton length must be greater than 300 and less than or equal to 5000 characters long`)
            }
             else {
              setSubmitError(error.message);
            }
            setNavigationBlocked(false);
            setSubmitting(false);
           
          }
        }, 300);
      })();
    }
  };
  function StyledRadio(props: RadioProps) {
  
    return (
      <Radio
        disableRipple
        color="primary"
        {...props}
      />
    );
  }

  const handleRadioChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setIsOnline((event.target as HTMLInputElement).value);
  };

  const handleFeeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setIsFree((event.target as HTMLInputElement).value);
  };

  const codeBlock=document.getElementsByClassName('fa-code');
  for(let i=0;i<codeBlock.length;i++){
    codeBlock[i].parentElement.style.display="none"
  }

  const checkTitle=(values: any)=>{
    const { title } = values;
    if (!title || title.length < 3 || title.length > 50) {
      message.error('Please insert a valid title.');
    }else{isNewOrganization=true;saveDraft(values)
      }
  }

  const organizationChange=(value:any)=>{
    if(value===null){
      checkTitle(form.getFieldsValue())
    }else{
      setOrganization(value)
    }
  }
  
  return (
    <div style={styles.root}>
      <Helmet>
        <title>{isEdit ? "Edit event":"Create event"}  | pozi.io</title>
      </Helmet>
      <Prompt
        when={navigationBlocked}
        message='You have unsaved changes, are you sure you want to leave?'
      />
      {isLoading && <Loading /> }
      {errorMessage && (
        <>
          <div style={styles.errorText}>Failed to load event:</div>
          <div style={{ ...styles.errorText, marginTop: 0, fontWeight: 400 }}>
            {errorMessage}
          </div>
        </>
      )}
      <div style={{ ...styles.content, display: isLoading ? 'none' : 'block' }}>
        <Cover url={coverImage} onChange={changeCover} />
        <EventTitleCard form={form} fieldKey='title' onAfterTyping={generateTag} />

        <div style={styles.formContent}>
        <InputRow>
            <FormLabel title='Add your organization' style={{ marginTop: 40 }} />
            <OrganizationSearch value={organization} onChange={(value)=>{organizationChange(value)}}/>
          </InputRow>
          <Row gutter={[12, 12]}>
           
            <Col xs={24} sm={12} md={8}>
              <FormLabel title='Application deadline' />
              <FormDateTimePicker
                form={form}
                fieldKey='deadline'
                required
                rules={[{
                  validator: validateDeadline(isEdit
                    ? moment(createdAt) : moment()),
                }]}
                onChange={() => {
                  validateDateByField('deadline');
                  const dlValue = form.getFieldValue('deadline');
                  //form.setFieldsValue({ firstDate: dlValue }); 
                  console.log(dlValue);
                  console.log(form);
                  setDeadlinDate(dlValue);
                  //form.getFieldDecorator('firstDate', { initialValue: dlValue });
                  //form.setFieldsValue({ firstDate: dlValue });
                }}
              />
            </Col>
          </Row>

          <FormLabel title='Duration' />
          <Row gutter={[12, 12]}>
            <Col xs={24} sm={12} md={8}>
              <div>
                <FormLabel style={styles.durationLabels} title='From' />
                <FormDateTimePicker
                  form={form}
                  fieldKey='firstDate'
                  required
                  rules={[
                    {
                      validator: isFutureDateValidator(isEdit
                        ? moment(createdAt) : moment()),
                    },
                  ]}
                  onChange={() => {
                    console.log("DEADLINEDATE: " + deadlineDate);
                    validateDateByField('firstDate');
                    console.log("DEADLINEDATE2: " + deadlineDate);

                  }}
                  // initialValue={
                  //   deadlineDate ? deadlineDate: null
                  // }
                />
              </div>
            </Col>
            <Col xs={24} sm={12} md={8}>
              <div>
                <FormLabel style={styles.durationLabels} title='To' />
                <FormDateTimePicker
                  form={form}
                  fieldKey='lastDate'
                  rules={[{
                    validator: validateLastDate(isEdit
                      ? moment(createdAt) : moment()),
                  }]}
                  onChange={() => {
                    validateDateByField('deadline');
                  }}
                  required
                />
              </div>
            </Col>
          </Row>

          <Row>
            <FormLabel title='Timezone' />
            <Col xs={24} sm={12} md={8}>
              <FormTimezoneSelector form={form} fieldKey='timezone' style={{ color: '#6755CE' }} required />
            </Col>
          </Row>

          <Row>
            {isEdit ? (
              <RadioGroup name="isOnline" value={isOnline} onChange={handleRadioChange} >
                  <FormControlLabel value="online" control={<StyledRadio />} label="Online event" defaultChecked={true} />
                  <FormControlLabel value="offline" control={<StyledRadio />} label="Offline event" />
                </RadioGroup>              ) : (
                <RadioGroup name="isOnline" value={isOnline} onChange={handleRadioChange} >
                  <FormControlLabel value="online" control={<StyledRadio />} label="Online event" defaultChecked={true} />
                  <FormControlLabel value="offline" control={<StyledRadio />} label="Offline event" />
                </RadioGroup>
              )}
          </Row>

          <Row gutter={[12, 12]}>
            <Col xs={24} sm={12} md={8}>
              <div style={styles.locationLabelContainer}>
                <div style={styles.inputLabel}>Location</div>
                <img style={styles.googleLogo} src='/images/google/powered_by_google.png' alt='Powered by Google' />
              </div>
              <FormLocationSelector fieldKey='location' form={form} style={{ color: '#6755CE' }} required />
              
              <div style={{ marginTop: 16, display:'none' }}>
                <GeneralCheckbox
                  text='Pre-registration'
                  checked={isPreregistration}
                  onChange={setPreregistration}
                  textStyle={styles.checkboxText}
                />
              </div>
            </Col>
          </Row>
        <InputRow>
        <Row>
            {isEdit ? (
              <RadioGroup name="isFree" value={isFree} onChange={handleFeeChange} >
                  <FormControlLabel value="free" control={<StyledRadio />} label="Free event" defaultChecked={true} />
                  <FormControlLabel value="paid" control={<StyledRadio />} label="Paid event" />
                </RadioGroup>              ) : (
                <RadioGroup name="isFree" value={isFree} onChange={handleFeeChange} >
                  <FormControlLabel value="free" control={<StyledRadio />} label="Free event" defaultChecked={true} />
                  <FormControlLabel value="paid" control={<StyledRadio />} label="Paid event" />
                </RadioGroup>
              )}
          </Row>
          {isFree==="paid"&&<><FormLabel title='Entry fee $ ' subtitle='(Can be modified later)' style={{ marginTop: 79 }} />
            <Input type='number'   
              placeholder={fee.toString()}
              required
              key='feeButtonText'
              onChange={number=>{setFee(parseInt(number.target.value))}}
            /></>
}
            <FormLabel title='Maximum number of the participants' subtitle='(Can be modified later)' style={{ marginTop: 79 }} />
            <Input type='number'   
              placeholder={maxParticipants.toString().italics()}
              value={maxParticipants}
              required
              key='maxNumberButtonText'
              onChange={number=>{setMaxParticipants(parseInt(number.target.value))}}
            />
            
          </InputRow>
          <Row>
            <FormLabel title='Insert your Youtube, Facebook Stream/Video or Vimeo Link/ Embed Code!' />
            <Col xs={24} sm={24} md={24}>
              <FormInput
                form={form}
                fieldKey='videoUrl'
                rules={[
                  {pattern: new RegExp(/^(?:https?:\/\/)?(?:www\.)?(?:youtu\.be\/|youtube\.com\/(?:embed\/|v\/|watch\?v=|watch\?.+&v=))((\w|-){11})(?:\S+)?$|https?:\/\/(?:www\.|player\.)?vimeo.com\/(?:channels\/(?:\w+\/)?|groups\/([^\/]*)\/videos\/|album\/(\d+)\/video\/|video\/|)(\d+)(?:$|\/|\?)|^(?:<div[^>]+>)?<iframe src="https:\/\/(?:player\.)?vimeo\.com[^"]+"[^>]+><\/iframe>(?:<\/div>)?$|^(https:\/\/www\.facebook\.com).*$|^(https:\/\/fb\.watch).*$/,)
                  ,message:'Please insert a valid youtube, facebook or vimeo url'}
                ]}
              />
            </Col>
          </Row>
          <Row>
            <FormLabel title='Short description' />
            <Col xs={24} sm={24} md={24}>
              <FormTextArea
                form={form}
                fieldKey='shortDescription'
                rules={[
                  { min: 3, message: 'Short description must be at least 3 characters.' },
                  { max: 100, message: 'Short description cannot be longer than 100 characters.' },
                ]}
                autoSize={{ minRows: 4 }}
                required
              />
            </Col>
          </Row>

          <FormLabel title='Description' />
          {!!description && (
            <div className='description-preview' style={styles.descriptionContainer}>
              <HtmlEditor
                schema={schema}
                plugins={plugins}
                value={description}
                handleChange={setDescription}
                debounce={300}
              >
                <Toolbar toolbar={toolbar} />
                <Editor autoFocus />
              </HtmlEditor>
            </div>
          )}

          <FormLabel title='Themes of your event' subtitle='(Choose 5 or less options)' style={{ marginBottom: 25, marginTop: 76 }} />
          <FormInterests form={form} fieldKey='interests' interestGroups={interestGroups} initialValue={themes} />

          <FormLabel title='Event schedule' style={{ marginTop: 119, marginBottom: 35 }} />
          {isEdit ? (
            <Schedule
              form={form}
              initialCount={initialCount}
              isEdit
              createdAt={createdAt}
            />
          ) : (
            <Schedule form={form} isEdit={false} />
          )}
          <InputRow>
            <FormLabel title='Lead button text' subtitle='(Can be modified later)' style={{ marginTop: 79 }} />
            <FormInput
              form={form}
              fieldKey='leadButtonText'
              placeholder='Eg.: Apply Event/Click here to join'
              required
              rules={[
                { min: 3, message: 'Lead button text must be at least 3 characters.' },
                { max: 15, message: 'Lead button text cannot be longer than 15 characters.' },
              ]}
            />
          </InputRow>
          <InputRow>
            <FormLabel title='Redirect URL for lead button' subtitle='(optional)' style={{ marginTop: 79 }} />
            <label>If you redirect the URL you can't accept participants through POZI.io. </label>
            <FormInput
              form={form}
              fieldKey='redirectURL'
              rules={[
                {pattern: new RegExp(/^(https?:\/\/[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,})?$/,)
                ,message:'Please insert a valid url! (valid form http://example.com or https://example.com)'}
              ]}
            />
          </InputRow>
         
          <InputRow>
            <FormLabel title='Unique Virtual Event Tag' subtitle='(Can’t be modified later)' style={{ marginTop: 40 }} />
            <FormValidatedInput
              form={form}
              fieldKey='eventTag'
              placeholder='Eg.: HSTLR2021'
              style={styles.inputText}
              validator={tagValidator}
              required
              isEdited={isTagEdited}
              onIsEdited={setTagEdited}
              rules={[
                { min: 3, message: 'Tag must be at least 3 characters.' },
                { max: 20, message: 'Tag cannot be longer than 20 characters.' },
              ]}
              disabled={isEdit && !isDraft}
            />
          </InputRow>
          <InputRow>
            <FormLabel title='Choose your speakers' style={{ marginTop: 40 }} />
            <UserSearch multiple value={speakers} onChange={setSpeakers} />
          </InputRow>
          {(event && event.createdBy.id === JSON.parse(userFromStorage).id || isCreating || isAdmin()) &&
          <InputRow>
            <FormLabel title='Owner' style={{ marginTop: 40, display: "inline-block", marginRight: 12 }} />       
            <Tooltip placement="topLeft" title={'With this step, you delegate the Owner status to another user, your role will change to co-organizer.'}>
              <InfoCircleOutlined />
            </Tooltip>
            <UserSearch owner multiple={false} value={owner} onChange={(e)=>ownerChange(e)} />
          </InputRow>}
          <InputRow>
            <FormLabel title='Invite co-organizers' style={{ marginTop: 40 }} />
            <UserSearch multiple value={organizers} onChange={setOrganizers} />
          </InputRow>
          <InputRow>
            <FormLabel title='Partnership' style={{ marginTop: 40 }} />
            <Partnership multiple value={partner} onChange={setPartner}/>
          </InputRow>

          <FormLabel title='Organizer logos' style={{ marginTop: 80 }} />
          <PartnerLogos value={organizerLogos} onChange={setOrganizerLogos} />

          <FormLabel title='Supporter/Partner logos' style={{ marginTop: 40 }} />
          <PartnerLogos value={partnerLogos} onChange={setPartnerLogos} />

          <div id="errors" style={{ whiteSpace: "break-spaces", color: "red", textAlign: "center" }}>

          </div>
          <div style={styles.submitContainer}>
            {!isEdit && (
              <div style={styles.submitText}>Are you ready?</div>
            )}
            <div style={styles.btnContainer}>
              {(isDraft || (!isEdit && !isDraft)) && (
                <Button style={{ fontWeight: 'bold', minWidth: 150 }} loading={isSubmitting} type='primary' size='large' onClick={() => saveDraft(form.getFieldsValue())}>
                  Save draft
                </Button>
              )}
              <Button style={{ fontWeight: 'bold', minWidth: 150 }} loading={isSubmitting} type='primary' size='large' onClick={submitForm}>
                {isDraft || (!isEdit && !isDraft) ? 'Launch' : 'Save changes'}
              </Button>
              {isEdit && (
                <Button style={{ fontWeight: 'bold', minWidth: 150 }} loading={isSubmitting} type='danger' size='large' onClick={() => setDeleteDialogVisible(true)}>
                  Delete event
                </Button>
              )}
            </div>
          </div>
          {submitError && (
            <Alert
              style={{ marginTop: 32 }}
              message='Failed to submit form'
              description={submitError}
              type='error'
              showIcon
            />
          )}
        </div>
      </div>
      <ConfirmationDialog
        visible={deleteDialogVisible}
        onOk={() => {
          setNavigationBlocked(false);
          setDeleteDialogVisible(false);
          deleteEvent();
        }}
        onCancel={() => setDeleteDialogVisible(false)}
        title='Delete event'
        description='Are you sure you want to delete event? This operation is not revertible.'
        confirmBtnText='Delete'
        confirmBtnType='danger'
      />
       <Modal
        visible={createOrganization}
        onOk={() => {
          setCreateOrganization(false);
          saveDraft(form.getFieldsValue)
            window.location.replace(window.origin+'/new-organization')
        }}
        onCancel={() => {setCreateOrganization(false)}}
        title='Create organization'
      />
    </div>
  );
};

interface Props {
  form: WrappedFormUtils;
  isEdit?: boolean;
  isDraft?: boolean;
  isCreating?: boolean;
}

newEvent.defaultProps = {
  isEdit: false,
  isDraft: false,
};
// export default newEvent;
export default Form.create<Props>({ name: 'event' })(newEvent);
