import React, { useEffect, useState } from 'react';
import ga4 from 'react-ga4';
import {
  Button, message, Modal, Select,
} from 'antd';
import '../Event/Event.css';
import { get, post } from '../../../libs/utils/request';
import { IEvent, } from '../Event/Event';
import { user } from '../../../libs/utils/user';
import BorderCard from '../../common/BorderCard';
import TextWithLink from '../../common/TextWithLink';


const { Option } = Select;

const baseText = {
  fontFamily: 'Inter',
  fontSize: 16,
  fontStyle: 'normal',
  color: '#282828',
};
const styles = {
  root: {
    padding: 31,
  },
  content: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row' as const,
    justifyContent: 'center',
  },
  welcome: {
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontWeight: 900,
    fontSize: 22,
    color: '#282828',
    marginBottom: 16,
  },
  description: {
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontWeight: 300,
    fontSize: 16,
    color: '#282828',
    marginBottom: 16,
  },
  footer: {
    width: '100%',
    marginTop: 50,
    display: 'flex',
    flexDirection: 'row-reverse' as const,
    flexWrap: 'wrap' as const,
    gap: '10px 10px',
  },
  btn: { fontWeight: 'bold' as const },
};

const welcomeText = 'Submit your application';
const description = 'Are you sure you want to attend this event?'

const EventApplicationModal = ({
  tag, visible, onSubmitApplication, onCancel, isPreregistration, additionalInfo,
}: Props) => {
  const [selectedType, setSelectedType] = useState(0); // 1: participant, 2: startup
  const [isSubmitting, setSubmitting] = useState(false);


  useEffect(() => {
    setSelectedType(1);
  }, [visible]);

  useEffect(() => {
    if (visible === true && !isSubmitting) {
      ga4.ga('send', { 'hitType': 'pageview', 'page': 'events/application', 'title': 'Application modal' });
    }
  }, [visible])


  const submitApplication = async () => {
    const isParticipant = selectedType === 1;
    if (selectedType) {
      setSubmitting(true);
      try {
        const id = user().id;

        await post(`/events/${tag}/apply`, { id });
        onSubmitApplication();

      } catch (error) {
        message.error('Failed to submit application.');
      } finally {
        setSubmitting(false);
      }
    }
  };

  return (
    <Modal
      visible={visible}
      onCancel={onCancel}
      maskClosable={false}
      footer={null}
      destroyOnClose
      width={520}
      className="event-modal"
    >
      <div className='summary-description' style={styles.welcome}>{welcomeText}</div>
      {description && (
        <div style={styles.description}>{description}</div>
      )}  <div style={styles.footer}>
        <Button
          style={styles.btn}
          type='primary'
          size='large'
          disabled={false}
          onClick={submitApplication}
          loading={isSubmitting}
        >
          Submit your application
        </Button>
      </div>



    </Modal>
  );
};

interface Props {
  tag: string;
  visible: boolean;
  onSubmitApplication: () => void;
  onCancel: () => void;
  isPreregistration: boolean;
  additionalInfo?: string;
}

EventApplicationModal.defaultProps = {
  additionalInfo: '',
};

export default EventApplicationModal;
