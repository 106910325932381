import { useEffect } from 'react';
import { useParams } from 'react-router';
import { post } from '../../libs/utils/request';
import { Loading } from '../common/Loading';

const FollowOrganisation = () => {
  const { tag } = useParams();

  useEffect(() => {
    follow()
  }, [])

  const follow = async () => {
    await post(`/organizations/${tag}/follow`);
    window.location.replace(`/organizations/${tag}`)
  }
}

export default FollowOrganisation;