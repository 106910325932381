import { Button } from "antd";
import React, { useState } from "react";
import ReactGA from "react-ga4";

const RegistrationButtons = ({ roleName, next, back, save, showBackbutton, showNextbutton, showSkipButton, showFinishButton }: Props) => {
  const trackingId = "G-G5B6N29N50"; // Replace with your Google Analytics tracking ID
  ReactGA.initialize(trackingId);
  const eventTrack = (category: string, action: string, label: string) => {
    ReactGA.event({
      category: category,
      action: action,
      label: label,
    })
  }
  return (
    <div className="navigation-container">
      <div className="buttons-container">
        {showBackbutton &&
          <Button
            id="back-button-registration"
            onClick={() => {
              save();
              back();
              eventTrack('registration', `back ${roleName}`, 'button')
            }}
          >
            Back
          </Button>
        }
        {showNextbutton &&
          <Button
            type="primary"
            className="registration-next-btn"
            onClick={() => {
              save();
              next();
              eventTrack('registration', `next ${roleName}`, 'button')
            }}
          >
            Next
          </Button>}
        {showFinishButton &&
          <Button
            type="primary"
            className="registration-next-btn"
            onClick={() => {
              save();
              next();
              eventTrack('registration', `finish ${roleName}`, 'button')
            }}
          >
            Finish
          </Button>}
      </div>
      {showSkipButton &&
        <div
          className="skip-registration-step"
          onClick={() => {
            save();
            next();
            eventTrack('registration', `skip ${roleName}`, 'button')
          }}
        >
          Skip this step
        </div>}
    </div>
  );
};

interface Props {
  next?: () => any;
  back?: () => any;
  save?: () => any;
  showBackbutton?: boolean;
  showNextbutton?: boolean;
  showSkipButton?: boolean;
  showFinishButton?: boolean;
  roleName: string;
}
export default RegistrationButtons;
