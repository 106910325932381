import React from "react";
import { Select } from "antd";
export const Option = Select;

export const renderOrderByField = (orderChangedMethod: any) => (
  <div style={{ width: '250px', textAlign: 'left', }} >
    <h2 className="item-title-register">
      Order by: 
    </h2>
    <Select defaultValue={'creation date desc'} style={{ width: 250 }} onChange={(order: any) => {
    { orderChangedMethod(order) }
      }}>
      <Option value={'name desc'}>name (Z - A)</Option>
      <Option value={'name asc'}>name (A - Z)</Option>
      <Option value={'creation date asc'}>creation date (older first)</Option>
      <Option value={'creation date desc'}>creation date (newer first)</Option>
      <Option value={'update date asc'}>last update (older first)</Option>
      <Option value={'update date desc'}>last update (newer first)</Option>
    </Select>
          </div>
);
  
const countryFilterOption = (input: string, option: any) => {
  return option.props.children.toLowerCase().includes(input.toLowerCase());
};

export const renderCountryFilterField = (arr: any, defValue: any, onChanged: any) => (
  <div style={{ width: '250px', textAlign: 'left',}}>
  <h2 className="item-title-register">
                Country filter: 
  </h2>
  <Select
    defaultValue={defValue}
    style={{ width: 250 }}
    onChange={(selectedCountry: any) => {
      onChanged(selectedCountry)
    }}
    showSearch
    filterOption={countryFilterOption}
  >
  {	
        arr.map((country: any) => (
        <Option
          key= {country}
          value={country}
        >
          {country}
        </Option>
        ))
      }
  </Select>
</div>
);

