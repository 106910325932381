import React, { ReactElement, useEffect, useState } from "react";
import { Button, Pagination, message, Modal, Input } from "antd";
import Masonry from "react-masonry-css";
import { useHistory } from "react-router";
import { Helmet } from "react-helmet";
import { get, post } from "../../libs/utils/request";
import { Loading } from "../common/Loading";
import { user, isAdmin } from "../../libs/utils/user";
import { ArticleCard } from "../CardView/ProjectCard";
import AutoSearchInput from "../common/AutoSearchInput";
import ErrorMessage from "../common/ErrorMessage";
import DraftsCard from "./DraftsCard";

const styles = {
  root: {
    width: "100%",
    paddingTop: 60,
    paddingBottom: 80,
    paddingLeft: 20,
    paddingRight: 20,
  },
  content: {
    maxWidth: 1200,
    marginRight: "auto",
    marginLeft: "auto",
    marginTop: 80,
  },
  titleContainer: {
    width: "100%",
    display: "flex",
    flexDirection: "row" as const,
    justifyContent: "space-between",
    flexWrap: "wrap" as const,
    gap: "16px 16px",
  },
  title: {
    fontFamily: "Inter",
    fontStyle: "normal",
    fontSize: 30,
    fontWeight: 900,
    color: "#000",
  },
  paginationContainer: {
    width: "100%",
    display: "flex",
    flexDirection: "row" as const,
    justifyContent: "center",
  },
  submitBtn: { fontWeight: "bold" as const, minWidth: 150, marginTop: 16 },
};

const Articles = () => {
  const [isLoading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [articles, setArticles] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [search, setSearch] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [drafts, setDrafts] = useState([]);
  const [collapsed, setCollapsed] = useState(true);
  const userFromStorage = sessionStorage.getItem("user");
  const [email, setEmail] = useState("");
  // const [showSubscriptionModal, setShowSubscriptionModal] = useState(false);
  const [windowSize, setWindowSize] = useState(getWindowSize());

  function getWindowSize() {
    const { innerWidth, innerHeight } = window;
    return { innerWidth, innerHeight };
  }

  useEffect(() => {
    function handleWindowResize() {
      setWindowSize(getWindowSize());
    }

    window.addEventListener("resize", handleWindowResize);

    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, []);

  // setTimeout(() => {
  //   if (
  //     !(
  //       sessionStorage.getItem("subscriptionClosed")
  //     )
  //   ) {
  //     setShowSubscriptionModal(true);
  //   }
  // }, 1 * 6000);
  // // , 5*60000);

  const profileProgress = userFromStorage
    ? JSON.parse(userFromStorage).progress
    : 0;

  const [limit, setLimit] = useState(20);

  const history = useHistory();

  useEffect(() => {
    (async () => {
      setErrorMessage("");
      setLoading(true);
      try {
        const page = localStorage.getItem("blogPage");
        const params: any = {
          currentPage: page,
          limit,
        };
        if (search) {
          params.search = search;
        }

        if (user()) {
          const draftsTmp = await get<{ drafts: any[] }>("blog/draft/all");
          setDrafts(draftsTmp.drafts);
        }
        const tmp = await get<{ data: any[]; pagination: any }>("blog", params);
        setArticles(tmp.data);
        setTotalCount(tmp.pagination.totalCount);
        if (currentPage !== tmp.pagination.currentPage) {
          setCurrentPage(parseInt(page));
        }
        if (limit !== tmp.pagination.limit) {
          setLimit(tmp.pagination.limit);
        }
      } catch (error) {
        if (error.response.status === 403 && error.response.data.extendedUser) {
          sessionStorage.setItem(
            "user",
            JSON.stringify(error.response.data.extendedUser)
          );
          if (
            !error.response.data.extendedUser.active &&
            !error.response.data.extendedUser.trial
          ) {
            window.location.replace("/trial-ended");
          }
        } else if (error.response?.data?.message) {
          setErrorMessage(error.response.data.message);
        } else {
          setErrorMessage(error.message);
        }
      } finally {
        setLoading(false);
      }
    })();
  }, [currentPage, limit, search]);

  const getArticles = async (withLoading = true) => {
    setErrorMessage("");
    if (withLoading) {
      setLoading(true);
    }
    try {
      const page = localStorage.getItem("blogPage");
      const params: any = {
        currentPage: page,
        limit,
      };
      if (search) {
        params.search = search;
      }
      const draftsTmp = await get<{ drafts: any[] }>("blog/draft/all");
      setDrafts(draftsTmp.drafts);
      const tmp = await get<{ data: any[]; pagination: any }>("blog", params);
      setArticles(tmp.data);
      setTotalCount(tmp.pagination.totalCount);
      if (currentPage !== tmp.pagination.currentPage) {
        window.location.replace(`/blog/page=${currentPage}`);
        setCurrentPage(parseInt(page));
      }
      if (limit !== tmp.pagination.limit) {
        setLimit(tmp.pagination.limit);
      }
    } catch (error) {
      if (error.response.status === 403 && error.response.data.extendedUser) {
        sessionStorage.setItem(
          "user",
          JSON.stringify(error.response.data.extendedUser)
        );
        if (
          !error.response.data.extendedUser.active &&
          !error.response.data.extendedUser.trial
        ) {
          window.location.replace("/trial-ended");
        }
      } else if (error.response?.data?.message) {
        setErrorMessage(error.response.data.message);
      } else {
        setErrorMessage(error.message);
      }
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const onAddNew = () => {
    history.push("/new-article");
  };

  const onPageChange = (page: number) => {
    setCurrentPage(page);
    localStorage.setItem("blogPage", page.toString());
    window.location.reload();
  };

  // const sendSubscription = async () => {
  //   await post("/subscription", { email })
  //     .then((response) => {
  //       message.success(response, 5);
  //       let text = ((
  //         document.getElementById(
  //           "homepage-email-subscribe-input"
  //         ) as HTMLInputElement
  //       ).value = "");
  //       sessionStorage.setItem("subscriptionClosed", "true");
  //       setShowSubscriptionModal(false);
  //     })
  //     .catch((e) => {
  //       console.log(e);
  //       message.success("Subscribe failed", 5);
  //       message.error(e.response?.data?.message || "Network error");
  //     });
  // };

  const renderPaginationButtons = (marginTop = 0) => (
    <div style={{ ...styles.paginationContainer, marginTop }}>
      <Pagination
        current={currentPage}
        defaultPageSize={40}
        pageSize={limit}
        onChange={onPageChange}
        total={totalCount}
        style={{ marginTop }}
      />
    </div>
  );

  const renderWithPager = (content: ReactElement) => (
    <>
      {renderPaginationButtons(16)}
      {isLoading ? <Loading /> : content}
      {renderPaginationButtons(42)}
    </>
  );

  const renderPagination = () => {
    if (errorMessage) {
      return (
        <ErrorMessage
          title="Failed to load articles."
          message={errorMessage}
          withRetry
          onRetry={() => getArticles()}
        />
      );
    }
    if (!articles.length && !isLoading) {
      return (
        <div className="no-result">
          We didn't found anything. Maybe it's a new article?
        </div>
      );
    }
    return renderWithPager(
      <Masonry
        breakpointCols={{ default: 2, 850: 1 }}
        className="startup-box-view"
        columnClassName="startup-box-view-item"
      >
        {articles.map((article: any) => (
          <ArticleCard
            key={article.tag}
            article={article}
            onChange={() => getArticles()}
            onClick={() => {
              history.push(`/blog/${article.tag}`);
            }}
          />
        ))}
      </Masonry>
    );
  };

  return (
    <div style={styles.root}>
      <div style={styles.content}>
        <Helmet>
          <title>Blog | pozi.io</title>
        </Helmet>
        <div style={styles.titleContainer}>
          <div>
            <div style={styles.title}>Articles</div>
            {!!user() && user().active && isAdmin() && (
              <Button
                style={styles.submitBtn}
                type="primary"
                size="large"
                onClick={() =>
                  !isAdmin()
                    ? message.warning(
                        `You don't have permission to create a new article!`
                      )
                    : onAddNew()
                }
              >
                Create new
              </Button>
            )}
          </div>
          <div style={{ minWidth: 280 }}>
            <AutoSearchInput
              onSearch={(value) => {
                setSearch(value);
                setCurrentPage(1);
              }}
              style={{ width: "100%" }}
              placeholder="Type something..."
            />
          </div>
        </div>
        {!!drafts.length && isAdmin() && (
          <DraftsCard
            type={"article"}
            drafts={drafts}
            collapsed={collapsed}
            setCollapsed={setCollapsed}
          />
        )}
        {renderPagination()}
      </div>
      {/* {showSubscriptionModal && (
        <p>
          <Modal
            className="registration-modal-view"
            visible={true}
            destroyOnClose
            footer={null}
            width={"min-content"}
            onCancel={() => {
              setShowSubscriptionModal(false);
              sessionStorage.setItem("subscriptionClosed", "true");
            }}
            closeIcon={
              <img
                src="/images/popup/cross.png"
                className="subscription-popup-close-icon"
              />
            }
          >
            <div className="home-page-popup-container">
              <div className="homepage-popup-image-container">
                <img src="/images/popup/Turcsan.png" />
              </div>
              <div className="homepage-description-container">
                <div className="homepage-description-container-title">
                  Choose Wisely!
                </div>
                <div className="homepage-description-container-description">
                  Start your week with a fresh dose of startup news, updates,
                  hints & tips served by our lovely team.
                  <br /> <br />
                  It’s time for you to be up-to-date. Subscribe to our
                  newsletter:
                </div>
                <div className="homepage-description-container-input">
                  {windowSize.innerWidth > 590 ? (
                    <Input.Group compact>
                      <Input
                        style={{
                          width: "calc(100% - 150px)",
                          borderTopLeftRadius: "13px",
                          borderBottomLeftRadius: "13px",
                        }}
                        placeholder="Email Address"
                        onChange={(e) => setEmail(e.target.value)}
                      />
                      <button
                        type="button"
                        className="ant-btn ant-btn-primary"
                        onClick={() => sendSubscription()}
                      >
                        Subscribe
                      </button>
                    </Input.Group>
                  ) : (
                    <>
                      <Input
                        placeholder="Email Address"
                        onChange={(e) => setEmail(e.target.value)}
                      />
                      <button
                        type="button"
                        className="ant-btn ant-btn-primary"
                        onClick={() => sendSubscription()}
                      >
                        Subscribe
                      </button>
                    </>
                  )}
                </div>
                <div className="homepage-description-container-link">
                  <a>
                    By subscribing to the newsletter, you accept our Privacy
                    Policy.
                  </a>
                </div>
                <div className="homepage-description-container-oneline">
                  Working together with Top Leaders and Organisations
                </div>
                <div className="homepage-description-container-logos">
                  <img src="/images/popup/nbx.png" />
                  <img src="/images/popup/Institute.png" />
                  <img src="/images/popup/Draper2.png" />
                  <img src="/images/popup/Draper.png" />
                  <img src="/images/popup/women.png" />
                  <img src="/images/popup/worldCup.png" />
                  <img src="/images/popup/TalCatran.png" />
                </div>
              </div>
            </div>
          </Modal>
        </p>
      )} */}
    </div>
  );
};

export default Articles;
