import { message } from "antd";
import React from "react";
import CopyToClipboard from "react-copy-to-clipboard";
import styles from "./styles.module.css";

const RssFeed = () => {
  return (
    <div className="Content-container">
      <h2 className={styles.rssfeedtitle}>RSS feeds</h2>
      <span className={styles.rsscontainer}>
        <div>
          Ideas:{" "}
          <CopyToClipboard
            text={`https://api.${window.location.host}/rss/ideas.xml`}
            onCopy={() => {
              message.success("Link copied to clipboard");
            }}
          >
            <span className={styles.link}>
              https://api.{window.location.host}/rss/ideas.xml
            </span>
          </CopyToClipboard>
        </div>
        <div>
          Startups:{" "}
          <CopyToClipboard
            text={`https://api.${window.location.host}/rss/startups.xml`}
            onCopy={() => {
              message.success("Link copied to clipboard");
            }}
          >
            <span className={styles.link}>
              https://api.{window.location.host}/rss/startups.xml
            </span>
          </CopyToClipboard>
        </div>
        <div>
          Competitions:{" "}
          <CopyToClipboard
            text={`https://api.${window.location.host}/rss/competitions.xml`}
            onCopy={() => {
              message.success("Link copied to clipboard");
            }}
          >
            <span className={styles.link}>
              https://api.{window.location.host}/rss/competitions.xml
            </span>
          </CopyToClipboard>
        </div>
        <div>
          Events:{" "}
          <CopyToClipboard
            text={`https://api.${window.location.host}/rss/events.xml`}
            onCopy={() => {
              message.success("Link copied to clipboard");
            }}
          >
            <span className={styles.link}>
              https://api.{window.location.host}/rss/events.xml
            </span>
          </CopyToClipboard>
        </div>
        <div>
          Organizations:{" "}
          <CopyToClipboard
            text={`https://api.${window.location.host}/rss/organizations.xml`}
            onCopy={() => {
              message.success("Link copied to clipboard");
            }}
          >
            <span className={styles.link}>
              https://api.{window.location.host}/rss/organizations.xml
            </span>
          </CopyToClipboard>
        </div>
        <div>
          News:{" "}
          <CopyToClipboard
            text={`https://api.${window.location.host}/rss/news.xml`}
            onCopy={() => {
              message.success("Link copied to clipboard");
            }}
          >
            <span className={styles.link}>
              https://api.{window.location.host}/rss/news.xml
            </span>
          </CopyToClipboard>
        </div>
      </span>
    </div>
  );
};

export default RssFeed;
