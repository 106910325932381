import React, { useState } from 'react';
import { DatePicker, TimePicker } from 'antd';
import moment, { Moment } from 'moment';
import './DateTimePicker.css';

const styles = {
  date: {
    cursor: 'pointer',
    marginRight: 4,
    marginBottom: 4,
    width: 120,
  },
  time: {
    cursor: 'pointer',
    marginBottom: 4,
    width: 120,
  },
};

export const DateTimePicker = ({
  value, onChange, required, withTime, name, disabledDate, defaultPickerValue, className
}: Props) => {
  const [date, setDate] = useState('');
  const [time, setTime] = useState('');

  const updateValue = (d: string, t: string) => {
    if (d) {
      onChange(t ? moment(`${d} ${t}`, 'YYYY-MM-DD HH:mm') : moment(d));
    } else {
      onChange(null);
    }
  };

  const onDateChange = (v: Moment, ts: string) => {
    setDate(ts);
    updateValue(ts, v.format('HH:mm').toString());
  };

  const onTimeChange = (v: Moment, ts: string) => {
    setTime(ts);
    updateValue(v.format('YYYY-MM-DD').toString(), ts);
    };
  return (
    <div>
      <DatePicker
        name={name}
        style={styles.date}
        onChange={onDateChange}
        value={value}
        allowClear={!required}
        showToday={false}
        disabledDate={disabledDate ? disabledDate : null}
        defaultPickerValue={defaultPickerValue?  defaultPickerValue : null}
        className = {className ? className : undefined}
      />
      {withTime && <TimePicker style={styles.time} format='HH:mm' onChange={onTimeChange} value={value} />}
    </div>
  );
};

interface Props {
  value: Moment;
  onChange: any;
  required: boolean;
  withTime?: boolean;
  name?: string;
  disabledDate? : (current: moment.Moment)=> boolean
  defaultPickerValue? :any 
  className?: string;
}

DateTimePicker.defaultProps = {
  withTime: true,
  name: 'dateTimePicker',
};
