import React from 'react'
import { get } from '../../libs/utils/request'
import { Button, Table, message, Select } from 'antd'
import { Loading } from '../common/Loading'
import { CheckSquareOutlined, CloseOutlined } from '@ant-design/icons'
import { Link } from "react-router-dom";
import { InvitationModal } from './InvitationModal'
import { Helmet } from 'react-helmet';
const { Option } = Select;
interface Props {
  match: any
  history: any
}

interface State {
  invitations: any
  modal: boolean
  order: string
}

class InvitationsPage extends React.Component<Props, State> {

  state: State = {
    invitations: null,
    modal: false,
    order: 'sentAtDesc'
  }

  async componentDidMount() {
    await this.list();
  }

  async list() {
    try {
      this.setState({
        invitations: await get(`/invitation/list?order=${this.state.order}`),
      });
    } catch (error) {
      if(error.response.status === 403 && error.response.data.extendedUser){
        sessionStorage.setItem("user", JSON.stringify(error.response.data.extendedUser))
        if(!error.response.data.extendedUser.active && !error.response.data.extendedUser.trial){
          window.location.replace('/trial-ended')
      }}
      message.error('failed to fetch invitations')
    }
  }

  async onOrderChange(order: string | undefined = undefined) {
    localStorage.setItem('invitationOrder', order.toString())
		try {
			await this.setState({
				order: order === undefined ? this.state.order : order
			})
      await this.list();
			//await this.getUsers(this.state.page, this.state.search, this.state.order)
		} catch (e) {
			console.error(e)
		}
	}

  render() {
    const { invitations } = this.state
    const userFromStorage = sessionStorage.getItem('user')
    const profileProgress =  userFromStorage ? JSON.parse(userFromStorage).progress : 0
  

    if (invitations === null) {
      return <div className="Content-container">
        <Loading />
      </div>
    }

    return <div className="Content-container">
      <Helmet>
        <title>Invitations | pozi.io</title>
      </Helmet>
      <div style={{ width: '100%' }}>
        <h3 style={{
          fontSize: '1.5rem',
          fontWeight: 900,
          marginTop: '1.5em',
        }}>Invitations</h3>
        <Button
          onClick={() => 
            this.setState({ modal: true })}
          loading={false}
          type="primary"
          size="large"
          block
          style={{
            width: '200px',
            marginBottom: '50px',
          }}>Invite</Button>

<div style={{ width: '250px', paddingBottom: '30px'}}>
<h2 className="item-title-register">
                      		Order by: 
            </h2>
						<Select defaultValue={this.state.order} style={{ width: 250 }}  onChange={(order: any) => {
									this.onOrderChange(order)
								}} >
								<Option value={'sentAtDesc'}>Sent date (newer first)</Option>
								<Option value={'sentAtAsc'}>Sent date (older first)</Option>
								<Option value={'emailDesc'}>Email (Z - A)</Option>
								<Option value={'emailAsc'}>Email (A - Z)</Option>
                <Option value={'invitedUserDesc'}>Invited user (Z - A)</Option>
								<Option value={'invitedUserAsc'}>Invited user (A - Z)</Option>
								<Option value={'linkVisitedDesc'}>Link visited (yes first)</Option>
								<Option value={'linkVisitedAsc'}>Link visited (no first)</Option>
                <Option value={'registeredAtDesc'}>Registration date (newer first)</Option>
								<Option value={'registeredAtAsc'}>Registration date (older first)</Option>
							
							</Select>
						</div>
            <div> </div>
      </div>
      <Table dataSource={invitations.map((i: any) => ({
        ...i,
        invitedUser: i.invitedUser
          ? <Link to={'/entrepreneurs/' + i.invitedUser.uniqueTag} target={'_blank'}>{`${i.invitedUser.firstName} ${i.invitedUser.lastName}`}</Link>
          : 'Not registered user',
        visited: i.visited ? <CheckSquareOutlined /> : <CloseOutlined />
      }))} columns={[
        {
          title: 'Email',
          dataIndex: 'email',
          key: 'email',
        },
        {
          title: 'Registered user',
          dataIndex: 'invitedUser',
          key: 'invitedUser',
        },
        {
          title: 'Link visited',
          dataIndex: 'visited',
          key: 'visited',
        },
        {
          title: 'Sent at',
          dataIndex: 'lastSend',
          key: 'lastSend',
        },
        {
          title: 'Registered at',
          dataIndex: 'registeredAt',
          key: 'registeredAt',
        },
      ]} />
      <InvitationModal
        open={this.state.modal}
        onClose={() => this.setState({ modal: false })}
        onInvitations={(invitations) => {
          this.setState({
            invitations
          })
        }}
      />
    </div>
  }

}

export default InvitationsPage
