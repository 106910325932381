import React, { useCallback, useEffect, useReducer, useState } from 'react';
import {
  Button, Input, message, Modal, Select,
} from 'antd';
import ga4 from 'react-ga4';
import './Competition.css';
import { get, post } from '../../../libs/utils/request';
import { ICompetition, IMyProjects } from './Competition';
import { user } from '../../../libs/utils/user';
import BorderCard from '../../common/BorderCard';
import TextWithLink from '../../common/TextWithLink';
import { Link } from 'react-router-dom';
import FormikInput from '../../common/form/FormikInput';


const { Option } = Select;

const baseText = {
  fontFamily: 'Inter',
  fontSize: 16,
  fontStyle: 'normal',
  color: '#282828',
};
const styles = {
  root: {
    padding: 31,
  },
  content: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row' as const,
    justifyContent: 'center',
  },
  welcome: {
    ...baseText,
    fontSize: 20,
    fontStyle: 'normal',
    fontWeight: 300,
    whiteSpace: 'pre-wrap' as const,
  },
  infoCard: {
    width: '100%',
    marginTop: 40,
    marginBottom: 20,
    padding: 16,
  },
  infoTitle: {
    ...baseText,
    fontWeight: 900,
    marginBottom: 16,
  },
  infoText: {
    ...baseText,
    fontSize: 15,
  },
  label: {
    fontFamily: 'Inter',
    fontSize: 16,
    fontStyle: 'normal',
    fontWeight: 600,
    color: '#6755CE',
  },
  select: {
    width: 250,
    color: '#6755CE',
  },
  btnContainer: {
    width: '100%', display: 'flex', justifyContent: 'center', marginTop: 56,
  },
  btn: { fontWeight: 'bold' as const },
};

const welcomeText = 'Hi there, \n'
  + '\n'
  + 'Thank you for your application! We are eager, to see your innovative ideas.\n'
  + '\n'
  + 'The Pozi Team';

const ApplicationModal = ({
  tag, visible, onSubmitApplication, onCancel, myProjects, isPreregistration, additionalInfo,
}: Props) => {
  const [selectedType, setSelectedType] = useState(0); // 1: participant, 2: startup
  const [selectedProject, setSelectedProject] = useState(null);
  const [projects, setProjects] = useState([]);
  const [isSubmitting, setSubmitting] = useState(false);
  // const [input, setInput] = useState('')
  const [inputTitle, setInputTitle] = useState('')
  const initialState = { input: '' }

  useEffect(() => {
    setSelectedType(0);
    setSelectedProject(null);
  }, [visible]);

  if (window.location.pathname.includes(tag)) {
    useEffect(() => {

      if (visible === true) {
        ga4.ga('send', { 'hitType': 'pageview', 'page': 'competitions/application', 'title': 'Application modal' });
      }
    }, [visible])
  }
  else {
    useEffect(() => {
      if (visible === true) {
        ga4.ga('send', { 'hitType': 'pageview', 'page': 'competitions/application', 'title': 'Application modal' });
      }
    }, [projects])
  }


  useEffect(() => {
    const { startups, ideas } = myProjects;
    let res = [];

    if (startups) {
      res = startups.map((item: any) => ({ ...item, isStartup: true }));
    }
    if (ideas) {
      res = res.concat(ideas.map((item: any) => ({ ...item, isStartup: false })));
    }

    setProjects(res);
  }, [myProjects]);

  const submitApplication = async () => {
    const isParticipant = selectedType === 1;
    if (selectedType) {
      setSubmitting(true);
      try {
        const id = isParticipant ? user().id : selectedProject;
        let type;
        if (isParticipant) {
          type = 'User';
        } else {
          type = projects.find((item) => item.id === selectedProject).isStartup ? 'Startup' : 'Idea';
        }
        await post(`/competitions/${tag}/apply`, { id, type });
        onSubmitApplication();

      } catch (error) {
        message.error('Failed to submit application.');
      } finally {
        setSubmitting(false);
      }
    }
  };

  const setTitle = (e: any) => {
    setInputTitle(e.target.value);
  }
  const reducer = (state: any, action: any) => {
    switch (action.type) {
      case 'startup':
        return { input: 'startup' };
      case 'idea':
        return { input: 'idea' };
      case 'simple':
        return { input: '' };
      default:
        return { input: '' };
    }
  }

  const [state, dispatch] = useReducer(reducer, initialState)
  const createDraftSubmit = async () => {
    const name = inputTitle
    await post(`/competitions/${tag}/apply-with-draft`, { name: inputTitle })
      .then(async (res: any) => {
        setSubmitting(true)
        await message.success('Your application sent')
        await window.location.replace(window.location.origin + '/edit-startup-draft/' + res.id)
      })
  }

  const createIdeaDraftSubmit = async () => {
    const name = inputTitle
    await post(`competitions/${tag}/apply-with-new-idea`, { name: inputTitle })
      .then(async (res: any) => {
        setSubmitting(true)
        await message.success('Your application sent')
        await window.location.replace(window.location.origin + '/idea/' + res.id)
      })
  }

  return (
    <Modal
      visible={visible}
      onCancel={() => {
        onCancel()
        dispatch({ type: '' })
      }}
      maskClosable={false}
      footer={null}
      destroyOnClose
      width={826}
      className="application-modal"
    >
      <div style={styles.root}>
        <div className='summary-description' style={styles.welcome}>{welcomeText}</div>
        {!!additionalInfo && (
          <BorderCard isTop isBottom style={styles.infoCard} borderColor='#6755CE'>
            <div style={styles.infoTitle}>
              Additional information by the Organizers
              {' '}
              <span style={{ fontWeight: 'normal' }}>
                (Pozi does not take responsibility for it.)
              </span>
            </div>
            <TextWithLink text={additionalInfo} textStyle={styles.infoText} />
          </BorderCard>
        )}
        <div style={styles.content}>
          <div>
            <div style={{ ...styles.label, marginTop: 60, marginBottom: 12 }}>
              Application type
            </div>
            <Select
              style={styles.select}
              onChange={setSelectedType}
              placeholder='Choose from the list'
            >
              {isPreregistration && (
                <Option value={1}>Participant</Option>
              )}
              <Option value={2}>Startup owner / Team member</Option>
            </Select>
            {selectedType === 2 && (
              <>
                <div style={{ ...styles.label, marginTop: 32, marginBottom: 12 }}>
                  Startup or Idea
                </div>
                <Select
                  style={styles.select}
                  onChange={setSelectedProject}
                  placeholder='Choose from the list'
                >
                  {myProjects.startups !== undefined && <Option key='create-new' value='Create startup'><p onClick={() => { dispatch({ type: 'startup' }) }}>Create startup</p></Option>}
                  {myProjects.ideas !== undefined && <Option key='create-new' value='Create idea'><p onClick={() => { dispatch({ type: 'idea' }) }}>Create idea</p></Option>}
                  {projects.map((item) => (
                    <Option key={item.id} value={item.id}><p onClick={() => { dispatch({ type: '' }) }}> {item.name} </p></Option>
                  ))}
                </Select>

              </>)}
            {state.input === 'startup' && <>
              <div style={{ ...styles.label, marginTop: 32, marginBottom: 12 }}>
                Your startup's name
              </div>
              <Input
                style={styles.select}
                onChange={setTitle}
              ></Input>
            </>}
            {state.input === 'idea' &&
              <>
                <div style={{ ...styles.label, marginTop: 32, marginBottom: 12 }}>
                  Your idea's name
                </div>
                <Input
                  style={styles.select}
                  onChange={setTitle}
                ></Input>
              </>}
            <div style={styles.btnContainer}>
              {(state.input !== 'startup' && state.input !== 'idea') ? <Button
                style={styles.btn}
                type='primary'
                size='large'
                disabled={!selectedType || (selectedType === 2 && !selectedProject)}
                onClick={submitApplication}
                loading={isSubmitting}
              >
                Submit your application
              </Button>
                :
                <Button
                  style={styles.btn}
                  type='primary'
                  size='large'
                  disabled={!selectedType || (selectedType === 2 && !selectedProject)}
                  onClick={state.input === 'startup' ? createDraftSubmit : createIdeaDraftSubmit}
                  loading={isSubmitting}
                >
                  Create and submit application
                </Button>}
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

interface Props {
  tag: string;
  visible: boolean;
  onSubmitApplication: () => void;
  onCancel: () => void;
  myProjects: IMyProjects;
  isPreregistration: boolean;
  additionalInfo?: string;
}

ApplicationModal.defaultProps = {
  additionalInfo: '',
};

export default ApplicationModal;
