import React from 'react';
import TitleCard from './TitleCard';
import ScheduleCard from './ScheduleCard';
import '../Event/Event.css';
import DetailList from './DetailList';
import { formatDate, IEvent, ILocation } from '../Event/Event';

// eslint-disable-next-line @typescript-eslint/no-var-requires
const timezones = require('../../../libs/timezones/timezones.json');

const styles = {
  imageContainer: {
    width: '100%',
    height: 264,
    borderRadius: 10,
    marginTop: 66,
    overflow: 'hidden',
  },
  image: {
    width: '100%',
    height: '100%',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    transition: '0.2s',
  },
  cardContainer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row' as const,
    justifyContent: 'space-between',
    gap: '0 16px',
  },
};

const getDuration = (event: IEvent): string => {
  const format = 'YYYY.MM.DD';
  const { durationFrom, durationTo, timezone } = event;
  return `${formatDate(durationFrom, timezone, format)} - ${formatDate(durationTo, timezone, format)}`;
};

const getTimezone = (timezone: string): string => {
  const tz = Object.entries(timezones).find((entry) => entry[1] === timezone);
  return tz ? tz[0].substring(1, 10) : 'N/A';
};

const getDetailedAddress = (location: ILocation): string => {
  const res = [];
  if (!location) {
    return 'N/A';
  }
  if (location.streetName) {
    if (location.streetNumber) {
      res.push(`${location.streetNumber} ${location.streetName}`);
    }
    res.push(location.streetName);
  }
  if (location.city) {
    res.push(location.city);
  }
  if (location.state) {
    res.push(location.state);
  }
  if (location.country) {
    res.push(location.country);
  }
  if (!res.length) {
    return 'N/A';
  }
  return res.length === 1 ? res[0] : res.join(', ');
};

const Header = ({
  event, onApply, userRoles, applyButtonVisible,
  onWithdraw, withdrawButtonVisible, onMessage, onGenerateSummary, onAddPin, pin,isFree,fee
}: Props) => (
  <>
    <div style={styles.imageContainer}>
      <div
        style={{
          ...styles.image,
          backgroundImage: `url(${event.image || '/images/profile-background.png'})`,
        }}
      />
    </div>
    <div style={styles.cardContainer}>
      <div>
        <TitleCard
          name={event.name}
          tag={event.tag}
          userRoles={userRoles}
          onMessage={onMessage}
          onWithdraw={onWithdraw}
          withdrawButtonVisible={withdrawButtonVisible}
          onGenerateSummary={onGenerateSummary}
          onAddPin={onAddPin}
          pin={pin}
          needRedirect={event.needRedirect}
        />
        <DetailList
          location={event.location
            ? getDetailedAddress(event.location) : event.city}
          duration={getDuration(event)}
          deadline={formatDate(event.deadline, event.timezone, 'YYYY.MM.DD HH:mm')}
          timezone={getTimezone(event.timezone)}
          isOnline={event.isOnline}
          isFree={event.isFree}
          fee={event.fee}
        />
      </div>
      <div>
        <ScheduleCard
          className='schedule-desktop'
          schedules={event.schedules}
          leadButtonText={event.leadButtonText}
          style={{ marginTop: -200, marginRight: -20, width: 288 }}
          timezone={event.timezone}
          onApply={onApply}
          applyButtonVisible={applyButtonVisible}
          withdrawButtonVisible={withdrawButtonVisible}
          isRegistrationOpen={event.isRegistrationOpen}
          needRedirect={event.needRedirect}
          redirectURL={event.redirectURL}
        />
      </div>
    </div>
    <ScheduleCard
      className='schedule-mobile'
      style={{ marginTop: 0 }}
      schedules={event.schedules}
      leadButtonText={event.leadButtonText}
      timezone={event.timezone}
      onApply={onApply}
      isRegistrationOpen={event.isRegistrationOpen}
      needRedirect={event.needRedirect}
      redirectURL={event.redirectURL}
      applyButtonVisible={applyButtonVisible}
      withdrawButtonVisible={withdrawButtonVisible}
    />
  </>
);

interface Props {
  event: IEvent;
  userRoles: string[];
  onApply: () => void;
  applyButtonVisible: boolean;
  onWithdraw: () => void;
  withdrawButtonVisible: boolean;
  onMessage: () => void;
  onGenerateSummary: () => void;
  onAddPin:()=>void;
  pin:boolean;
  isFree:string;
  fee:number;
}

export default Header;
