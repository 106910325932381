import React, { useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import {
  Button,
  Carousel,
  Icon,
  message,
  Modal,
  Pagination,
  Progress,
  Steps,
} from "antd";
import { Helmet } from "react-helmet";
import { get } from "../../libs/utils/request";
import { isAdmin, user } from "../../libs/utils/user";
import User from "../../user/User";
// import { Project } from '../Project/ProjectPage';
import { StartupCard } from "../CardView/ProjectCard";
import { IdeaCard } from "../CardView/ProjectCard";
import "./ProfilePage.css";
import { Loading } from "../common/Loading";
import { IIdea } from "../Competitions/Competition/components/Ideas/Ideas";
import StartupDraft from "../Project/StartupDraft";
import { IStartup } from "../Competitions/Competition/components/Startups/Startups";
import _ from "lodash";
import DraftCard from "../Competitions/DraftCard";
import NewProfileEditor from "./NewProfileEditor";
import CompetitionDraftsCard from "../Competitions/DraftsCard";
import EventsDraftsCard from "../Events/components/DraftsCard";
import Masonry from "react-masonry-css";
import { textOverflow } from "../../libs/utils/textUtils";
import Award from "../Project/Award";
import DraftsCard from "../Blog/DraftsCard";

const { Step } = Steps;

interface Props {
  match: any; // TODO: any
  history: any;
}

interface State {
  user: User | null;
  ideas: IIdea[] | null;
  projects: IStartup[] | null;
  modalVisible: boolean;
  reload: boolean;
  projectNo: number;
  ideaNo: number;
  compNo: number;
  eventNo: number;
  competitions: any[];
  events: any[];
  competitionDrafts: any[];
  eventDrafts: any[];
  competitionCollapse: boolean;
  eventCollapse: boolean;
  articlesCollapse:boolean;
  articleDrafts: any[];
}

class ProfilePage extends React.Component<Props, State> {
  state: State = {
    user: null,
    ideas: [],
    projects: [],
    competitions: [],
    events: [],
    modalVisible: false,
    reload: false,
    projectNo: 0,
    ideaNo: 0,
    compNo: 0,
    eventNo: 0,
    competitionDrafts: [],
    eventDrafts: [],
    competitionCollapse: true,
    eventCollapse: true,
    articlesCollapse: true,
    articleDrafts: [],
  };

  componentDidMount() {
    (async () => {
      const u = await this.getUser();
      await this.getUserIdeas(this.state.user);
      await this.getUserProjects(this.state.user);
      await this.getUserCompetitions(this.state.user);
      await this.getUserEvents(this.state.user);
      await this.getUserCompetitionDrafts();
      await this.getUserArticleDrafts();
      await this.getUserEventDrafts();
      if(!this.state.user){
        message.error('Could not find this profile, maybe it was deleted', 1)
      }
    })();
  }

  componentDidUpdate(prevProps: Props) {
    // update page when coming from an other profile
    (async () => {
      if (prevProps.match.params.id !== this.props.match.params.id) {
        const u = await this.getUser();
        await this.getUserIdeas(this.state.user);
        await this.getUserProjects(this.state.user);
        await this.getUserCompetitions(this.state.user);
        await this.getUserEvents(this.state.user);
        await this.getUserCompetitionDrafts();
        await this.getUserEventDrafts();
        await this.getUserArticleDrafts();
      }
    })();
  }

  getUser = async () => {
    try {
      const paramsId = this.props.match.params.id;
      const sessionUser = sessionStorage.getItem("user");

      const user = await get<User>(paramsId ? `/user/${paramsId}` : "/me");

      this.setState({ user });
      if (
        localStorage.getItem("token") && this.state.user.id === JSON.parse(sessionUser).id
      ) {
        sessionStorage.setItem("user", JSON.stringify(user));
      }
      return user;
    } catch (e) {
      console.log(e);
      // await sessionStorage.removeItem("user");
      // await localStorage.removeItem("token");
    }
  };

  getUserIdeas = async (u: any) => {
    try {
      const paramsId = this.props.match.params.id;

      const ideas = await get<IIdea[]>(`/user/${u.id}/ideas`);

      this.setState({ ideas });
    } catch (err) {
      // console.log(err);
    }
  };

  getUserCompetitionDrafts = async () => {
    const draftsTmp = await get<{ drafts: any[] }>("competitions/draft/all");
    this.setState({
      competitionDrafts: draftsTmp.drafts,
    });
  };
  getUserEventDrafts = async () => {
    const draftsTmp = await get<{ drafts: any[] }>("events/draft/all");
    this.setState({
      eventDrafts: draftsTmp.drafts,
    });
  };

  getUserArticleDrafts = async () => {
    const draftsTmp = await get<{ drafts: any[] }>("blog/draft/all");
    this.setState({
      articleDrafts: draftsTmp.drafts,
    });
  };
  getUserProjects = async (u: any) => {
    try {
      const projects = await get<IStartup[]>(`/user/${u.id}/projects`);

      this.setState({ projects });
    } catch (err) {
      // console.log(err);
    }
  };

  getUserCompetitions = async (u: any) => {
    try {
      const paramsId = this.props.match.params.id;

      const competitions = await get<any[]>(`/user/${u.id}/competitions`);

      this.setState({ competitions });
    } catch (err) {
      // console.log(err);
    }
  };
  setCompetitionCollapse = () => {
    this.setState({
      competitionCollapse: !this.state.competitionCollapse,
    });
  };
  setEventCollapse = () => {
    this.setState({
      eventCollapse: !this.state.eventCollapse,
    });
  };
  setArticlesCollapse = () => {
    this.setState({
      articlesCollapse: !this.state.articlesCollapse,
    });
  };
  getUserEvents = async (u: any) => {
    try {
      const paramsId = this.props.match.params.id;

      const events = await get<IIdea[]>(`/user/${u.id}/events`);

      this.setState({ events });
    } catch (err) {
      // console.log(err);
    }
  };

  showModal = () => {
    this.setState({ modalVisible: true });
  };

  closeModal = () => {
    this.setState({ modalVisible: false });
  };

  render() {
    const { ideas, projects, competitions, events } = this.state;
    const isLoading =
      ideas === null ||
      projects === null ||
      this.state.user === null ||
      competitions === null ||
      events === null;
    if (isLoading) {
      return (
        <div className="Content-container">
          <Loading />
        </div>
      );
    }
    // const showAge = true;
    const {
      id,
      firstName,
      lastName,
      profileImage,
      coverImage,
      city,
      location,
      oneLiner,
      bio,
      interestGroups,
      role,
      age,
      skills,
      interests,
      showAge,
      progress,
      isMentor,
      socialLinks,
      missingFields,
      awards,
    } = this.state.user;
    const userIsMe = user() && user().id === id;

    const getUserAddress = (): string => {
      const getOldLocation = () => city;
      const res = [];
      if (!location) {
        return getOldLocation();
      }
      if (location.city) {
        res.push(location.city);
      }
      if (location.country) {
        res.push(location.country);
      }
      if (!res.length) {
        return getOldLocation();
      }
      return res.length === 1 ? res[0] : res.join(", ");
    };
    const locationRender = () => {
      let variable = getUserAddress();
      if (variable !== null && variable.length > 0) {
        return (
          <div className="detail city">
            <img src="/images/user_info_icons/location.svg" alt="" /> {variable}
          </div>
        );
      } else return "";
    };

    const onStartupChange = (pageNumber: any) => {
      this.setState({ projectNo: pageNumber - 1 });
    };

    const onIdeaChange = (pageNumber: any) => {
      this.setState({ ideaNo: pageNumber - 1 });
    };

    const onCompChange = (pageNumber: any) => {
      this.setState({ compNo: pageNumber - 1 });
    };

    const onEventChange = (pageNumber: any) => {
      this.setState({ eventNo: pageNumber - 1 });
    };

    const fields = [
      "profileImage",
      "coverImage",
      "oneLiner",
      "bio",
      "socialLinks",
    ];
    const steps = this.state.projects;

    return (
      <div className="Profile-container">
        <Helmet>
          <title>Profile | pozi.io</title>
        </Helmet>
        {firstName === "Deleted User" ? (
          ""
        ) : (
          <div className="ProfilePage">
            <div
              className="ProfilePage-cover"
              style={{
                backgroundImage: `url('${
                  coverImage || "/images/POZI_default_background_user.png"
                }'`,
              }}
            />
            <div className="ProfilePage-body">
              <div className="ProfilePage-header">
                <div className="ProfilePage-image">
                  <div className="ProfilePage-image-profile-container">
                    <img
                      className="ProfilePage-image-profile"
                      src={profileImage || "/images/user.svg"}
                      alt=""
                    />
                  </div>
                  {isMentor && (
                    <div className="ProfilePage-image-role">
                      <img src={role.icon} alt="" />
                    </div>
                  )}
                </div>
                <div className="ProfilePage-title">
                  <div className="ProfilePage-title-greeting">
                    <div>
                      Hey! I'm{" "}
                      <span className="greeting-name-profile">
                        {firstName + " " + lastName}
                      </span>
                      .
                      <br />
                      <span>Get to know me!</span>
                      {""}
                      {userIsMe && (
                        <Icon type="edit" onClick={this.showModal} />
                      )}
                    </div>
                    <span className="social-links-profile">
                      {socialLinks &&
                        socialLinks.map((link) => (
                          <a target="_blank" href={link.url}>
                            <img
                              src={
                                "/images/social_icons/" +
                                link.type.name +
                                ".svg"
                              }
                            />
                          </a>
                        ))}
                    </span>
                  </div>
                  <div>
                    <div className="ProfilePage-title-details">
                      <div className="detail name">
                        <img src={role.icon} alt="" /> {role.name}
                      </div>
                      {age > 0 ? (
                        <div className="detail age">
                          <img
                            src="/images/user_info_icons/birth_date.svg"
                            alt=""
                          />
                          <div>{age} years old</div>
                        </div>
                      ) : (
                        ""
                      )}

                      {locationRender()}
                      {user() && !!!userIsMe ? (
                        <span>
                          <Button
                            className="message-btn-profile"
                            onClick={() => {
                              progress < 10
                                ? message.warning(
                                    `${progress}% of your profile is completed. To send a message you need to get at least 10%`
                                  )
                                : this.props.history.push(
                                    `/messages/user/${id}`
                                  );
                            }}
                            type="primary"
                          >
                            Message
                          </Button>
                        </span>
                      ) : null}
                    </div>
                    {oneLiner && (
                      <div className="profile-oneliner">
                        <img src="/images/user_info_icons/actual_oneliner.svg" />
                        <p>{oneLiner}</p>
                      </div>
                    )}
                  </div>
                </div>
                <span className="social-links-profile-mobile">
                  {socialLinks &&
                    socialLinks.map((link) => (
                      <a target="_blank" href={link.url}>
                        <img
                          src={
                            "/images/social_icons/" + link.type.name + ".svg"
                          }
                        />
                      </a>
                    ))}
                </span>
                {user() && !!!userIsMe ? (
                  <span>
                    <Button
                      className="message-btn-profile-mobile"
                      onClick={() => {
                        progress < 10
                          ? message.warning(
                              `${progress}% of your profile is completed. To send a message you need to get at least 10%`
                            )
                          : this.props.history.push(`/messages/user/${id}`);
                      }}
                      type="primary"
                    >
                      Message
                    </Button>
                  </span>
                ) : null}
              </div>

              <div className="ProfilePage-cards">
                {userIsMe && progress < 100 && missingFields && (
                  <div className="ProfilePage-card progress">
                    <h3 className="card-title progress-title">
                      Set up your Pozi{" "}
                      <span className="progress-bar-profile">{progress}%</span>{" "}
                      <span className="progress-desc-profile">
                        | Completed of your full profile
                      </span>
                    </h3>
                    <Progress
                    className="profile-progress"
                      percent={progress}
                      showInfo={false}
                      strokeColor={"var(--primary-blue)"}
                      // style={{ height: "20px !important" }}
                    />
                    <p className="about-bio">
                      Accomplish steps to unlock access for our upcoming
                      milestone system and other new features. Users with a
                      completed profile, reach 41% more engagement.
                    </p>
                    <p className="checklist">
                      <h2>Checklist:</h2>
                      <div className="checklist-elements">
                        <div className="ProfilePage-card-progress">
                          <h3 className="progress-details-title">
                            Personal information
                          </h3>
                          {(missingFields.length > 0 &&
                            missingFields.map(
                              (value, index) =>
                                value === fields.map((field) => field)
                            )) ||
                          missingFields.length === 0 ? (
                            <p className="profile-details-done">
                              <img
                                src="/images/user_info_icons/done_black.svg"
                                alt=""
                              />
                            </p>
                          ) : (
                            ""
                          )}
                          <p className="progress-details-desc">
                            Introduce yourself, so that the community can get to
                            know you better.
                          </p>
                        </div>
                        <div className="ProfilePage-card-progress">
                          <h3 className="progress-details-title">Skills</h3>
                          {!missingFields.includes("skills") ? (
                            <p className="profile-details-done">
                              <img
                                src="/images/user_info_icons/done_black.svg"
                                alt=""
                              />
                            </p>
                          ) : (
                            ""
                          )}
                          <p className="progress-details-desc">
                            Show your values, choose 5 skills which represent
                            you the most.
                          </p>
                        </div>

                        <div className="ProfilePage-card-progress">
                          <h3 className="progress-details-title">Interests</h3>
                          {!missingFields.includes("interests") ? (
                            <p className="profile-details-done">
                              <img
                                src="/images/user_info_icons/done_black.svg"
                                alt=""
                              />
                            </p>
                          ) : (
                            ""
                          )}
                          <p className="progress-details-desc">
                            Pick your interests, and connect with people, who
                            has similar interests as you.
                          </p>
                        </div>
                      </div>
                    </p>
                    <p
                      className="progress-edit-profile"
                      onClick={this.showModal}
                    >
                      <Icon type="edit" /> Complete now
                    </p>
                  </div>
                )}
                <div className="ProfilePage-card about">
                  <h3 className="card-title about-oneLiner">About</h3>
                  <p className="about-bio">
                    {bio || "Write something about yourself"}
                  </p>
                </div>

                <div className="ProfilePage-card skills">
                  <h3 className="card-title">My 5 best skills</h3>
                  <div className="card-skills">
                    {skills.map((skill) => (
                      <div className="skill">{skill.name}</div>
                    ))}
                  </div>
                </div>
                <div className="ProfilePage-card interests">
                  <h3 className="card-title">My interests</h3>
                  <div className="card-interests">
                    {interestGroups.map((group: any) => (
                      <>
                        {group.interests.map((interest: any, idx: number) => (
                          <div className="ProfilePage-interest-icon-text">
                            <img src={group.icon} alt="" />
                            <div
                              key={interest.id}
                              className="ProfilePage-interest-item"
                            >
                              {` ${interest.name} `}
                            </div>
                          </div>
                        ))}
                      </>
                    ))}
                  </div>
                </div>
                <Masonry
                  breakpointCols={{ default: 2, 850: 1 }}
                  className="Profile-card-holder"
                >
                  <div className="ProfilePage-card projects">
                    <span className="profile-projects-title-container">
                      <span>
                        <h3 className="card-title">My startups</h3>
                        <p>{projects.length} startups</p>
                      </span>
                      {(userIsMe && user()?.active) && (
                        <Button type="primary">
                          <Link to="/new-startup/">Create new</Link>
                        </Button>
                      )}
                    </span>
                    {projects.length > 0 ? (
                      _.times(1, (i) => (
                        <div>
                          <StartupCard
                            key={projects[this.state.projectNo].id}
                            startup={projects[this.state.projectNo]}
                            onChange={() => window.location.reload()}
                          />
                          <span className="nav-buttons-profile">
                            <p
                              onClick={() => {
                                this.state.projectNo > 0 &&
                                  this.setState({
                                    projectNo: this.state.projectNo - 1,
                                  });
                              }}
                            >
                              {this.state.projectNo > 0 && (
                                <>
                                  <img src="/images/user_info_icons/back.svg" />
                                  Previous
                                </>
                              )}
                            </p>
                            <p className="pagination-desktop-profile">
                              <Pagination
                                total={projects.length}
                                defaultPageSize={1}
                                current={this.state.projectNo + 1}
                                size="small"
                                onChange={onStartupChange}
                              />
                            </p>
                            <p
                              onClick={() => {
                                this.state.projectNo < projects.length - 1 &&
                                  this.setState({
                                    projectNo: this.state.projectNo + 1,
                                  });
                              }}
                            >
                              {this.state.projectNo < projects.length - 1 && (
                                <>
                                  Next
                                  <img src="/images/user_info_icons/next.svg" />
                                </>
                              )}
                            </p>
                          </span>
                          <p className="pagination-mobile-profile">
                            <Pagination
                              total={projects.length}
                              defaultPageSize={1}
                              current={this.state.projectNo + 1}
                              size="small"
                              onChange={onStartupChange}
                            />
                          </p>
                        </div>
                      ))
                    ) : (
                      <div className="no-idea">
                        <h3>{user()?.active ? "You don't have any startup yet" : "Your trial ended"}</h3>
                        <img src="/images/rocket.svg" alt="" />
                      </div>
                    )}
                  </div>
                  <div className="ProfilePage-card ideas">
                    <span className="profile-projects-title-container">
                      <span>
                        <h3 className="card-title">My ideas</h3>
                        <p>{ideas.length} ideas</p>
                      </span>
                      {(userIsMe && user()?.active) && (
                        <Button type="primary">
                          <Link to={`/ideas/?create_new=true`}>Create new</Link>
                        </Button>
                      )}
                    </span>

                    {ideas.length > 0 ? (
                      _.times(1, (i) => (
                        <div>
                          <IdeaCard
                            key={ideas[this.state.ideaNo].id}
                            idea={ideas[this.state.ideaNo]}
                            onChange={() => window.location.reload()}
                          />
                          <span className="nav-buttons-profile">
                            <p
                              onClick={() => {
                                this.state.ideaNo > 0 &&
                                  this.setState({
                                    ideaNo: this.state.ideaNo - 1,
                                  });
                              }}
                            >
                              {this.state.ideaNo > 0 && (
                                <>
                                  <img src="/images/user_info_icons/back.svg" />
                                  Previous
                                </>
                              )}
                            </p>
                            <p className="pagination-desktop-profile">
                              <Pagination
                                total={ideas.length}
                                defaultPageSize={1}
                                current={this.state.ideaNo + 1}
                                size="small"
                                onChange={onIdeaChange}
                              />
                            </p>
                            <p
                              onClick={() => {
                                this.state.ideaNo < ideas.length - 1 &&
                                  this.setState({
                                    ideaNo: this.state.ideaNo + 1,
                                  });
                              }}
                            >
                              {this.state.ideaNo < ideas.length - 1 && (
                                <>
                                  Next
                                  <img src="/images/user_info_icons/next.svg" />
                                </>
                              )}
                            </p>
                          </span>
                          <p className="pagination-mobile-profile">
                            <Pagination
                              total={ideas.length}
                              defaultPageSize={1}
                              current={this.state.ideaNo + 1}
                              size="small"
                              onChange={onIdeaChange}
                            />
                          </p>
                        </div>
                      ))
                    ) : (
                      <div className="no-idea">
                        <h3>{user()?.active ? "You don't have any idea yet" : "Your trial ended"}</h3>
                        <img src="/images/lightbulb.svg" alt="" />
                      </div>
                    )}
                  </div>

                  {competitions.length > 0 && (
                    <div className="ProfilePage-card projects">
                      <span className="profile-projects-title-container">
                        <span>
                          <h3 className="card-title">Attended competitions</h3>
                          <p>{competitions.length} competitions</p>
                        </span>
                      </span>
                      {_.times(1, (i) => (
                        <div className="profile-drafts">
                          <DraftCard
                            className="profile-drafts-card"
                            key={competitions[this.state.compNo].id}
                            draft={competitions[this.state.compNo]}
                            onClick={() => {
                              window.open(
                                `${window.location.origin}/competitions/${
                                  competitions[this.state.compNo].tag
                                }`
                              );
                            }}
                          />
                          <span className="nav-buttons-profile">
                            <p
                              onClick={() => {
                                this.state.compNo > 0 &&
                                  this.setState({
                                    compNo: this.state.compNo - 1,
                                  });
                              }}
                            >
                              {this.state.compNo > 0 && (
                                <>
                                  <img src="/images/user_info_icons/back.svg" />
                                  Previous
                                </>
                              )}
                            </p>
                            <p className="pagination-desktop-profile">
                              <Pagination
                                total={competitions.length}
                                defaultPageSize={1}
                                current={this.state.compNo + 1}
                                size="small"
                                onChange={onCompChange}
                              />
                            </p>
                            <p
                              onClick={() => {
                                this.state.compNo < competitions.length - 1 &&
                                  this.setState({
                                    compNo: this.state.compNo + 1,
                                  });
                              }}
                            >
                              {this.state.compNo < competitions.length - 1 && (
                                <>
                                  Next
                                  <img src="/images/user_info_icons/next.svg" />
                                </>
                              )}
                            </p>
                          </span>
                          <p className="pagination-mobile-profile">
                            <Pagination
                              total={competitions.length}
                              defaultPageSize={1}
                              current={this.state.compNo + 1}
                              size="small"
                              onChange={onCompChange}
                            />
                          </p>
                        </div>
                      ))}
                    </div>
                  )}

                  {events.length > 0 && (
                    <div className="ProfilePage-card ideas">
                      <span className="profile-projects-title-container">
                        <span>
                          <h3 className="card-title">Attended events</h3>
                          <p>{events.length} events</p>
                        </span>
                      </span>

                      {_.times(1, (i) => (
                        <div className="profile-drafts">
                          <DraftCard
                            className="profile-drafts-card"
                            key={events[this.state.eventNo].id}
                            draft={events[this.state.eventNo]}
                            onClick={() => {
                              window.open(
                                `${window.location.origin}/events/${
                                  events[this.state.eventNo].tag
                                }`
                              );
                            }}
                          />
                          <span className="nav-buttons-profile">
                            <p
                              onClick={() => {
                                this.state.eventNo > 0 &&
                                  this.setState({
                                    eventNo: this.state.eventNo - 1,
                                  });
                              }}
                            >
                              {this.state.eventNo > 0 && (
                                <>
                                  <img src="/images/user_info_icons/back.svg" />
                                  Previous
                                </>
                              )}
                            </p>
                            <p className="pagination-desktop-profile">
                              <Pagination
                                total={events.length}
                                defaultPageSize={1}
                                current={this.state.eventNo + 1}
                                size="small"
                                onChange={onEventChange}
                              />
                            </p>
                            <p
                              onClick={() => {
                                this.state.eventNo < events.length - 1 &&
                                  this.setState({
                                    eventNo: this.state.eventNo + 1,
                                  });
                              }}
                            >
                              {this.state.eventNo < events.length - 1 && (
                                <>
                                  Next
                                  <img src="/images/user_info_icons/next.svg" />
                                </>
                              )}
                            </p>
                          </span>
                          <p className="pagination-mobile-profile">
                            <Pagination
                              total={events.length}
                              defaultPageSize={1}
                              current={this.state.eventNo + 1}
                              size="small"
                              onChange={onCompChange}
                            />
                          </p>
                        </div>
                      ))}
                    </div>
                  )}
                </Masonry>
                {awards.length > 0 && (
                  <div className="ProfilePage-card awards">
                    <h3 className="card-title">My achievements</h3>
                    <div className="card-awards">
                      {awards.map((award) => (
                        <Award award={award}/>
                      ))}
                    </div>
                  </div>
                )}
              </div>
              {!!user() && userIsMe && (
                <StartupDraft title="My draft startups" />
              )}
              {!!user() &&
                userIsMe &&
                this.state.competitionDrafts.length > 0 && (
                  <CompetitionDraftsCard
                    title="My draft competitions"
                    drafts={this.state.competitionDrafts}
                    type={"competition"}
                    collapsed={this.state.competitionCollapse}
                    setCollapsed={this.setCompetitionCollapse}
                  />
                )}
              {!!user() && userIsMe && this.state.eventDrafts.length > 0 && (
                <EventsDraftsCard
                  title="My draft events"
                  drafts={this.state.eventDrafts}
                  collapsed={this.state.eventCollapse}
                  setCollapsed={this.setEventCollapse}
                />
              )}
               {isAdmin() &&
                this.state.articleDrafts.length > 0 && (
                  <DraftsCard
                    title="My draft articles"
                    drafts={this.state.articleDrafts}
                    type={"article"}
                    collapsed={this.state.articlesCollapse}
                    setCollapsed={this.setArticlesCollapse}
                  />
                )}
            </div>

            <Modal
              className="ProfileEditor-modal"
              title="Edit your profile"
              visible={this.state.modalVisible}
              width={900}
              closable={false}
              footer={null}
            >
              <NewProfileEditor
                onClose={this.closeModal}
                userToEdit={this.state.user}
                callback={this.getUser}
              />
            </Modal>
          </div>
        )}
      </div>
    );
  }
}

export default ProfilePage;
