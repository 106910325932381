import React from 'react';
import {
  Button, Input, Pagination, Select,
} from 'antd';
import Masonry from 'react-masonry-css';
import { Helmet } from 'react-helmet';
import { SearchOutlined } from '@ant-design/icons';
import { get } from '../../libs/utils/request';
import '../Idea/Ideas.css';
import User from '../../user/User';
import { Interest } from '../Registration/Registration';
import { Loading } from '../common/Loading';
import { user } from '../../libs/utils/user';
import { Option, renderOrderByField, renderCountryFilterField } from "../../libs/utils/render";
import StartupDraft from './StartupDraft';
import { StartupCard } from '../CardView/ProjectCard';
import { IStartup } from '../Competitions/Competition/components/Startups/Startups';

// eslint-disable-next-line @typescript-eslint/no-var-requires
const countries: CountryType[] = require('../../libs/countries/countries.json');

interface CountryType {
  name: string;
  code: string;
}

const suffix = (
  <div style={{
    background: 'var(--secondary-blue)',
    borderRadius: '100%',
    height: '50px',
    width: '50px',
    textAlign: 'center',
    paddingTop: '15px',
    marginLeft: '2px',
    position: 'absolute'
  }}>
    <SearchOutlined
      style={{
        fontSize: 18,
        color: '#fff',
      }}
    />
  </div>
);

export type Idea = {
  id: string
  createdAt: string
  name: string
  details: string
  image: string
  likes: number
  userLiked: boolean
  shareCount: number
  user: User
  comments: number
  interests: Interest[]
  commentable: boolean
  commentVisible: boolean
}

interface Props {
  history: any // TODO any
}

interface State {
  projectList: IStartup[]
  totalProjects: IStartup[]
  filteredList: IStartup[]
  loading: boolean
  error: boolean
  noResult: boolean
  currentPage: number
  displayPagination: boolean
  order: string
  countriesArray: string[]
  countryFilter: string
}

class Projects extends React.Component<Props, State> {
  state: State = {
    projectList: [],
    totalProjects: [],
    filteredList: [],
    loading: true,
    error: false,
    noResult: false,
    currentPage: 1,
    displayPagination: true,
    order: 'average rating desc',
    countriesArray: this.getCountryNamesArray(),
    countryFilter: 'All',
  }

  async componentDidMount() {
    const param = localStorage.getItem('projectPage');
    const projectOrder = localStorage.getItem('projectOrder');

    try {
      this.setState({ currentPage: param ? parseInt(param) : 1 });
      this.setState({ order: projectOrder || 'average rating desc' });
      this.getProjects();
    } catch (err) {
      console.log(err);
    }
  }

  getProjects = async () => {
    const param = localStorage.getItem('projectPage');
    try {
      const orderFromLocalStorage = localStorage.getItem('projectOrder');
      const countryFilterFromLocalStorage = localStorage.getItem('projectCountryFilter') || this.state.countryFilter;
//      console.log('State countryFilter: ', this.state.countryFilter)

      const projectList = await get<IStartup[]>(`/projects/list?page=${param != null ? param : 1}&count=10&order=${orderFromLocalStorage}&countryFilter=${countryFilterFromLocalStorage}`)
      const totalProjects = await get<IStartup[]>(`/projects/list?page=1&count=1000&order=${orderFromLocalStorage}&countryFilter=${countryFilterFromLocalStorage}`)
      this.setState({
        totalProjects,
        projectList,
        loading: false,
        filteredList: projectList, // only because of frontend side pagination
        // handling. when we'll change to server 
        //side handling it needs to be an empty array!!
      })
    } catch (error) {
      if (error.response.status === 403 && error.response.data.extendedUser) {
        sessionStorage.setItem("user", JSON.stringify(error.response.data.extendedUser))
        if (!error.response.data.extendedUser.active && !error.response.data.extendedUser.trial) {
          window.location.replace('/trial-ended')
        }
      }
      // console.log(err)
    }
  }

  onAddNew = () => {
    this.props.history.push('/new-startup');
  }

  onSearch = (value: string) => {
    if (value) {
      const filteredIdeas = this.state.totalProjects.filter(project => JSON.stringify(project).toLocaleLowerCase().includes(value.toLocaleLowerCase()))
      if (filteredIdeas.length > 0) {
        this.setState({ filteredList: filteredIdeas, noResult: false, displayPagination: false })
      } else {
        this.setState({ noResult: true, displayPagination: false })
      }
    } else {
      this.setState({
        filteredList: this.state.projectList, // only because of frontend side pagination handling. when we'll change to server side handling it needs to be an empty array!!
        displayPagination: true,
        noResult: false,
      });

    }
  }

  getCountryNamesArray() {
    const countryArray = countries.map(country => country.name);
    return countryArray;
  }

  async onOrderChange(order: string | undefined = undefined) {
    this.setState({ currentPage: 1 });
    localStorage.setItem('projectPage', '1');
    localStorage.setItem('projectOrder', order.toString())
    try {
      this.setState({
        order: order === undefined ? this.state.order : order,
      })
      await this.getProjects();
      //await this.getUsers(this.state.page, this.state.search, this.state.order)
    } catch (e) {
      console.error(e)
    }
  }

  async onCountryFilterChange(countryFilter: string | undefined = undefined) {
    try {
      this.setState({ currentPage: 1 });
      localStorage.setItem('projectPage', '1');
      localStorage.setItem('projectCountryFilter', countryFilter);
      await this.setState({
        countryFilter: countryFilter === undefined ? this.state.countryFilter : countryFilter,
      })
      await this.getProjects();
      //await this.getUsers(this.state.page, this.state.search, this.state.order)
    } catch (e) {
      console.error(e)
    }
  }

  onPageChange = (page: number) => {
    localStorage.setItem('projectPage', page.toString())
    this.setState({
      currentPage: page,
      filteredList: this.state.projectList.slice((page * 10) - 10, (page * 10))
    })
    this.getProjects();
  }

  render() {
    const orderFromLocalStorage = localStorage.getItem('projectOrder');
    const countryFilterFromLocalStorage = localStorage.getItem('projectCountryFilter');
//    console.log('render - order:', orderFromLocalStorage);
    const userFromStorage = sessionStorage.getItem('user')
    const profileProgress = userFromStorage ? JSON.parse(userFromStorage).progress : 0
    return (
      <div className="Content-container">
        <Helmet>
          <title>Startups | pozi.io</title>
        </Helmet>
        <div className="Ideas">

          <div className="Ideas-top-container">
            <h3 className="Ideas-top-title">Find awesome startups</h3>
            <div className="Ideas-top-options">
              {
                !!user() && user().active && (
                  <div className="option-add">
                    <Button type="primary" size="large"
                      onClick={() =>
                        this.onAddNew()}>Create a new</Button>
                  </div>
                )
              }

              <Input
                style={{ maxWidth: "250px" }}
                className={'Users-search'}
                placeholder={'Type a name you like...'}
                // @ts-ignore
                onChange={e => this.onSearch(e.target?.value)}
                suffix={suffix}
                addonAfter={null}
              />
            </div>
            {user() === undefined ? "" : <StartupDraft />}

          </div>
          {
            this.state.loading ? <Loading /> : null
          }
          {
            this.state.noResult ?
              <div className="no-result">
                We didn't found anything. Maybe it's a new startup?
              </div> :
              <>
                <div style={{
                  textAlign: 'center',
                  paddingBottom: '60px',
                  display: 'flex',
                  justifyContent: 'space-between'
                }}>
                  {renderCountryFilterField(this.state.countriesArray, countryFilterFromLocalStorage ? countryFilterFromLocalStorage : this.state.countryFilter, this.onCountryFilterChange)}

                  <div style={{ width: '250px', textAlign: 'left', }}>
                    <h2 className="item-title-register">
                      Order by:
                    </h2>
                    <Select defaultValue={orderFromLocalStorage ? orderFromLocalStorage : this.state.order} style={{ width: 250 }} onChange={(order: any) => {
                      this.onOrderChange(order)
                    }} >
                      <Option value={'updated at asc'}>update date (older first)</Option>
                      <Option value={'updated at desc'}>update date (newer first)</Option>
                      <Option value={'average rating asc'}>average rating (lower first)</Option>
                      <Option value={'average rating desc'}>average rating (higher first)</Option>
                    </Select>
                  </div>
                </div>
                {
                  this.state.displayPagination &&
                  <div className="Ideas-pagination">
                    <Pagination current={this.state.currentPage} onChange={this.onPageChange}
                      total={this.state.totalProjects.length} />
                  </div>
                }
                {
                    this.state.filteredList.length==0 ?
                    <Loading /> :

                    <Masonry
                      breakpointCols={{ default: 2, 850: 1 }}
                      className="Ideas-list"
                      columnClassName="Ideas-list-column">
                      {
                        this.state.filteredList.map((project: IStartup) => (
                          <StartupCard
                            startup={project}
                            key={project.id}
                            onChange={this.getProjects} />
                        ))
                      }
                    </Masonry>
                }
                {
                  this.state.displayPagination &&
                  <div className="Ideas-pagination-bottom">
                    <Pagination current={this.state.currentPage} onChange={this.onPageChange}
                      total={this.state.totalProjects.length} />
                  </div>
                }
              </>
          }
        </div>
      </div>
    )
  }
}

export default Projects
