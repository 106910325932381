import React from "react";
import {
  Dropdown,
  Icon,
  Popover,
  Rate,
  Button,
  Menu,
  Modal,
  Popconfirm,
} from "antd";
import { ShareMenu } from "../common/ShareMenu";
import { get, post } from "../../libs/utils/request";
import { message } from "antd";
import { Link } from "react-router-dom";
import { ago } from "../Messages/MessageList";
import moment from "moment";
import "./NewsCard.css";
import { UserOutlined, EllipsisOutlined } from "@ant-design/icons";
import { NewsCardEdit } from "./NewsCardEdit";
import { user } from "../../libs/utils/user";
import { ReadMore } from "../read-more/ReadMore";
import { Comments } from "../comments/Comments";
import { Helmet } from "react-helmet";

interface Props {
  data: any;
  carousel?: boolean;
  onUpdate?: (data: any) => void;
  onDeleted?: () => void;
}

interface State {
  commentVisible: boolean;
  ratingGroup: any;
  commentsCount: number;
  onEdit: boolean;
  rate: number
}

export class NewsCard extends React.Component<Props, State> {
  state: State = {
    commentVisible: false,
    ratingGroup: this.props.data.ratingGroup || {},
    commentsCount: this.props.data.commentGroup?.commentsCount,
    onEdit: false,
    rate: this.props.data.ratingGroup.userRating
  };

  increaseShare = async (id: string) => {
    try {
      await post(`/news/increaseShare/${id}`);
    } catch (e) {
      // not handled error because it will be not displayed
    }
  };

  async startDelete() {
    try {
      await post(`/news/delete`, { id: this.props.data.id });
      this.props.onDeleted();
      message.success("Post deleted");
    } catch (e) {
      message.error(e.response?.data?.message || "Network error");
    }
  }

  startEdit() {
    this.setState({ onEdit: true });
  }

  startReport() {
    message.error("This function is under development");
  }

  render() {
    const { data, carousel } = this.props;
    const { commentVisible, ratingGroup, commentsCount } = this.state;
    const { ratingCount, averageRating, userRating = this.state.ratingGroup.userRating || 0 } = ratingGroup;
    const { profileImage, firstName, lastName, id: profileId } = data.user;
    const {
      createdAt,
      hashtags,
      description,
      id,
      image,
      message: dataMessage,
      shareCount,
      title,
      text,
      fromDate,
      buttonLink,
      jobType,
      lead,
      link,
      type,
      location,
      linkedImage,
      linkedDescription,
      linkedTitle,
      salary,
      toDate,
    } = data;
    console.log('linkedImage: ', linkedImage);
    const roleName = data.user.role?.name;


    const hideToDate =
      fromDate && toDate
        ? moment(fromDate).format("YYYY.MM.DD") ===
        moment(toDate).format("YYYY.MM.DD")
        : false;
    const shareUrl =
      window.location.protocol + "//" + window.location.host + "/news/" + id;

    const menu = (
      <Menu
        onClick={(v) => {
          if (v.key === "edit") {
            return this.startEdit();
          }
          if (v.key === "report") {
            return this.startReport();
          }
        }}
      >
        {user() && profileId === user().id ? (
          <Menu.Item
            key="delete"
            style={{ padding: 0 }}
            // @ts-ignore
            icon={<UserOutlined />}
          >
            <Popconfirm
              title="Are you sure to delete this post?"
              onConfirm={() => {
                this.startDelete();
              }}
              okText="Yes"
              cancelText="No"
            >
              <div
                style={{
                  padding: "5px 12px",
                }}
              >
                Delete
              </div>
            </Popconfirm>
          </Menu.Item>
        ) : null}
        {user() && profileId === user().id ? (
          <Menu.Item
            key="edit"
            // @ts-ignore
            icon={<UserOutlined />}
          >
            Edit
          </Menu.Item>
        ) : null}
        <Menu.Item
          key="report"
          // @ts-ignore
          icon={<UserOutlined />}
        >
          Report
        </Menu.Item>
      </Menu>
    );

    return (
      <div className={"NewsCard"}>
        {carousel &&

          <Link className="NewsCard-hover-overlay" to={`/news/${id}`} target="_blank"><span>Read more</span></Link>

        }

        <div className={"NewsCard-head"}>
          <div className={"NewsCard-head-left"}>
            <div className={"NewsCard-profileImage"}>
              <img
                src={profileImage ? profileImage + "?w=48" : "/images/user.svg"}
                alt=""
              />
            </div>
            <div className={"NewsCard-head-data"}>
              <Link to={`/profile`} className="NewsCard-name">
                {firstName} {lastName}
              </Link>
              <div className={"NewsCard-roleName"}>{roleName}</div>
              <div className={"NewsCard-createdAt"}>{ago(createdAt)}</div>
            </div>
          </div>
          <div className={"NewsCard-head-right"}>
            <Dropdown overlay={menu} trigger={["click"]}>
              <EllipsisOutlined
                style={{
                  fontSize: "23px",
                }}
              />
            </Dropdown>
          </div>
        </div>

        <div className={"NewsCard-body"}>
          {title ? <h2>{title}</h2> : null}
          {dataMessage ? <ReadMore text={dataMessage} /> : null}
          {description ? <ReadMore text={description} /> : null}
          {text ? <ReadMore text={text} /> : null}
          {link && !linkedImage
            ? [
              <br />,
              <a href={link} target={"_blank"}>
                {link}
              </a>,
            ]
            : null}

          {fromDate || toDate || location || salary
            ? [
              <br />,
              <div className={"NewsCard-tags"}>
                {fromDate ? (
                  <div className={"NewsCard-tag"}>
                    <div style={{ display: "flex" }}>
                      <div
                        className={"NewsCard-tagicon"}
                        style={{
                          backgroundImage: "url(/images/groups/event.svg)",
                        }}
                      />
                      <div>{moment(fromDate).format("YYYY.MM.DD")}</div>
                      {fromDate && toDate && !hideToDate ? (
                        <div style={{ margin: "0 8px" }}>-</div>
                      ) : null}
                      {toDate && !hideToDate ? (
                        <div>{moment(toDate).format("YYYY.MM.DD")}</div>
                      ) : null}
                    </div>
                  </div>
                ) : null}
                {location ? (
                  <div className={"NewsCard-tag"}>
                    <div style={{ display: "flex" }}>
                      <div
                        className={"NewsCard-tagicon"}
                        style={{
                          backgroundImage: "url(/images/groups/place.svg)",
                        }}
                      />
                      {location}
                    </div>
                  </div>
                ) : null}
                {salary ? (
                  <div className={"NewsCard-tag"}>
                    <div style={{ display: "flex" }}>
                      <div
                        className={"NewsCard-tagicon"}
                        style={{
                          backgroundImage: "url(/images/groups/salary.svg)",
                        }}
                      />
                      {salary}
                    </div>
                  </div>
                ) : null}
              </div>,
            ]
            : null}
          {linkedImage && (linkedTitle || linkedDescription) ? (
            <a href={link} target={"_blank"}>
              <div className={"NewsCard-link-tab"}>

                <div
                  className={"img"}
                  style={{ backgroundImage: "url(" + linkedImage.url + ")" }}
                />
                <div className={"NewsCard-link-tab-bottom"}>
                  {linkedTitle ? (
                    <div className={"NewsCard-link-title"}>{linkedTitle}</div>
                  ) : null}
                  {linkedDescription ? (
                    <div className={"NewsCard-link-description"}>
                      <ReadMore text={linkedDescription} />
                    </div>
                  ) : null}
                </div>
              </div>
            </a>
          ) : null}
          {linkedImage && !linkedTitle && !linkedDescription ? (
            <a href={link} target={"_blank"}>
              <div
                className={"img"}
                style={{ backgroundImage: "url(" + linkedImage.url + ")" }}
              />
            </a>
          ) : null}
          {image ? (
            <div
              className={"img"}
              style={{ backgroundImage: "url(" + image.url + ")" }}
            />
          ) : null}
          {buttonLink ? (
            <Button
              style={{
                width: "100%",
                height: "28px",
              }}
              type="primary"
              size="large"
              onClick={() => {
                window.open(buttonLink, "_blank");
              }}
              target={"_blank"}
            >
              {title}
            </Button>
          ) : null}
          <div className={"NewsCard-hashtags"}>
            {carousel ?
              hashtags.slice(0, 5).map((tag: string) => (
                <div className={"NewsCard-hashtag"}>#{tag}</div>
              )) :
              hashtags.map((tag: string) => (
                <div className={"NewsCard-hashtag"}>#{tag}</div>
              ))
            }
          </div>
        </div>

        {!carousel && (
          <div>
            <div className="News-social">
              <div className="News-social-rating">
                {ratingCount === 0
                  ? "0 rating"
                  : [
                    Math.round(averageRating * 10) / 10,
                    <Icon type="star" theme="filled" />,
                    ` from ${ratingCount} rating`,
                  ]}
              </div>
              <div className="News-social-comments">
                {commentsCount} comment
              </div>
              <div className="News-social-shares">{shareCount} shares</div>
            </div>

            <div className="News-actions">
              <Popover
                content={
                  <Rate
                    className={"News-action-rating"}
                    allowClear
                    onChange={async (rate: number) => {
                      try {
                        const { rating, ratingGroup } = await post(
                          `/rating/post`,
                          {
                            rate: rate,
                            id: data.ratingGroup.id,
                          }
                        );
                        this.setState({
                          ratingGroup,
                          rate
                        });
                        await get('/news/get/' + this.props.data.id)
                      } catch (e) {
                        message.error("failed to rate");
                      }
                    }}
                    value={this.state.rate}
                  />
                }
              >
                <div className="News-action rate">
                  <Icon type="star" theme="filled" />
                </div>
              </Popover>
              <div
                className={`News-action comment${
                  /*!project.commentable && " disabled"*/ ""
                  }`}
                onClick={() => {
                  this.setState((prevState: State) => ({
                    commentVisible: !prevState.commentVisible,
                  }));
                }}
              >
                <Icon type="message" theme="filled" />
              </div>
              <Dropdown
                overlay={
                  <ShareMenu
                    shareURL={shareUrl}
                    onShare={(type) => {
                      this.increaseShare(id);
                    }}
                  />
                }
                trigger={["click"]}
                placement="topCenter"
              >
                <div className="News-action share">
                  <Icon type="share-alt" />
                </div>
              </Dropdown>
            </div>
            {commentVisible && (
              <Comments
                style={{
                  marginTop: "1.1em",
                }}
                v2={true}
                commentable={true}
                postURL={`/comment/post/${data.commentGroup.id}`}
                commentsCount={commentsCount}
                source={`/comment/get/${data.commentGroup.id}`}
                onNewComment={() => {
                  this.setState({
                    commentsCount: commentsCount + 1,
                  });
                }}
              />
            )}
          </div>
        )}

        <Modal
          style={{
            maxWidth: "1000px",
          }}
          bodyStyle={{ padding: "0" }}
          width={"90vw"}
          visible={this.state.onEdit}
          footer={null}
          onCancel={() => this.setState({ onEdit: false })}
          closable={true}
        >
          <NewsCardEdit
            data={this.props.data}
            onUpdate={(data) => {
              this.props.onUpdate(data);
              this.setState({ onEdit: false });
            }}
          />
        </Modal>
      </div>
    );
  }
}
