import React from "react";
import {MessageContent} from "./MessageContent";
import {Message} from "./MessageInterface";

interface Props {
    messages: Message[]
}

export class MessageScrollbox extends React.Component<Props> {

    key: any = null;
    messagesEndRef: any = undefined;
   
    constructor(props : any) {
        super(props);
        this.messagesEndRef = React.createRef();
    }

    scrollToBottom() {
        if (this.messagesEndRef !== null && this.key !== this.props.messages.length) {
            this.key = this.props.messages.length;
            this.messagesEndRef.current?.scrollIntoView({ behavior: "smooth", block: 'nearest', inline: 'start' });
        }
    }
    
    render() {
        const { messages } = this.props;
        return <div className={'Messages Messages-uniqeScrollbar'} key={messages.length}>
            {
                messages.length === 0 ? <div style={{textAlign: 'center',marginTop: '10%',color:'#444'}}>No messages yet</div> : null
            }
            {messages.map((v, i) => <MessageContent key={i} messages={messages} i={i} />)}
            <div className={'clear'} ref={this.messagesEndRef} />
        </div>;
    }

    async componentDidUpdate() {
        this.scrollToBottom();
    }

    async componentDidMount() {
        this.scrollToBottom();
    }

}