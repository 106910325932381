import { Button, message } from "antd";
import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { useHistory, useParams } from "react-router";
import { get, post } from "../../libs/utils/request";
import { hasPermission, isAdmin, user } from "../../libs/utils/user";
import MessageModal from "../Events/components/MessageModal";
import { IUser } from "../Events/Event/Event";
import OrganizationDetails from "./components/OrganizationDetails";
import About from "./components/pages/About";
import Events from "./components/pages/Events";
import Competitons from "./components/pages/Competitions";
import Announcements from "./components/pages/Announcements";
import Followers from "./components/pages/Followers";
import Portfolio from "./components/pages/Portfolio";
import TitleCard from "./components/TitleCard";
import "./OrganizationPage.css";
import ReactPixel from "react-facebook-pixel"
import { Loading } from "../common/Loading";

const OrganizationPage = () => {
  const [errorMessage, setErrorMessage] = useState("");
  const [organization, setOrganization] = useState(null);
  const [userRoles, setUserRoles] = useState([]);
  const [isTeamMember, setTeamMember] = useState(true);
  const [messageModalVisible, setMessageModalVisible] = useState(false);
  const [isPartner, setIsPartner] = useState(undefined);

  const { tag, subPage } = useParams();
  const history = useHistory();

  function getQueryVariable(variable: string)
  {
          var query = window.location.search.substring(1);
          console.log(query)//"app=article&act=news_content&aid=160990"
          var vars = query.split("&");
          console.log(vars) //[ 'app=article', 'act=news_content', 'aid=160990' ]
          for (var i=0;i<vars.length;i++) {
                      var pair = vars[i].split("=");
                      console.log(pair)//[ 'app', 'article' ][ 'act', 'news_content' ][ 'aid', '160990' ] 
          if(pair[0] == variable){return pair[1];}
           }
           return(false);
  }

  const param = getQueryVariable('action')

  const follow = async () => {
    await post(`/organizations/${tag}/follow`);
    window.location.replace(`/organizations/${tag}`)
  }

useEffect(() => {
    if(user() && param === 'follow') {
      follow()
    } else if (!user() && param === 'follow') {
      window.location.replace(`/login`)
    }
  }, [])

useEffect(()=>{
    ReactPixel.fbq('track', 'ViewContent');
  })

  useEffect(() => {
    getOrganization();
  }, [tag]);

  const getOrganization = async () => {
    await get(`/organizations/${tag}`)
      .then((res:any) => {
        setOrganization(res);
        setIsPartner(res.isPartner)
      })
      .catch((error) => {
        if(error.response.status === 403 && error.response.data.extendedUser){
          sessionStorage.setItem("user", JSON.stringify(error.response.data.extendedUser))
          if(!error.response.data.extendedUser.active && !error.response.data.extendedUser.trial){
            window.location.replace('/trial-ended')
        }}
        if(error?.response.status === 404){
          message.error('We are sorry. This content might be deleted by the owners.', 5)
          history.push('/organizations')
        }
        setErrorMessage(error.data);
      });
  };

  const getOrganizers = (): IUser[] => {
    if (organization) {
      return [organization.createdBy];
    }
    return [];
  };

  const renderSection = () => {
    // const notImplemented = (text: string) => <div style={styles.notImplementedText}>{text}</div>;
    const defaultPage = <About organization={organization} />;
    const fallback = () => {
      history.push(`/organizations/${tag}`);
      return defaultPage;
    };
    switch (subPage) {
      case "feed":
        if (user()) {
          return <Announcements organization={organization} />;
        }
        return fallback();

      case "followers":
        if (user()) {
          return <Followers />;
        }
        return fallback();

      case "events":
        if (user()) {
          return <Events organization={organization} />;
        }
        return fallback();

      case "competitions":
        if (user()) {
          return <Competitons organization={organization} />;
        }
        return fallback();

      case "portfolio":
        if (user()) {
          return <Portfolio organization={organization} />;
        }
        return fallback();

      default:
        return defaultPage;
    }
  };

  const followOrganization = async () => {
    await post(`/organizations/${tag}/follow`);
    getOrganization();
  };

  const unfollowOrganization = async () => {
    await post(`/organizations/${tag}/unfollow`);
    getOrganization();
  };

  const onAddPartner = async () => {
    try {
      setIsPartner(!isPartner);
      await post(`/organizations/${tag}/partner`);
      getOrganization();
    } catch (err) {}
  };

  return (
    <div>
      <Helmet>
      <meta property="og:image" content={`${
                      organization?.image || "/images/profile-background.png"
                    }`} />
        <title>{`${organization?.name ? organization?.name : ''} | organization | pozi.io`}</title>
      </Helmet>
      {!organization ? (
        <Loading />
      ) : (
        <>
          {organization && (
            <>
              <div className="organizationpage-container">
                <div className="organizationpage-title-container">
                  <img
                    className="organizationpage-cover-image"
                    src={`${
                      organization?.image || "/images/profile-background.png"
                    }`}
                  />
                  <TitleCard
                    name={organization.name}
                    tag={organization.tag}
                    teamMember={organization.isMember}
                    organizer={organization.isOwner}
                    onMessage={() => setMessageModalVisible(true)}
                    onAddPartner={onAddPartner}
                    createdBy={organization.createdBy.id}
                    partner={isPartner}
                  />
                  <div className="organizationpage-logo-image-profile-container">
                    <div
                      className="organizationpage-logo-image-logo"
                      style={{
                        backgroundImage: `url(${
                          organization.logo || "/images/onePagerLogo.png"
                        })`,
                      }}
                    />
                  </div>
                </div>
                <div className="organizationpage-details-container">
                  <div className="organizationpage-details">
                    <OrganizationDetails organization={organization} />
                    <div className="organizationpage-social-org">
                      {organization.socialLinks &&
                        organization.socialLinks.map((link: any) => (
                          <a target="_blank" href={link.url}>
                            <img
                              src={
                                "/images/social_icons/" +
                                link.type.name +
                                ".svg"
                              }
                            />
                          </a>
                        ))}

                      {!organization.canFollow && organization.isFollowing && (
                        <Button type="primary" onClick={unfollowOrganization}>
                          {<span>Unfollow page!</span>}
                        </Button>
                      )}
                      {organization.canFollow  && (
                        <Button type="primary" onClick={followOrganization}>
                          {<span>Follow page!</span>}
                        </Button>
                      )}
                    </div>
                  </div>
                  <div className="organizationpage-short-description-org">
                    <img src="/images/user_info_icons/actual_oneliner.svg" />
                    {organization.shortDescription}
                  </div>
                </div>
                {renderSection()}
              </div>
              <MessageModal
                visible={messageModalVisible}
                users={getOrganizers()}
                onCancel={() => setMessageModalVisible(false)}
                eventName={organization.name}
                eventType="Organization"
              />
            </>
          )}
        </>
      )}
    </div>
  );
};

export default OrganizationPage;
