import React, { useEffect, useState } from 'react';
import {
  Alert,
  Button,
  Col, Form, message, Modal, Row, Tooltip,
} from 'antd';
import { WrappedFormUtils } from 'antd/lib/form/Form';
import { Moment } from 'moment';
import moment from 'moment-timezone';
import { useHistory, Prompt, useParams } from 'react-router';
import { HtmlEditor, Toolbar, Editor } from '@aeaton/react-prosemirror';
import { plugins, schema, toolbar } from '@aeaton/react-prosemirror-config-default';
import { Helmet } from 'react-helmet';
import InputTitleCard from './components/InputTitleCard';
import Cover from './components/Cover';
import GeneralCheckbox from '../common/form/GeneralCheckbox';
import {
  deleteReq, get, getBaseUrl, patch, post,
} from '../../libs/utils/request';
import Schedule, { sortSchedule } from './components/Schedule/Schedule';
import FormLabel from './components/FormLabel';
import FormInput from '../common/form/FormInput';
import { FormDateTimePicker } from '../common/form/DateTimePicker';
import { UserSearch } from '../User/UserSearch';
import PartnerLogos from './components/PartnerLogos/PartnerLogos';
import FormApplicationType from './components/FormApplicationType';
import { FormTimezoneSelector } from '../common/form/TimezoneSelector';
import { FormLocationSelector } from '../common/form/LocationSelector';
import FormInterests from '../common/form/FormInterests';
import User from '../../user/User';
import FormValidatedInput from '../common/form/FormValidatedInput';
import { ICompetition } from './Competition/Competition';
import { Loading } from '../common/Loading';
import ConfirmationDialog from '../common/ConfirmationDialog';
import './NewCompetitions.css';
import FormTextArea from '../common/form/FormTextArea';
import { colors, FormControlLabel, Radio, RadioGroup, RadioProps, Switch } from '@material-ui/core';
import { values } from 'lodash';
import { OrganizationSearch } from './Competition/components/OrganizationSearch';
import { isAdmin, user } from '../../libs/utils/user';
import Partnership from './Competition/components/Partnership/Partnership';
import { InfoCircleOutlined } from '@ant-design/icons';

const styles = {
  root: {
    flex: 1,
    display: 'flex',
    justifyContent: 'center',
    marginLeft: 20,
    marginRight: 20,
    marginBottom: 100,
  },
  content: { width: 1100 },
  durationLabels: {
    marginTop: 0,
    fontWeight: 300,
    fontSize: 16,
    lineHeight: '20px',
  },
  inputLabel: {
    fontFamily: 'Inter',
    fontWeight: 900,
    fontStyle: 'normal',
    fontSize: 18,
    lineHeight: '22px',
    color: '#282828',
  },
  googleLogo: {
    maxHeight: 12,
  },
  checkboxText: {
    fontFamily: 'Inter',
    fontWeight: 900,
    fontStyle: 'normal',
    fontSize: 15,
    color: '#282828',
  },
  formContent: {
    maxWidth: 1000,
    marginRight: 'auto',
    marginLeft: 'auto',
  },
  inputText: {
    fontSize: 16,
    color: '#282828',
  },
  submitContainer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column' as const,
    alignItems: 'center',
    marginTop: 100,
  },
  btnContainer: {
    display: 'flex',
    flexDirection: 'row' as const,
    flexWrap: 'wrap' as const,
    gap: '20px 20px',
  },
  submitText: {
    marginTop: 100,
    marginBottom: 40,
    fontFamily: 'Inter',
    fontWeight: 900,
    fontSize: 36,
    fontStyle: 'normal',
  },
  errorText: {
    fontSize: 20,
    marginTop: 72,
    textAlign: 'center' as const,
    fontWeight: 600,
    color: '#F00',
  },
  locationLabelContainer: {
    display: 'flex',
    flexDirection: 'row' as const,
    alignItems: 'center',
    marginTop: 32,
    marginBottom: 10,
    gap: '10px 10px',
  },
  descriptionContainer: {
    border: '1px solid #262626', borderRadius: 4, padding: 8, width: '100%', minHeight: 400,
  },
};

const DEFAULT_DESCRIPTION = '<H1><b>Add a detailed description of your competition.</b></H1>'
  + '<ul>'
  + '<li>Introduce your competition, what is the purpose, goals?</li>'
  + '<li>What are the benefits, awards, and prizes?</li>\n'
  + '<li>Add a detailed agenda (schedule, workshops, mentoring sessions)</li>'
  + '<li>Who can participate and how?</li>'
  + '<li>Why should they apply?</li>'
  + '<li>What are the rules and requirements (e.g. number of team members, duration of the pitch, final expectations, etc.)?</li>'
  + '<li>What are the judging criteria and evaluation processes?</li>'
  + '<li>What other tools/platforms are recommended for the participants?</li>'
  + '<li>Specific venue (if you organize an offline or hybrid competition)</li>'
  + '<li>Introduce the organizers</li>'
  + '<li>Contact information (email address, website)</li>'
  + '<li>FAQ</li>'
  + '</ul>';

const convertDate = (d: Moment, timezone: string): string => (timezone ? (moment(d))
  .tz(timezone, true) : moment(d))
  .toISOString();

const InputRow = ({ children }: { children: any }) => (
  <Row>
    <Col xs={24} sm={24} md={16}>
      {children}
    </Col>
  </Row>
);

export const isFutureDateValidator = (dateToCompare: Moment) => (_: any, value: Moment) => {
  if (!isAdmin() &&value && value <= dateToCompare) {
    return Promise.reject(new Error('Date must be a future date.'));
  }
  return Promise.resolve();
};

const imageLinkBase = `${getBaseUrl()}/image`;

const NewCompetition = ({ form, isEdit, isDraft, isCreating }: Props) => {
  const [coverImage, setCoverImage] = useState('');
  const [isOnline, setOnline] = useState(false);
  const [isPreregistration, setPreregistration] = useState(false);
  const [interestGroups, setInterestGroups] = useState([]);
  const [mentors, setMentors] = useState([]);
  const [juries, setJuries] = useState([]);
  const [organizers, setOrganizers] = useState([]);
  const [organization, setOrganization] = useState(null);
  const [organizerLogos, setOrganizerLogos] = useState([]);
  const [partnerLogos, setPartnerLogos] = useState([]);
  const [navigationBlocked, setNavigationBlocked] = useState(true);
  const [isSubmitting, setSubmitting] = useState(false);
  const [submitError, setSubmitError] = useState('');
  const [isTagEdited, setTagEdited] = useState(false);
  const [videoUrl, setVideoUrl] = useState('')
  const [partner, setPartner] = useState([])
  const [owner, setOwner] = useState(isCreating ? [JSON.parse(sessionStorage.getItem('user'))]: undefined)


  const [themes, setThemes] = useState([]);
  const [initialCount, setInitialCount] = useState(3);

  const [competition, setCompetition] = useState(null);
  const [createdAt, setCreatedAt] = useState('');
  const [isLoading, setLoading] = useState(true);
  const [errorMessage, setErrorMessage] = useState('');

  const [deleteDialogVisible, setDeleteDialogVisible] = useState(false);
  const [createOrganization,setCreateOrganization] = useState(false)

  const [description, setDescription] = useState('');

  const [value, setValue] = useState('manual');

  const { tag, draftId } = useParams();
  const history = useHistory();

  const userFromStorage = sessionStorage.getItem('user')
  const profileProgress =  userFromStorage ? JSON.parse(userFromStorage).progress : 0

  useEffect(() => {
    if (!isEdit) {
      setDescription(DEFAULT_DESCRIPTION);
    }
  }, [isEdit]);

  useEffect(() => {
    // eslint-disable-next-line no-undefined
    window.onbeforeunload = navigationBlocked ? () => true : undefined;
    return () => {
      // eslint-disable-next-line no-undefined
      window.onbeforeunload = undefined;
    };
  }, [navigationBlocked]);

  useEffect(() => {
    (async () => {
      try {
        let startedOrganization=localStorage.getItem('organization')
        setErrorMessage('');
        setLoading(true);
        const regData = await get<any>('/register/init');
        if (Array.isArray(regData.interestGroups)) {
          setInterestGroups(regData.interestGroups);
        }
        setOrganization(startedOrganization !== null ? JSON.parse(startedOrganization) : '')
        localStorage.removeItem('organization')
        let ownerArray =[]
        if(!isEdit){
          ownerArray.push(JSON.parse(sessionStorage.getItem('user')))
          setOwner(ownerArray)
        }
        if (isEdit) {
          let comp: ICompetition;
          if (!isDraft) {
            comp = await get<ICompetition>(`/competitions/${tag}`);
          } else {
            comp = await get<ICompetition>(`/competitions/draft/all/${draftId}`);
          }
          if(comp.isOrganization){
            setNavigationBlocked(false)
            history.push(comp.isOrganization)
          }
          const formatTime = (value: string) => {
            if (value) {
              return comp.timezone ? moment(value).tz(comp.timezone) : moment(value);
            }
            return null;
          };          
          ownerArray.push(comp.createdBy)

          setCompetition(comp);
          setCreatedAt(comp.createdAt);
          setCoverImage(comp.image);
          setOnline(comp.isOnline);
          setVideoUrl(comp.videoUrl);
          setPreregistration(comp.isPreregistration);
          setMentors(comp.mentors ? comp.mentors.map((item) => item.user) : []);
          setJuries(comp.juries ? comp.juries.map((item) => item.user) : []);
          setPartner(comp.partnerOrganizations ? comp.partnerOrganizations.map((item:any) => item) : []);
          setOrganizers(comp.coorganizers ? comp.coorganizers.map((item) => item.user) : []);
          setOwner(ownerArray)
          setThemes(comp.interests ? comp.interests
            .map((item) => ({ id: item.id, name: item.name })) : []);
          setInitialCount(Array.isArray(comp.schedules) ? Math.max(comp.schedules.length, 3) : 3);
          setOrganizerLogos(comp.partnerOrganizers ? comp.partnerOrganizers
            .map((item) => ({ partnerUrl: item.partnerUrl, name: item.name, image: `${imageLinkBase}/${item.image.filename}` })) : []);
          setPartnerLogos(comp.partners ? comp.partners
            .map((item) => ({ partnerUrl: item.partnerUrl, name: item.name, image: `${imageLinkBase}/${item.image.filename}` })) : []);
          let applicationTypes = { startup: false, idea: false, user: false };
          if (Array.isArray(comp.applicationTypes)) {
            applicationTypes = {
              startup: !!comp.applicationTypes.find((item) => item.name.toLowerCase() === 'startup'),
              idea: !!comp.applicationTypes.find((item) => item.name.toLowerCase() === 'idea'),
              user: !!comp.applicationTypes.find((item) => item.name.toLowerCase() === 'user'),
            };
          }
          // setEditorState(getEditorStateByHtml(comp.description));
          setDescription(comp.description);
          setOrganization(comp.organization ? comp.organization:"")

          form.setFieldsValue({
            title: comp.name,
            eventTag: comp.tag,
            applicationTypes,
            deadline: formatTime(comp.deadline),
            firstDate: formatTime(comp.durationFrom),
            lastDate: formatTime(comp.durationTo),
            timezone: comp.timezone,
            location: comp.location ? `${comp.location.city}, ${comp.location.country}` : comp.city,
            shortDescription: comp.shortDescription ? comp.shortDescription : '',
            description: comp.description,
            leadButtonText: comp.leadButtonText,
            additionalInfo: comp.additionalInfo,
            interests: comp.interests.map((item) => ({ id: item.id, name: item.name })),
            videoUrl: comp.videoUrl ? comp.videoUrl : '',
          });
          comp.schedules.forEach((item, i) => {
            form.setFieldsValue({
              [`schedule_date_${i}`]: formatTime(item.date),
              [`schedule_name_${i}`]: item.description,
            });
          });
        }
      } catch (error) {
        if (error.response?.data?.message) {
          setErrorMessage(error.response.data.message);
        } else {
          setErrorMessage(error.message);
        }
        if(error?.response?.status === 404){
          setNavigationBlocked(false)
          message.error('We are sorry. This content might be deleted by the owners.', 5)
          history.push('/competitions')
        }
      } finally {
        setLoading(false);
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isEdit, tag]);

  const changeCover = (newImage: string) => {
    const url: string = coverImage;
    setCoverImage(newImage);
    if (url && !isEdit) {
      post('/image/delete', { url });
    }
  };

  const ownerChange = (e:any)=>{
    const myOrganizers = organizers.map(organizer=>{ return organizer});
    setOwner(e);

    let filteredOrgs = myOrganizers;
    let filteredIds = organizers.map(organizer=>{ return organizer.id})
    
    if(filteredOrgs.map(org=>org.id === e[0].id)){
      filteredOrgs = organizers.filter(org=>org.id !== e[0].id)
    }

    if(!filteredIds.includes(competition?.createdBy.id) && competition?.createdBy.deleted !== true){
      filteredOrgs.push(competition ? competition.createdBy : JSON.parse(sessionStorage.getItem('user')))
    }
    setOrganizers(filteredOrgs)

  }

  const validateDeadline = (dateToCompare: Moment) => (_: any, value: Moment) => {
    if (!isAdmin() && value && value <= dateToCompare) {
      return Promise.reject(new Error('Date must be a future date.'));
    }
    const lastDate = form.getFieldValue('lastDate');
    if (value && lastDate && value >= lastDate) {
      return Promise.reject(new Error('The deadline must be larger then last date.'));
    }
    return Promise.resolve();
  };

  const validateLastDate = (dateToCompare: Moment) => (_: any, value: Moment) => {
    if (!isAdmin() && value && value <= dateToCompare) {
      return Promise.reject(new Error('Date must be a future date.'));
    }
    const firstDate = form.getFieldValue('firstDate');
    if (value && firstDate && value <= firstDate) {
      return Promise.reject(new Error('The end date must be larger then first date.'));
    }
    return Promise.resolve();
  };

  const validateDateByField = (field: string) => {
    setTimeout(() => {
      if (form.getFieldValue(field)) {
        form.validateFields([field]);
      }
    }, 300);
  };

  const tagValidator = async (t: string) => {
    await post('/competitions/validate-tag', { tag: t });
  };

  const generateTag = async (name: string) => {
    if (!isTagEdited) {
      const resp: { tag: string } = await post('/competitions/generate-tag', { name });
      form.setFieldsValue({ eventTag: resp.tag.toUpperCase() });
    }
  };

  const deleteCompetition = async () => {
    try {
      if (isDraft) {
        await deleteReq(`/competitions/draft/all/${draftId}`);
      } else {
        await deleteReq(`/competitions/${tag}`);
      }
      history.replace('/competitions');
    } catch (error) {
      message.error('Failed to delete competition.');
    }
  };

  const submitForm = () => {
    form.validateFields((err, values) => {
      if (err) {
        console.log(err)
        let error = Object.values(err).sort();
        document.getElementById('errors').innerHTML = "";
        document.getElementById('errors').innerHTML = "<h1>Please check these fields</h1>";
        error.map((errorMsg: any) => {
          document.getElementById('errors').append(errorMsg.errors[0].field.replace(/([a-z](?=[A-Z]))/g, '$1 ').replace(/_/g, " ").replace("name", "description").toLowerCase().replace(/([0-9])/gi, function (x: string) {
            return parseInt(x) + 1;
          }), "\n");
        })

      }
      let formattedDescription = description
      if(description.includes("span")){
        formattedDescription = description.replace(/span style="text-decoration:underline"/g, "u").replace(/span/g, 'u')
      }
      if(description.includes("<a href")){
        formattedDescription = description.replace(/<a href/g, "<a target='_blank' href")
      }

      if (!err) {
        const { timezone } = values;
        let placeId;
        try {
          placeId = JSON.parse(values.location).placeId;
        } catch (error) {
          if (isEdit) {
            placeId = competition.location.placeId;
          }
        }
        const formatSchedules = () => {
          sortSchedule(form);
          const tmp = [];
          let n = 0;
          while (form.getFieldValue(`schedule_name_${n}`)) {
            tmp.push({
              date: convertDate(form.getFieldValue(`schedule_date_${n}`), timezone),
              description: form.getFieldValue(`schedule_name_${n}`),
            });
            n += 1;
          }
          return tmp;
        };
        const formatUsers = (list: User[]): string[] => list.map((item) => item.id);
        const dto: any = {
          name: values.title,
          applicationTypes: Object.entries(values.applicationTypes)
            .filter((entry) => entry[1] === true)
            .map(([key]) => key),
          deadline: convertDate(values.deadline, timezone),
          durationFrom: convertDate(values.firstDate, timezone),
          durationTo: convertDate(values.lastDate, timezone),
          timezone,
          // country: location.country,
          // city: location.name,
          // description: values.description,
          location: {
            placeId,
          },
          createdBy: formatUsers(owner),
          shortDescription: values.shortDescription,
          description: formattedDescription,
          schedules: formatSchedules(),
          interests: values.interests.map((item: { id: string, name: string }) => item.id),
          image: coverImage,
          partners: partnerLogos,
          partnerOrganizers: organizerLogos,
          mentors: formatUsers(mentors),
          juries: formatUsers(juries),
          organizers: formatUsers(organizers),
          leadButtonText: values.leadButtonText,
          additionalInfo: values.additionalInfo,
          isOnline,
          isPreregistration,
          acceptType: value === 'manual' ? false : true,
          videoUrl: values.videoUrl,
          organizationId:organization?organization.id:null,
          partnerOrganizations:formatUsers(partner)
        };
        if (isDraft) {
          dto.draftId = draftId;
        }
        setNavigationBlocked(false);
        setSubmitting(true);
        (async () => {
          setTimeout(async () => {
            try {
              if (isEdit) {
                if (isDraft) {
                  if(isAdmin()){
                    await post('/competitions/create-admin', { tag: values.eventTag, ...dto });
                    history.replace('/competitions');
                  }
                  else{
                    await post('/competitions/create', { tag: values.eventTag, ...dto });
                    history.replace('/competitions');
                  }
                  
                } else {
                  await patch(`/competitions/${tag}`, { id: competition.id, ...dto });
                  history.replace(`/competitions/${tag}`);
                }
              } else {
                if(isAdmin()){
                  await post('/competitions/create-admin', { tag: values.eventTag, ...dto });
                }
                else{
                  await post('/competitions/create', { tag: values.eventTag, ...dto });
                }
                setSubmitting(false)

                history.replace('/competitions');
              }
            } catch (error) {
              if (error.response?.data?.name === 'ValidationError' && Array.isArray(error.response?.data?.details)) {
                const msg = Object.values(error.response.data.details[0]).join(', ');
                setSubmitError(`Validation failed: ${msg}.`);
              }if (error.response?.data?.message) {
                setSubmitError(error.response.data.message);
              }if(error.response?.status === 403){
                message.warning(`${profileProgress}% of your profile is completed. To update the competition you need to get 100%`)
              }else if(error.response?.data?.details[0].videoUrl){
                setSubmitError("Video url does not match any of the allowed types");
                message.error("Video url does not match any of the allowed types")
              }else if(error.response?.data?.details[0].description){
                setSubmitError(`Validation failed: Descripton length must be greater than 300 and less than or equal to 5000 characters long.`);
                message.error(`Validation failed: Descripton length must be greater than 300 and less than or equal to 5000 characters long`)
              }
               else {
                setSubmitError(error.message);
              }
              setNavigationBlocked(false);
              setSubmitting(false);
              
            }
            finally{
              setSubmitting(false)
            }
          }, 300);
        })();
      }
    });
  };

  let isNewOrganization=false;

  const saveDraft = (values: any) => {
    const { title } = values;
    if (!title || title.length < 3 || title.length > 50) {
      message.error('Please insert a valid title.');
    }
    else {
      // const acceptType= competition.acceptType;
      const { timezone } = values;
      let placeId;
      try {
        const parse = JSON.parse(values.location)?.placeId;
        placeId = parse || null;
      } catch (error) {
        if (isEdit) {
          placeId = competition.location?.placeId ? competition.location.placeId : null;
        }
      }
     
      const formatSchedules = () => {
        sortSchedule(form);
        const tmp = [];
        let n = 0;
        while (form.getFieldValue(`schedule_name_${n}`)) {
          tmp.push({
            date: convertDate(form.getFieldValue(`schedule_date_${n}`), timezone),
            description: form.getFieldValue(`schedule_name_${n}`),
          });
          n += 1;
        }
        return tmp;
      };
      const formatUsers = (list: User[]): string[] => list.map((item) => item.id);
      const dto = {
        name: values.title,
        applicationTypes: Object.entries(values.applicationTypes)
          .filter((entry) => entry[1] === true)
          .map(([key]) => key),
        deadline: values.deadline ? convertDate(values.deadline, timezone) : null,
        durationFrom: values.firstDate ? convertDate(values.firstDate, timezone) : null,
        durationTo: values.lastDate ? convertDate(values.lastDate, timezone) : null,
        timezone,
        // country: location.country,
        // city: location.name,
        // description: values.description,
        location: {
          placeId,
        },
        createdBy: formatUsers(owner),
        shortDescription: values.shortDescription,
        description: description.includes("span") ? description.replace(/span style="text-decoration:underline"/g, "u").replace(/span/g, 'u') : description,
        schedules: formatSchedules(),
        interests: values.interests.map((item: { id: string, name: string }) => item.id),
        image: coverImage,
        partners: partnerLogos,
        partnerOrganizers: organizerLogos,
        mentors: formatUsers(mentors),
        juries: formatUsers(juries),
        organizers: formatUsers(organizers),
        partnerOrganizations:formatUsers(partner),
        leadButtonText: values.leadButtonText,
        additionalInfo: values.additionalInfo,
        isOnline,
        isPreregistration,
        acceptType: value === 'manual' ? false : true,
        videoUrl:values.videoUrl,
        organizationId:organization?organization.id:""
      };

      setNavigationBlocked(false);
      setSubmitting(true);
      (async () => {
        setTimeout(async () => {
          try {
            if(isNewOrganization===true){
              if (isEdit) {
                await patch(`/competitions/draft/all/${draftId}`, { draftId, tag: values.eventTag, ...dto });
                history.replace('/new-!organization');
              } else {
                await post('/competitions/draft', { tag: values.eventTag, ...dto });
                history.replace('/new-!organization');
              }
            }else{
            if (isEdit) {
              await patch(`/competitions/draft/all/${draftId}`, { draftId, tag: values.eventTag, ...dto });
              history.replace('/competitions');
            } else {
              await post('/competitions/draft', { tag: values.eventTag, ...dto });
              history.replace('/competitions');
            }
          }
          } catch (error) {
            if (error.response?.data?.name === 'ValidationError' && Array.isArray(error.response?.data?.details)) {
              const msg = Object.values(error.response.data.details[0]).join(', ');
              setSubmitError(`Validation failed: ${msg}.`);
            } else if (error.response?.data?.message) {
              setSubmitError(error.response.data.message);
            } else {
              setSubmitError(error.message);
            }
            setNavigationBlocked(false);
            setSubmitting(false);
          }
        }, 300);
      })();
    }
  };
  function StyledRadio(props: RadioProps) {
    return (
      <Radio
        disableRipple
        color="primary"
        {...props}
      />
    );
  }

  const handleRadioChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValue((event.target as HTMLInputElement).value);
  };


  const codeBlock = document.getElementsByClassName('fa-code');
  for (let i = 0; i < codeBlock.length; i++) {
    codeBlock[i].parentElement.style.display = "none"
  }

  const checkTitle=(values: any)=>{
    const { title } = values;
    if (!title || title.length < 3 || title.length > 50) {
      message.error('Please insert a valid title.');
    }else{
      isNewOrganization=true;
      saveDraft(values)
    }
  }

  const organizationChange=(value:any)=>{
    if(value===null){
      checkTitle(form.getFieldsValue())
    }else{
      setOrganization(value)
    }
  }

  return (
    <div style={styles.root}>
      <Helmet>
        <title>{isEdit ? "Edit competition":"Create competition"}  | pozi.io</title>
      </Helmet>
      <Prompt
        when={navigationBlocked}
        message='You have unsaved changes, are you sure you want to leave?'
      />
      {isLoading && <Loading />}
      {errorMessage && (
        <>
          <div style={styles.errorText}>Failed to load competition:</div>
          <div style={{ ...styles.errorText, marginTop: 0, fontWeight: 400 }}>
            {errorMessage}
          </div>
        </>
      )}
      <div style={{ ...styles.content, display: isLoading ? 'none' : 'block' }}>
        <Cover url={coverImage} onChange={changeCover} />
        <InputTitleCard form={form} fieldKey='title' onAfterTyping={generateTag} />

        <div style={styles.formContent}>
        <InputRow>
            <FormLabel title='Add your organization' style={{ marginTop: 40 }} />
            <OrganizationSearch value={organization} onChange={(value)=>{organizationChange(value)}} />
          </InputRow>
          <Row gutter={[12, 12]}>
            <Col xs={24} sm={12} md={8}>
              <FormLabel title='Application type' />
              {isEdit ? (
                <FormApplicationType form={form} fieldKey='applicationTypes' initialValue={form.getFieldValue('applicationTypes')} />
              ) : (
                <FormApplicationType form={form} fieldKey='applicationTypes' />
              )}
            </Col>
            <Col xs={24} sm={12} md={8}>
              <FormLabel title='Application deadline' />
              <FormDateTimePicker
                form={form}
                fieldKey='deadline'
                required
                rules={[{
                  validator: validateDeadline(isEdit
                    ? moment(createdAt) : moment()),
                }]}
                onChange={() => validateDateByField('deadline')}
              />
            </Col>
          </Row>

          <FormLabel title='Duration' />
          <Row gutter={[12, 12]}>
            <Col xs={24} sm={12} md={8}>
              <div>
                <FormLabel style={styles.durationLabels} title='From' />
                <FormDateTimePicker
                  form={form}
                  fieldKey='firstDate'
                  required
                  rules={[
                    {
                      validator: isFutureDateValidator(isEdit
                        ? moment(createdAt) : moment()),
                    },
                  ]}
                  onChange={() => validateDateByField('lastDate')}
                />
              </div>
            </Col>
            <Col xs={24} sm={12} md={8}>
              <div>
                <FormLabel style={styles.durationLabels} title='To' />
                <FormDateTimePicker
                  form={form}
                  fieldKey='lastDate'
                  rules={[{
                    validator: validateLastDate(isEdit
                      ? moment(createdAt) : moment()),
                  }]}
                  onChange={() => validateDateByField('deadline')}
                  required
                />
              </div>
            </Col>
          </Row>
          <Row>
            <FormLabel title='Accept type' />
            {isEdit ? (
              <RadioGroup name="acceptType" value={value} onChange={handleRadioChange} >
                <FormControlLabel value="manual" control={<StyledRadio />} label="Manual accept" defaultChecked={true} />
                <FormControlLabel value="auto" control={<StyledRadio />} label="Auto accept" />
              </RadioGroup>) : (
              <RadioGroup name="acceptType" value={value} onChange={handleRadioChange} >
                <FormControlLabel value="manual" control={<StyledRadio />} label="Manual accept" defaultChecked={true} />
                <FormControlLabel value="auto" control={<StyledRadio />} label="Auto accept" />
              </RadioGroup>
            )}
          </Row>
          <Row>
            <FormLabel title='Timezone' />
            <Col xs={24} sm={12} md={8}>
              <FormTimezoneSelector form={form} fieldKey='timezone' style={{ color: '#6755CE' }} required />
            </Col>
          </Row>

          <Row gutter={[12, 12]}>
            <Col xs={24} sm={12} md={8}>
              <div style={styles.locationLabelContainer}>
                <div style={styles.inputLabel}>Location</div>
                <img style={styles.googleLogo} src='/images/google/powered_by_google.png' alt='Powered by Google' />
              </div>
              <FormLocationSelector fieldKey='location' form={form} style={{ color: '#6755CE' }} required />
              <div style={{ marginTop: 10 }}>
                <GeneralCheckbox
                  text='Online competition'
                  checked={isOnline}
                  onChange={setOnline}
                  textStyle={styles.checkboxText}
                />
              </div>
              <div style={{ marginTop: 16 }}>
                <GeneralCheckbox
                  text='Pre-registration'
                  checked={isPreregistration}
                  onChange={setPreregistration}
                  textStyle={styles.checkboxText}
                />
              </div>
            </Col>
          </Row>
          <Row>
            <FormLabel title='Insert your Youtube, Facebook Stream/Video or Vimeo Link/ Embed Code!' />
            <Col xs={24} sm={24} md={24}>
              <FormInput
                form={form}
                fieldKey='videoUrl'
                rules={[
                  {pattern: new RegExp(/^(?:https?:\/\/)?(?:www\.)?(?:youtu\.be\/|youtube\.com\/(?:embed\/|v\/|watch\?v=|watch\?.+&v=))((\w|-){11})(?:\S+)?$|https?:\/\/(?:www\.|player\.)?vimeo.com\/(?:channels\/(?:\w+\/)?|groups\/([^\/]*)\/videos\/|album\/(\d+)\/video\/|video\/|)(\d+)(?:$|\/|\?)|^(?:<div[^>]+>)?<iframe src="https:\/\/(?:player\.)?vimeo\.com[^"]+"[^>]+><\/iframe>(?:<\/div>)?$|^(https:\/\/www\.facebook\.com).*$|^(https:\/\/fb\.watch).*$/,)
                    ,message:"Please insert a valid youtube or vimeo url"
                  }
                ]}
              />
            </Col>
          </Row>
          <Row>
            <FormLabel title='Short description' subtitle='(Short description cannot be longer than 100 characters)'/>
            <Col xs={24} sm={24} md={24}>
              <FormTextArea
                form={form}
                fieldKey='shortDescription'
                rules={[
                  { min: 3, message: 'Short description must be at least 3 characters.' },
                  { max: 100, message: 'Short description cannot be longer than 100 characters.' },
                ]}
                autoSize={{ minRows: 4 }}
                required
              />
            </Col>
          </Row>

          <FormLabel title='Description'  subtitle='(Descripton length must be greater than 300 and less than or equal to 5000 characters long.)'/>
          {!!description && (
            <div className='description-preview' style={styles.descriptionContainer}>
              <HtmlEditor
                schema={schema}
                plugins={plugins}
                value={description}
                handleChange={setDescription}
                debounce={300}
              >
                <Toolbar toolbar={toolbar} />
                <Editor autoFocus />
              </HtmlEditor>
            </div>
          )}

          <FormLabel title='Themes of your competition' subtitle='(Choose 5 or less options)' style={{ marginBottom: 25, marginTop: 76 }} />
          <FormInterests form={form} fieldKey='interests' interestGroups={interestGroups} initialValue={themes} />

          <FormLabel title='Competition schedule' style={{ marginTop: 119, marginBottom: 35 }} />
          {isEdit ? (
            <Schedule
              form={form}
              initialCount={initialCount}
              isEdit
              createdAt={createdAt}
            />
          ) : (
            <Schedule form={form} isEdit={false} />
          )}
          <InputRow>
            <FormLabel title='Lead button text' subtitle='(Can be modified later)' style={{ marginTop: 79 }} />
            <FormInput
              form={form}
              fieldKey='leadButtonText'
              placeholder='Eg.: Apply Event/Click here to join'
              required
              rules={[
                { min: 3, message: 'Lead button text must be at least 3 characters.' },
                { max: 15, message: 'Lead button text cannot be longer than 15 characters.' },
              ]}
            />
          </InputRow>
          <InputRow>
            <FormLabel
              title='Personalize the Application Form with an additional information'
              subtitle='(Optional. You can attach a link, if you would like to ask for more information about the applicants. This will appear on the application form of your competition.)'
              style={{ marginTop: 40 }}
            />
            <FormTextArea
              form={form}
              fieldKey='additionalInfo'
              rules={[
                { min: 3, message: 'Additional information must be at least 3 characters.' },
                { max: 300, message: 'Additional information cannot be longer than 300 characters.' },
              ]}
              autoSize={{ minRows: 4 }}
            />
          </InputRow>
          <InputRow>
            <FormLabel title='Unique Virtual Competition Tag' subtitle='(Can’t be modified later)' style={{ marginTop: 40 }} />
            <FormValidatedInput
              form={form}
              fieldKey='eventTag'
              placeholder='Eg.: HSTLR2021'
              style={styles.inputText}
              validator={tagValidator}
              required
              isEdited={isTagEdited}
              onIsEdited={setTagEdited}
              rules={[
                { min: 3, message: 'Tag must be at least 3 characters.' },
                { max: 20, message: 'Tag cannot be longer than 20 characters.' },
              ]}
              disabled={isEdit && !isDraft}
            />
          </InputRow>
          <InputRow>
            <FormLabel title='Choose your mentors' style={{ marginTop: 40 }} />
            <UserSearch multiple value={mentors} onChange={setMentors} />
          </InputRow>
          <InputRow>
            <FormLabel title='Choose your jury' style={{ marginTop: 40 }} />
            <UserSearch multiple value={juries} onChange={setJuries} />
          </InputRow>
          {(competition && competition.createdBy.id === JSON.parse(userFromStorage).id || isCreating || isAdmin()) &&
          <InputRow>
            <FormLabel title='Owner' style={{ marginTop: 40, display: "inline-block", marginRight: 12 }} />       
            <Tooltip placement="topLeft" title={'With this step, you delegate the Owner status to another user, your role will change to co-organizer.'}>
              <InfoCircleOutlined />
            </Tooltip>
            <UserSearch owner multiple={false} value={owner} onChange={(e)=>ownerChange(e)} />
          </InputRow>}
          <InputRow>
            <FormLabel title='Invite co-organizers' style={{ marginTop: 40 }} />
            <UserSearch multiple value={organizers} onChange={setOrganizers} />
          </InputRow>
          <InputRow>
            <FormLabel title='Partnership' style={{ marginTop: 40 }} />
            <Partnership multiple value={partner} onChange={setPartner}/>
          </InputRow>
         
          <FormLabel title='Organizer logos' style={{ marginTop: 80 }} />
          <PartnerLogos value={organizerLogos} onChange={setOrganizerLogos} />

          <FormLabel title='Supporter/Partner logos' style={{ marginTop: 40 }} />
          <PartnerLogos value={partnerLogos} onChange={setPartnerLogos} />

          <div id="errors" style={{ whiteSpace: "break-spaces", color: "red", textAlign: "center" }}>

          </div>
          <div style={styles.submitContainer}>
            {!isEdit && (
              <div style={styles.submitText}>Are you ready?</div>
            )}
            <div style={styles.btnContainer}>
              {(isDraft || (!isEdit && !isDraft)) && (
                <Button style={{ fontWeight: 'bold', minWidth: 150 }} loading={isSubmitting} type='primary' size='large' onClick={() => saveDraft(form.getFieldsValue())}>
                  Save draft
                </Button>
              )}
              <Button style={{ fontWeight: 'bold', minWidth: 150 }} loading={isSubmitting} type='primary' size='large' onClick={submitForm}>
                {isDraft || (!isEdit && !isDraft) ? 'Launch' : 'Save changes'}
              </Button>
              {isEdit && (
                <Button style={{ fontWeight: 'bold', minWidth: 150 }} loading={isSubmitting} type='danger' size='large' onClick={() => setDeleteDialogVisible(true)}>
                  Delete competition
                </Button>
              )}
            </div>
          </div>
          {submitError && (
            <Alert
              style={{ marginTop: 32 }}
              message='Failed to submit form'
              description={submitError}
              type='error'
              showIcon
            />
          )}
        </div>
      </div>
      <ConfirmationDialog
        visible={deleteDialogVisible}
        onOk={() => {
          setNavigationBlocked(false);
          setDeleteDialogVisible(false);
          deleteCompetition();
        }}
        onCancel={() => setDeleteDialogVisible(false)}
        title='Delete competition'
        description='Are you sure you want to delete competition? This operation is not revertible.'
        confirmBtnText='Delete'
        confirmBtnType='danger'
      />
      <Modal
        visible={createOrganization}
        onOk={() => {
          setCreateOrganization(false);
          saveDraft(form.getFieldsValue)
            window.location.replace(window.origin+'/new-!organization')
        }}
        onCancel={() => {setCreateOrganization(false);console.log(form.getFieldsValue)}}
        title='Create organization'
      />
    </div>
  );
};

interface Props {
  form: WrappedFormUtils;
  isEdit?: boolean;
  isDraft?: boolean;
  isCreating?: boolean;
}

NewCompetition.defaultProps = {
  isEdit: false,
  isDraft: false,
};

export default Form.create<Props>({ name: 'competition' })(NewCompetition);
