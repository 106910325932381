import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router";
import ga4 from "react-ga4";
import { message } from "antd";
import moment from "moment-timezone";
import { Helmet } from "react-helmet";
import { SubPageEnum } from "./SubPageEnum";
import Header from "./components/Header/Header";
import { get, post, deleteReq } from "../../../libs/utils/request";
import Summary from "./components/Summary/Summary";
import ApplicationModal from "./ApplicationModal";
import Participants from "./components/Participants/Participants";
import Startups from "./components/Startups/Startups";
import { Loading } from "../../common/Loading";
import Ideas from "./components/Ideas/Ideas";
import { user } from "../../../libs/utils/user";
import Winners from "./components/Winners/Winners";
import MessageModal from "./MessageModal";
import Feed from "./components/Feed/Feed";
import ConfirmationDialog from "../../common/ConfirmationDialog";
import LoginWallModal from "../../common/LoginWallModal";
import Jury from "./components/Jury/Jury";
import Evaluation from "./components/Evaluation/Evaluation";
import GenerateSummaryModal from "./GenerateSummaryModal";
import Competitions from "../Competitions";
import ReactPixel from "react-facebook-pixel"


const styles = {
  root: {
    flex: 1,
    display: "flex",
    justifyContent: "center",
    paddingLeft: 20,
    paddingRight: 20,
    marginBottom: 100,
  },
  notImplementedText: {
    fontSize: 20,
    fontWeight: "bold" as const,
    textAlign: "center" as const,
    marginTop: 72,
    color: "#282828",
  },
  errorText: {
    fontSize: 20,
    marginTop: 72,
    textAlign: "center" as const,
    fontWeight: 600,
    color: "#F00",
  },
  errorButtonText: {
    fontSize: 16,
    marginTop: 10,
    textAlign: "center" as const,
    fontWeight: 400,
    textDecoration: "underline",
    cursor: "pointer",
    color: "#6755CE",
  },
  content: { width: 1300 },
};

export const formatDate = (
  date: string,
  timezone: string,
  format: string
): string => moment(date).tz(timezone).format(format);

export const havePermission = (target: string[], userRoles: string[]) => {
  if (!target.length) {
    return !userRoles.length;
  }
  for (let i = 0; i < target.length; i += 1) {
    if (userRoles.includes(target[i])) {
      return true;
    }
  }
  return false;
};

const Competition = () => {
  const [competition, setCompetition] = useState(null);
  const [canJoin, setJoinPermission] = useState(false);
  const [userRoles, setUserRoles] = useState([]);
  const [isLoading, setLoading] = useState(true);
  const [errorMessage, setErrorMessage] = useState("");
  const [applicationModalVisible, setApplicationModalVisible] = useState(false);
  const [loginWallVisible, setLoginWallVisible] = useState(false);
  const [messageModalVisible, setMessageModalVisible] = useState(false);
  const [withdrawButtonVisible, setWithdrawButtonVisible] = useState(false);
  const [withdrawDialogVisible, setWithdrawDialogVisible] = useState(false);
  const [myProjects, setMyProjects] = useState({ startups: [], ideas: [] });
  const [isTeamMember, setTeamMember] = useState(true);
  const [criteria, setCriteria] = useState<ICriterion[]>([]);
  const [downloadModalVisible, setDownloadModalVisible] = useState(false);
  const [isPinned, setIsPinned] = useState(false);

  const { tag, subPage } = useParams();
  const history = useHistory();

  useEffect(() => {
    ReactPixel.fbq('track', 'ViewContent');
  })

  useEffect(() => {
    if (subPage) {
      if (!Object.values(SubPageEnum).includes(subPage)) {
        history.push(`/competitions/${tag}`);
      }
    }
  }, [history, tag, subPage]);

  useEffect(() => {
    (async () => {
      if (competition !== null) {

      }
      try {
        setErrorMessage("");
        setLoading(true);
        const comp: ICompetition = await get<ICompetition>(
          `/competitions/${tag}`
        )
        if (comp === null) {
          history.push('/dashboard')
        }
        setCompetition({
          ...comp,
          interests: comp.interests.map((item) => ({
            id: item.id,
            name: item.name,
            icon: item.group.icon,
          })),
        });
        setJoinPermission(comp.canJoin);

        if (user()) {
          const roles: { roles: string[]; isTeamMember: boolean } = await get<{
            roles: string[];
            isTeamMember: boolean;
          }>(`/competitions/${tag}/my-roles`);
          setUserRoles(roles.roles);
          setTeamMember(roles.isTeamMember);
          const myProjectsTmp = await get<IMyProjects>(
            `/competitions/${tag}/my-projects`
          );
          setMyProjects(myProjectsTmp);
          if (
            havePermission(["Jury", "Organizer", "Co-organizer"], roles.roles)
          ) {
            const tmpCriteria = await get<ICriterion[]>(
              `competitions/${tag}/evaluation/criteria`
            );
            setCriteria(tmpCriteria);
          }
        }
      } catch (error) {
        if (error.response.status === 404) {
          message.error('We are sorry. This content might be deleted by the owners.', 5)
          history.push('/competitions')
        }
        if (error.response?.data?.message) {
          setErrorMessage(error.response.data.message);
        } else {
          setErrorMessage(error.message);
        }
      } finally {
        setLoading(false);
      }
    })();
  }, [tag]);

  useEffect(() => {
    window.onpopstate = () => {
      if (applicationModalVisible) {
        setApplicationModalVisible(false);
      }
    };
    return () => {
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      window.onpopstate = () => { };
    };
  }, [applicationModalVisible]);

  useEffect(() => {
    if (competition !== null) {
      setJoinPermission(competition.canJoin)
    }
  }, [competition]);



  useEffect(() => {
    setWithdrawButtonVisible(
      user() &&
      havePermission(["Participant", "Participant Pending"], userRoles)
    );
  }, [userRoles]);

  useEffect(() => {
    if (competition !== null) {
      if (localStorage.url === `${window.location.origin}/competitions/${tag}` ||
        localStorage.url === `${window.location.origin}/dashboard`) {
        localStorage.removeItem('url');
        if (competition.canJoin && JSON.parse(sessionStorage.getItem('user')).progress >= 50) {
          setApplicationModalVisible(true);
        }
      }
    }
  }, [competition])
  const getCompetition = async (withLoading = true) => {
    try {
      setErrorMessage("");
      if (withLoading) {
        setLoading(true);
      }
      const comp: ICompetition = await get<ICompetition>(
        `/competitions/${tag}`
      );
      setCompetition({
        ...comp,
        interests: comp.interests.map((item) => ({
          id: item.id,
          name: item.name,
          icon: item.group.icon,
        })),
      });
      const roles: { roles: string[]; isTeamMember: boolean } = await get<{
        roles: string[];
        isTeamMember: boolean;
      }>(`/competitions/${tag}/my-roles`);
      setUserRoles(roles.roles);
      setTeamMember(roles.isTeamMember);
      const myProjectsTmp = await get<IMyProjects>(
        `/competitions/${tag}/my-projects`
      );
      setMyProjects(myProjectsTmp);
      if (havePermission(["Jury", "Organizer", "Co-organizer"], roles.roles)) {
        const tmpCriteria = await get<ICriterion[]>(
          `competitions/${tag}/evaluation/criteria`
        );
        setCriteria(tmpCriteria);
      }
    } catch (error) {
      if (error.response.status === 403 && error.response.data.extendedUser) {
        sessionStorage.setItem("user", JSON.stringify(error.response.data.extendedUser))
        if (!error.response.data.extendedUser.active && !error.response.data.extendedUser.trial) {
          window.location.replace('/trial-ended')
        }
      }
      if (error.response.status === 404) {
        message.error('We are sorry. This content might be deleted by the owners.', 5)
        history.push('/competitions')
      }
      if (error.response?.data?.message) {
        setErrorMessage(error.response.data.message);
      } else {
        setErrorMessage(error.message);
      }
    } finally {
      setLoading(false);
    }
  };

  const getOrganizers = (): IUser[] => {
    if (competition) {
      return [competition.createdBy].concat(
        competition.coorganizers.map((item: ISomeone) => item.user)
      );
    }
    return [];
  };

  const handleApplicationSubmit = async () => {
    setApplicationModalVisible(false);
    await getCompetition();
    message.success("Application Succeeded.");
    ga4.ga('send', { 'hitType': 'pageview', 'page': 'competitions/success', 'title': 'Applied for competition' });
    if (competition.acceptType === true) {
      await post(`/competitions/${tag}/participants/${user().id}/accepted`);
      location.reload();
    } else {
      return;
    }
  };

  const handleApply = () => {
    if (user()) {
      setApplicationModalVisible(true);
    } else {
      localStorage.setItem("url", window.location.href);
      setLoginWallVisible(true);
      ga4.ga('send', { 'hitType': 'pageview', 'page': 'competitions/regorlogin', 'title': 'To Reg. or Login popup' });
    }
  };

  const handleWithdraw = async () => {
    if (
      user() &&
      havePermission(["Participant", "Participant Pending"], userRoles)
    ) {
      try {
        await deleteReq(`/competitions/${tag}/apply`);
        await getCompetition();
        message.success("Withdraw succeed.");
      } catch (error) {
        message.error("Failed to withdraw application.");
      }
    }
  };

  const handlePin = async () => {
    try {
      setIsPinned(!competition.isPinned);
      await post(`/competitions/${tag}/pin`).then(() => {
        getCompetition()
      })
    } catch (err) {
      console.log(err);
    }
  };

  const renderSection = () => {
    const types = competition.applicationTypes.map(
      (item: IApplicationType) => item.name
    );
    // const notImplemented = (text: string) => <div style={styles.notImplementedText}>{text}</div>;
    const defaultPage = (
      <Summary
        competition={competition}
        applyButtonVisible={canJoin}
        onApply={handleApply}
        onWithdraw={() => setWithdrawDialogVisible(true)}
        withdrawButtonVisible={withdrawButtonVisible}
        youtubeVideo={youtubeVideo}
        vimeoVideo={vimeoVideo}
        vimeoEmbed={vimeoEmbed}
        fbVideo={fbVideo}
      />
    );
    const fallback = () => {
      history.push(`/competitions/${tag}`);
      return defaultPage;
    };
    switch (subPage) {
      case SubPageEnum.participants:
        if (user()) {
          return <Participants tag={competition.tag} userRoles={userRoles} />;
        }
        return fallback();
      case SubPageEnum.startups:
        if (user() && types.includes("Startup")) {
          return (
            <Startups
              tag={competition.tag}
              userRoles={userRoles}
              myProjects={myProjects}
              isTeamMember={isTeamMember}
              onChange={getCompetition}
              evaluationStatus={competition.evaluationStatus}
            />
          );
        }
        return fallback();
      case SubPageEnum.ideas:
        if (user() && types.includes("Idea")) {
          return (
            <Ideas
              tag={competition.tag}
              userRoles={userRoles}
              myProjects={myProjects}
              isTeamMember={isTeamMember}
              onChange={getCompetition}
              evaluationStatus={competition.evaluationStatus}
            />
          );
        }
        return fallback();
      case SubPageEnum.feed:
        if (user()) {
          return <Feed competition={competition} tag={competition.tag} userRoles={userRoles} />;
        }
        return fallback();
      case SubPageEnum.winners:
        if (user()) {
          return <Winners tag={competition.tag} userRoles={userRoles} />;
        }
        return fallback();
      case SubPageEnum.jury:
        if (
          user() &&
          havePermission(["Jury"], userRoles) &&
          competition.evaluationStatus !== "INIT"
        ) {
          return (
            <Jury
              tag={competition.tag}
              criteria={criteria}
              evaluationStatus={competition.evaluationStatus}
            />
          );
        }
        return fallback();
      case SubPageEnum.evaluation:
        if (
          user() &&
          havePermission(["Organizer", "Co-organizer"], userRoles)
        ) {
          return (
            <Evaluation
              tag={competition.tag}
              criteria={criteria}
              evaluationStatus={competition.evaluationStatus}
              onChange={getCompetition}
            />
          );
        }
        return fallback();
      default:
        return defaultPage;
    }
  };

  if (isLoading) {
    return <Loading />;
  }
  const youtubeVideo = competition.videoType === "youtube" && competition.videoId ? competition.videoId : ""
  const vimeoVideo = competition.videoType === "vimeo" && competition.videoId ? competition.videoId : ""
  const vimeoEmbed = competition.videoType === "iframe" && competition.videoUrl ? competition.videoUrl : ""
  const fbVideo = competition.videoType === "facebook" && competition.videoUrl ? competition.videoUrl : "";

  return (
    <div style={styles.root}>
      <Helmet>
        <title>{`${competition.name} | competition | pozi.io`}</title>
      </Helmet>
      <div style={styles.content}>
        {errorMessage ? (
          <>
            <div style={styles.errorText}>Failed to load competition:</div>
            <div style={{ ...styles.errorText, marginTop: 0, fontWeight: 400 }}>
              {errorMessage}
            </div>
            <div
              style={styles.errorButtonText}
              onClick={() => getCompetition()}
              aria-hidden="true"
            >
              retry
            </div>
          </>
        ) : (
          <>
            <Header
              competition={competition}
              userRoles={userRoles}
              applyButtonVisible={canJoin}
              onApply={handleApply}
              onWithdraw={() => setWithdrawDialogVisible(true)}
              withdrawButtonVisible={withdrawButtonVisible}
              onMessage={() => setMessageModalVisible(true)}
              evaluationStatus={competition.evaluationStatus}
              onGenerateSummary={() => setDownloadModalVisible(true)}
              onAddPin={handlePin}
              pin={competition.isPinned}
            />
            {renderSection()}
            <ApplicationModal
              tag={competition.tag}
              visible={applicationModalVisible}
              onSubmitApplication={() => handleApplicationSubmit()}
              onCancel={() => {
                setApplicationModalVisible(false);
              }}
              myProjects={myProjects}
              isPreregistration={competition.isPreregistration}
              additionalInfo={competition.additionalInfo}
            />
            <LoginWallModal
              title="Log in or Sign up!"
              description="For applying to this competition, first, you need to log in. If you do not have a Pozi account yet, feel free to sign up! Good luck with the Competition! The Pozi Team"
              visible={loginWallVisible}
              onCancel={() => setLoginWallVisible(false)}
            />
            <MessageModal
              visible={messageModalVisible}
              users={getOrganizers()}
              onCancel={() => setMessageModalVisible(false)}
              competitionName={competition.name}
            />
            <ConfirmationDialog
              visible={withdrawDialogVisible}
              onCancel={() => setWithdrawDialogVisible(false)}
              onOk={() => {
                setWithdrawDialogVisible(false);
                handleWithdraw();
              }}
              title="Withdraw application"
              description="Are you sure you want to withdraw your application?"
              confirmBtnText="Withdraw"
              confirmBtnType="danger"
            />
            <GenerateSummaryModal
              tag={competition.tag}
              visible={downloadModalVisible}
              onCancel={() => setDownloadModalVisible(false)}
            />
          </>
        )}
      </div>
    </div>
  );
};

interface IRole {
  name: string;
}

export interface IUser {
  id: string;
  firstName: string;
  lastName: string;
  profileImage: string;
  oneLiner: string;
  role: IRole;
  city: string;
  location: ILocation;
  uniqueTag: string;
  deleted?: boolean;
}

export interface ISomeone {
  id: string;
  user: IUser;
}

interface IPartnerImage {
  filename: string;
}

export interface IPartner {
  id: string;
  name: string;
  partnerUrl: string;
  image: IPartnerImage;
}

export interface IInterestGroup {
  id: string;
  name: string;
  icon: string;
}

export interface IApplicationType {
  name: string;
}

interface IInterest {
  id: string;
  name: string;
  icon: string;
  group: any;
}

export interface IMyProject {
  id: string;
  name: string;
  image: string;
}

export interface IMyProjects {
  startups: IMyProject[];
  ideas: IMyProject[];
}

export interface ICriterion {
  ordinalNo: number;
  id: string;
  title: string;
  description: string;
}

export interface ILocation {
  id: string;
  streetNumber: string;
  streetName: string;
  city: string;
  country: string;
  state: string;
  postalCode: string;
  placeId: string;
}

export interface ICompetition {
  isOrganization?: any;
  partnerOrganizations?: any;
  organizer: any;
  organization: any;
  id: string;
  name: string;
  tag: string;
  additionalInfo: string;
  image: string;
  schedules: any[];
  leadButtonText: string;
  city?: string;
  country?: string;
  deadline: string;
  timezone: string;
  durationFrom: string;
  durationTo: string;
  shortDescription: string;
  description: string;
  interestGroups: IInterestGroup[];
  mentors: ISomeone[];
  juries: ISomeone[];
  coorganizers: ISomeone[];
  partnerOrganizers: IPartner[];
  partners: IPartner[];
  applicationTypes: IApplicationType[];
  isOnline: boolean;
  isPreregistration: boolean;
  interests: IInterest[];
  createdBy: IUser;
  createdAt: string;
  evaluationStatus: "INIT" | "OPEN" | "CLOSED";
  location: ILocation;
  isPinned: boolean;
  canJoin: boolean;
  commentGroup: any;
  ratingGroup: any;
  shareCount: number;
  userRating: any;
  youtubeVideoUrl: string;
  youtubeVideoId: string;
  videoType: string;
  videoUrl: string;
  newsGroup?: any;
  owner?: any
}

export default Competition;
