import React, { CSSProperties, useState } from 'react';
import { Button, Timeline, message } from 'antd';
import ga4 from 'react-ga4';
import Card from '../../../../../common/card/Card';
import '../../../Competition.css';
import ScheduleDot from '../../../../../common/ScheduleDot';
import ScheduleItem from './ScheduleItem';
import { formatDate } from '../../../Competition';
import { user } from '../../../../../../libs/utils/user';
import { get } from '../../../../../../libs/utils/request';
import LoginWallModal from '../../../../../common/LoginWallModal';

const styles = {
  root: {
    paddingTop: 30,
    paddingBottom: 30,
    paddingLeft: 40,
    paddingRight: 40,
    borderRadius: 10,
  },
  title: {
    fontFamily: 'Inter',
    fontWeight: 800,
    fontStyle: 'normal',
    fontSize: 16,
    marginBottom: 16,
  },
  scrollView: {
    height: 180,
    overflow: 'scroll',
    overflowX: 'hidden' as const,
  },
  btnContainer: {
    marginTop: 20,
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  btn: { fontWeight: 'bold' as const, minWidth: 150 },
  attendingText: {
    fontFamily: 'Inter',
    fontWeight: 400,
    fontStyle: 'italic',
    fontSize: 16,
  },
};

const ScheduleCard = ({
  schedules, leadButtonText, style, className, timezone,
  onApply, withdrawButtonVisible, applyButtonVisible
}: Props) => {
  const [loginWallVisible, setLoginWallVisible] = useState(false);

  const checkProfile = async () => {
    if (user()) {
      try {
        onApply()
      } catch (err) {
        console.log(err)
      }
    } else {
      localStorage.setItem("url", window.location.href);
      setLoginWallVisible(true);
      ga4.ga('send', { 'hitType': 'pageview', 'page': 'competitions/regorlogin', 'title': 'To Reg. or Login popup' });
    }
  }

  return (
    <>
      <Card className={className} style={{ ...styles.root, ...style }}>
        <div style={styles.title}>Event schedule</div>
        <div
          className='schedule-scroll'
          style={styles.scrollView}
        >
          <Timeline style={{ marginLeft: 9, marginTop: 9, marginRight: 9 }}>
            {schedules.map((item, i) => (
              <Timeline.Item key={item.id} dot={<ScheduleDot index={i + 1} size={26} fontSize={8} />}>
                <ScheduleItem title={item.description} date={formatDate(item.date, timezone, 'YYYY.MM.DD HH:mm')} />
              </Timeline.Item>
            ))}
          </Timeline>
        </div>
        {(!user() || applyButtonVisible) && (
          <div style={styles.btnContainer}>
            <Button style={styles.btn} type='primary' size='large' onClick={() => checkProfile()}>{leadButtonText}</Button>
          </div>
        )}
        {withdrawButtonVisible && (
          <div style={styles.btnContainer}>
            <div style={styles.attendingText}>You are attending</div>
          </div>
        )}
      </Card>
      <LoginWallModal
        title='Log in or Sign up!'
        description='For applying, first, you need to log in. If you do not have a Pozi account yet, feel free to sign up! The Pozi Team '
        visible={loginWallVisible}
        onCancel={() => setLoginWallVisible(false)}
      />
    </>
  )
};

export interface IScheduleItem {
  id: string;
  date: string;
  description: string;
}

interface Props {
  schedules: IScheduleItem[];
  leadButtonText: string;
  style?: CSSProperties;
  className?: string;
  timezone: string;
  onApply: () => void;
  withdrawButtonVisible: boolean;
  applyButtonVisible: boolean;

}

ScheduleCard.defaultProps = {
  style: {},
  className: '',
};

export default ScheduleCard;
