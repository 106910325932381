import { Carousel, Input, message, Modal } from "antd";
import React, { useEffect, useState } from "react";
import Masonry from "react-masonry-css";
import { get, post } from "../../libs/utils/request";
import { user } from "../../libs/utils/user";
import { EventCard, HomePageEventCard } from "../CardView/ProjectCard";
import RegisterWallModal from "../common/RegiserWallModal";
import Slider from "../Sales/Slider";
import TestimonialCard from "../Sales/TestimonialCard";
import "./HomePage.css";
import { FrontendDataResponse } from "./IndexPage2";

const dataForSlider = [
  {
    img: "/images/index_page/first-image.png",
    title: "A place to grow your startup and your future",
    description: "Free and open platform for startups and innovators.",
    button: user() ? "Show the dashboard" : "Register now",
  },
  {
    img: "/images/index_page/first-image.png",
    title: "A place to grow your startup and your future",
    description: "Free and open platform for startups and innovators.",
    button: user() ? "Show the dashboard" : "Register now",
  },
];

const carouselResponsiveSettings = [
  {
    breakpoint: 1850,
    settings: {
      slidesToShow: 1,
      slidesToScroll: 1,
      infinite: true,
    },
  },
  {
    breakpoint: 1625,
    settings: {
      slidesToShow: 1,
      slidesToScroll: 1,
      infinite: true,
    },
  },
  {
    breakpoint: 1422,
    settings: {
      slidesToShow: 1,
      slidesToScroll: 1,
      infinite: true,
    },
  },
  {
    breakpoint: 1219,
    settings: {
      slidesToShow: 1,
      slidesToScroll: 1,
      infinite: true,
    },
  },
  {
    breakpoint: 1166,
    settings: {
      slidesToShow: 1,
      slidesToScroll: 1,
      infinite: true,
    },
  },
  {
    breakpoint: 1016,
    settings: {
      slidesToShow: 1,
      slidesToScroll: 1,
      infinite: true,
    },
  },
  {
    breakpoint: 800,
    settings: {
      slidesToShow: 1,
      slidesToScroll: 1,
    },
  },
  {
    breakpoint: 600,
    settings: {
      slidesToShow: 1,
      slidesToScroll: 1,
    },
  },
  {
    breakpoint: 420,
    settings: {
      slidesToShow: 1,
      slidesToScroll: 1,
    },
  },
];
let enter: any;
let dateExpired = false;

const HomePage = () => {
  const [frontendData, setFrontendData] = useState<FrontendDataResponse>({
    numbers: {
      ideas: 0,
      projects: 0,
      users: 0,
    },
    latestNews: [],
    mentors: [],
    events: [],
    topLists: {
      ideas: [],
      mentors: [],
      projects: [],
    },
  });
  const [email, setEmail] = useState("");
  const [events, setEvents] = useState([]);
  const [showRegisterModal, setShowRegisterModal] = useState(false);
  // const [showSubscriptionModal, setShowSubscriptionModal] = useState(false);
  const [windowSize, setWindowSize] = useState(getWindowSize());

  useEffect(() => {
    function handleWindowResize() {
      setWindowSize(getWindowSize());
    }

    window.addEventListener("resize", handleWindowResize);

    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, []);

  // setTimeout(() => {
  //   if (!sessionStorage.getItem("subscriptionClosed")) {
  //     setShowSubscriptionModal(true);
  //   }
  // }, 1 * 6000);
  // // , 5*60000);

  useEffect(() => {
    (async () => {
      try {
        const data: FrontendDataResponse = await get("/frontpage/");

        const event = await get<{ data: any[]; pagination: any }>("events", {
          currentPage: 1,
          limit: 4,
        });
        setEvents(event.data);
        setFrontendData(data);
      } catch (error) {
        if (
          error?.response?.status === 403 &&
          error?.response?.data?.extendedUser
        ) {
          sessionStorage.setItem(
            "user",
            JSON.stringify(error.response.data.extendedUser)
          );
          if (
            !error?.response?.data?.extendedUser?.active &&
            !error?.response?.data?.extendedUser?.trial
          ) {
            window.location.replace("/trial-ended");
          }
        }
        message.error("Failed to fetch data.");
      }
    })();
  }, []);

  const sendSubscription = async () => {
    await post("/subscription", { email })
      .then((response) => {
        message.success(response, 5);
        let text = ((
          document.getElementById(
            "homepage-email-subscribe-input"
          ) as HTMLInputElement
        ).value = "");
        // sessionStorage.setItem("subscriptionClosed", "true");
        // setShowSubscriptionModal(false);
      })
      .catch((e) => {
        message.success("Subscribe failed", 5);
        message.error(e.response?.data?.message || "Network error");
      });
  };
  const getEvents = () => {
    async () => {
      try {
        const event = await get<{ data: any[]; pagination: any }>("events", {
          currentPage: 1,
          limit: 4,
        });
        setEvents(event.data);
      } catch (error) {
        if (
          error?.response?.status === 403 &&
          error?.response?.data?.extendedUser
        ) {
          sessionStorage.setItem(
            "user",
            JSON.stringify(error.response.data.extendedUser)
          );
          if (
            !error?.response?.data?.extendedUser?.active &&
            !error?.response?.data?.extendedUser?.trial
          ) {
            window.location.replace("/trial-ended");
          }
        }
        message.error("Failed to fetch data.");
      }
    };
  };

  const scrollTo = () => {
    var scrollDiv = document.getElementById("homepage-embed-video").offsetTop;
    window.scrollTo({ top: scrollDiv - 50, behavior: "smooth" });
  };

  function getWindowSize() {
    const { innerWidth, innerHeight } = window;
    return { innerWidth, innerHeight };
  }

  const CardView = () => {
    return dataForSlider.map((data) => (
      <div className="homepage-text-on-image-first">
        <img src={data.img} className="homepage-text-on-image-desktop" />
        <div>
          <div className="homepage-text-on-image-title">{data.title}</div>
          <div className="homepage-text-on-image-description">
            {data.description}
          </div>
          <button
            type="button"
            className="ant-btn ant-btn-primary home-page-first-box-button"
          >
            {user() ? (
              <div onClick={() => window.location.replace("/dashboard")}>
                {data.button}
              </div>
            ) : (
              <div onClick={() => setShowRegisterModal(true)}>
                {data.button}
              </div>
            )}
          </button>
        </div>
      </div>
    ));
  };

  return (
    <div className="home-page-main-container">
      <Carousel
        className="home-page-image-slider"
        slidesToShow={1}
        infinite={true}
        // autoplay
        // autoplaySpeed={2500}
        responsive={carouselResponsiveSettings}
      >
        {CardView()}
      </Carousel>

      <div className="home-page-counters-title">
        <div className="home-page-counters">
          <div>
            <img
              src="/images/index_page/users.png"
              className="home-page-counter-icon"
            />
            <span className="home-page-counters-number">
              {frontendData.numbers.users}
            </span>
            Users registered
          </div>
          <div className="homepage-counter-center-box">
            <img
              src="/images/index_page/ideas.png"
              className="home-page-counter-icon"
            />
            <span className="home-page-counters-number">
              {frontendData.numbers.ideas}
            </span>
            Ideas created
          </div>
          <div>
            <img
              src="/images/index_page/startups.png"
              className="home-page-counter-icon"
            />
            <span className="home-page-counters-number">
              {frontendData.numbers.projects}
            </span>
            Startups created
          </div>
        </div>
      </div>
      <div className="home-page-counters-title-box">
        <div className="home-page-counters-title-box-title">
          All - in - one platform to build your unicorn
        </div>
        <div className="home-page-counters-title-box-description">
          <div className="home-page-counters-title-box-text">
            Explore the startupversum with only a few clicks
          </div>
          <div
            className="home-page-how-it-works-container"
            onClick={() => {
              scrollTo();
            }}
          >
            <img src="/images/index_page/icon_play.png" alt="" />
            <p className="home-page-how-it-works">How it works?</p>
          </div>
        </div>
      </div>
      <div className="home-page-testimonials">
        <div className="home-page-testimonials-inner-box">
          <img
            src="/images/sales/upper.svg"
            className="testimonial-first-image"
          />
          <Masonry
            breakpointCols={{ default: 2, 850: 1 }}
            className="home-page-testimonials-masonry"
            columnClassName="testimonials-list-column"
          >
            <TestimonialCard
              proposer={{
                image: "/images/index_page/Ming.png",
                name: "Ming Xia Ho",
                position: "Investor",
                description:
                  "“Collaborating with POZI.io has been a blast! Not only is it a cool platform to bridge founders, their startup pitches, and investors; the team's passion also translates into the progress they are driving in the startup ecosystem, as they continue to build POZI! It has been a joy working alongside them to enable the startup community collectively. Thanks for inspiring!”",
                navigationLink: "/entrepreneurs/xia-ho",
              }}
              navigationTitle={"Find out more ->"}
            />
            <TestimonialCard
              proposer={{
                image: "/images/index_page/Noemi.png",
                name: "Noémi Marencsák",
                position: "Founder",
                description:
                  "“Pozi.io is something amazing. An integrated platform which provides all possibilities to startups, organisations, investors and anyone interested in this innovative field. From the idea stage to already set up companies Pozi.io helps you get known by organising quality competitions, discussions, meetings and gatherings. Being a part of it gets you closer to your dreams and goals.”",
                navigationLink: "/entrepreneurs/noemi-marencsak-1",
              }}
              navigationTitle={"Find out more ->"}
            />
            <TestimonialCard
              proposer={{
                image: "/images/index_page/Mark.png",
                name: "Mark Engelhardt",
                position: "Founder",
                description:
                  "“We did our first ever web3 pitch competition on Pozi.io's platform. Creating a profile and applying to the competition was pretty straightforward and thanks to the platform's notification and messaging abilities we never missed a piece of information for the event. When filling out your profile, they ask the right questions that would be important for not only anyone looking at your startup, but also for yourself to really think about how to explain what you do in the shortest form possible. Fantastic events, great UX, I'm a returning user. :)”",
                navigationLink: "/entrepreneurs/mark-engelhardt",
              }}
              navigationTitle={"Find out more ->"}
            />
            <TestimonialCard
              proposer={{
                image: "/images/index_page/Zsolt.png",
                name: "Zsolt Oleg Berta",
                position: "Advisor",
                description:
                  "As a jury member of the DEMO day I appreciate POZI’s professionalism and commitment to build a the startup ecosystem, gather the investors, consultants and startup founders, facilitate and support the development of the projects.”",
                navigationLink: "/entrepreneurs/zsolt-berta",
              }}
              navigationTitle={"Find out more ->"}
            />
          </Masonry>
          <img
            src="/images/sales/bottom.svg"
            className="testimonial-second-image"
          />
        </div>
      </div>
      <div className="home-page-helping-startups-container">
        <div className="home-page-helping-startups-title">
          Our goal is to support startups all around the world
        </div>
        <div className="home-page-helping-startups-desc">
          If you ever thinking to find a place where you can easily connect and
          find what you are looking for: a startup you can support, run an
          innovation, win on a global event, join to an incubator and find THE
          investor.
        </div>
      </div>
      <div className="home-page-join-startup-section" id="homepage-embed-video">
        <div className="home-page-join-startup-section-one">
          <div className="homepage-embed-video" id="homepage-embed-video">
            <iframe
              src="https://www.youtube.com/embed/yWwAoXXrBII"
              title="YouTube video player"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            ></iframe>
          </div>
        </div>
        <div className="home-page-join-startup-section-two">
          <a href="/blog" target="_blank">
            <span className="home-page-join-startup-section-two-title">
              Explore the startupversum with only a few clicks - Take a tour
              <br />
              <br />
            </span>
            <span className="home-page-join-startup-section-two-description">
              <b>As a Startup:</b> Expand your team, meet the investor you've
              been looking for a long time. Challenge yourself with
              international mentors, make contact, ask questions to get
              inspiring feedback on your Startup, and apply to the biggest
              competitions easily.
              <br />
              <b>As a mentor:</b> Join events, rate startups, and become a
              trustful and ranked mentor. It’s up to you how you develop your
              reputation on our platform. This is the place where you can work
              and expand your network easily and globally. <br />
            </span>
            <div style={{ marginTop: "20px" }}>Find out more -{">"}</div>
          </a>
        </div>
      </div>
      <div className="homepage-text-on-image-hubs">
        <img
          src="/images/index_page/for-hubs.png"
          className="homepage-text-on-image-desktop"
        />
        <img
          src="/images/sales/startup_world_cup_pozi_mobil.png"
          className="homepage-text-on-image-mobile"
        />
        <div className="homepage-text-on-image-second">
          <div className="homepage-text-on-image-title-for-hubs">
            Incubators, hubs, universities
          </div>
          <div className="homepage-text-on-image-description-for-hubs">
            <div>
              We want you to consider us as an extension of your hands. With our
              platform:
            </div>
            <p>🔥 Reach your target audience fast and easy</p>
            <p>🔥Create events and competitions</p>
            <p>🔥 Scout, Sort and Select the best teams</p>
            <p>🔥 Build and manage your portfolio</p>
            <p>🔥 Invite mentors, advisors or investors</p>
          </div>
          <button
            type="button"
            className="ant-btn ant-btn-primary home-page-first-box-button"
          >
            <a href="/packages/organizations" target="_blank">
              Learn more
            </a>
          </button>
        </div>
      </div>
      <div className="home-page-events-title">
        Building a stronger and valuable community together
      </div>
      <div className="home-page-best-things">
        <div className="home-page-best-things-sections">
          <p className="home-page-best-things-sections-image-holder">
            <img
              src="/images/index_page/projects.png"
              className="home-page-best-things-image"
            />
          </p>
          <p className="home-page-best-things-title">Top startups</p>
          <p className="home-page-best-things-description">
            Check out these top One-Pagers, ask for & give feedback to the
            founders while you are becoming a valuable member of this ecosystem.
          </p>
          <div>
            {frontendData.topLists.projects.map((project) => (
              <div className="home-page-best-things-list">
                <a href={"/startups/" + project.uniqueTag}>{project.name}</a>
              </div>
            ))}
          </div>
          <a href="/startups">
            <p className="home-page-best-things-navigate">View all {"->"}</p>
          </a>
        </div>
        <div className="home-page-best-things-sections home-page-best-things-sections-middle">
          <p className="home-page-best-things-sections-image-holder">
            <img
              src="/images/index_page/ideaMentor.png"
              className="home-page-best-things-image"
            />
          </p>
          <p className="home-page-best-things-title">Best ideas</p>
          <p className="home-page-best-things-description">
            Let's drop a look at these ideas! And brainstorm together for
            handling challenges from the whole world. Then evaluate it!
          </p>
          <div>
            {frontendData.topLists.ideas.map((idea) => (
              <div className="home-page-best-things-list">
                <a href={"/idea/" + idea.uniqueTag}>{idea.name}</a>
              </div>
            ))}
          </div>
          <a href="/ideas">
            <p className="home-page-best-things-navigate">View all {"->"}</p>
          </a>
        </div>
        {/* <div className="home-page-best-things-sections">
          <p className="home-page-best-things-sections-image-holder">
            <img
              src="/images/index_page/ideaMentor.png"
              className="home-page-best-things-image"
            />
          </p>
          <p className="home-page-best-things-title">Mentors</p>
          <p className="home-page-best-things-description">
            Let's drop a look at these ideas! And brainstorm together for
            handling challenges from the whole world. Then evaluate it!{" "}
          </p>
          <div>
            {frontendData.topLists.mentors.map((mentor) => (
              <div className="home-page-best-things-list">
                <a href={mentor.uniqueTag}>
                  {mentor.firstName + " " + mentor.lastName}
                </a>
              </div>
            ))}
          </div>
          <a href="/entrepreneurs">
            <p className="home-page-best-things-navigate"> View all {"->"}</p>
          </a>
        </div> */}
        <img
          src="/images/index_page/rocket.svg"
          className="home-page-rocket-image"
        />
      </div>
      <div className="home-page-events">
        <div className="home-page-events-title">
          Stay tuned for our next events
        </div>
        <div className="home-page-events-cards">
          {events.map((event) => (
            <HomePageEventCard event={event} onChange={() => getEvents()} />
          ))}
        </div>
      </div>
      <div className="home-page-helping-startups-container">
        <div className="home-page-helping-startups-title home-page-helping-startups-title-with-padding">
          Access tools to grow your skills and accelerate the growth of your
          startup
        </div>
        <div className="home-page-helping-startups-desc">
          Keep your knowledge alive and develop your ideas and your team. You
          can prepare with dedicated mentors for the competitions, and share and
          discuss ideas with the insiders help to complete your pitch and
          profile.
        </div>
      </div>
      <div className="homepage-text-on-image home-page-try-for-free">
        <img
          src="/images/index_page/try-for-free.png"
          className="homepage-text-on-image-desktop-two"
        />
        <div>
          <div className="homepage-text-on-image-title-try-for-free">
            Try POZI.io for free!
          </div>

          <button
            type="button"
            className="ant-btn ant-btn-primary home-page-first-box-button"
          >
            {user() ? (
              <div onClick={() => window.location.replace("/dashboard")}>
                Show the dashboard
              </div>
            ) : (
              <div onClick={() => setShowRegisterModal(true)}>Register now</div>
            )}
          </button>
        </div>
      </div>
      {/* <div className="home-page-learn-more-section">
        <div className="home-page-learn-more-section-one">
          <img src="/images/index_page/team.png" />
        </div>
        <div className="home-page-learn-more-section-two">
          <span className="home-page-learn-more-section-two-title">
            Learn more about our tools:
            <br />
          </span>
          <ul className="home-page-learn-more-section-two-description">
            <b>GUIDED:</b>
            <br />
            <li>
              Pozi aims to <b>encompass all elements</b> of a successful startup
              lifeline.
            </li>
            <b>COMPLEXITY</b>
            <br />
            <li>
              From idea generation to seed funding, feasibility and viability
              check, to scale-up.{" "}
            </li>
            <li>
              Our goal is to connect, and build bridges between the startup
              ecosystems.
            </li>
            <li>
              Our team built POZI.io during the pandemic. The online community
              offered opportunities to connect at a time when the startup
              ecosystem and networking around the world were paralyzed.
            </li>
            <li>
              Our team is also working to find ways to fund startups with an{" "}
              <b>NFT-based crowdfunding solution through WEB3</b>. That's why we
              created POZI.cards.
            </li>
            <li>
              We would love to learn more about you, email us at{" "}
              <b>hello@pozi.io</b>
            </li>
          </ul>
          <div>Read more -{">"}</div>
        </div>
      </div> */}
      <div className="sales-slider">
        <div className="partners-of-pozi-title">Partners of Pozi</div>
        <Slider />
      </div>
      <div className="home-page-newsletter-container">
        <div className="home-page-newsletter-box">
          <div className="home-page-newsletter-box-header">
            <p className="home-page-newsletter-box-header-title">
              Are you ready for a new challenge?
            </p>
            <span className="home-page-newsletter-box-header-description">
              Join our newsletter and start your week with a dose of startup
              news, trends, hottest investors, competitions. Stay updated.
            </span>
          </div>
          <div className="home-page-newsletter-box-body">
            <span className="home-page-newsletter-box-body-email-container">
              <p className="home-page-newsletter-box-body-title">
                Email address
              </p>
              <input
                id="homepage-email-subscribe-input"
                onChange={(e) => setEmail(e.target.value)}
                type="text"
                placeholder="Enter your email address"
              />
            </span>
            <span>
              <button
                type="button"
                className="ant-btn ant-btn-primary home-page-first-box-button"
                onClick={() => sendSubscription()}
              >
                Subscribe
              </button>
            </span>
          </div>
        </div>
      </div>
      <RegisterWallModal
        onCancel={() => setShowRegisterModal(false)}
        visible={showRegisterModal}
        title="Sign up for Pozi"
      />
      {/* {showSubscriptionModal && (
        <p>
          <Modal
            className="registration-modal-view"
            visible={true}
            destroyOnClose
            footer={null}
            width={"min-content"}
            onCancel={() => {
              setShowSubscriptionModal(false);
              sessionStorage.setItem("subscriptionClosed", "true");
            }}
            closeIcon={
              <img
                src="/images/popup/cross.png"
                className="subscription-popup-close-icon"
              />
            }
          >
            <div className="home-page-popup-container">
              <div className="homepage-popup-image-container">
                <img src="/images/popup/Turcsan.png" />
              </div>
              <div className="homepage-description-container">
                <div className="homepage-description-container-title">
                  Choose Wisely!
                </div>
                <div className="homepage-description-container-description">
                  Start your week with a fresh dose of startup news, updates,
                  hints & tips served by our lovely team.
                  <br /> <br />
                  It’s time for you to be up-to-date. Subscribe to our
                  newsletter:
                </div>
                <div className="homepage-description-container-input">
                  {windowSize.innerWidth > 590 ? (
                    <Input.Group compact>
                      <Input
                        style={{
                          width: "calc(100% - 150px)",
                          borderTopLeftRadius: "13px",
                          borderBottomLeftRadius: "13px",
                        }}
                        placeholder="Email Address"
                      />
                      <button
                        type="button"
                        className="ant-btn ant-btn-primary"
                        onClick={() => sendSubscription()}
                      >
                        Subscribe
                      </button>
                    </Input.Group>
                  ) : (
                    <>
                      <Input placeholder="Email Address" />
                      <button
                        type="button"
                        className="ant-btn ant-btn-primary"
                        onClick={() => sendSubscription()}
                      >
                        Subscribe
                      </button>
                    </>
                  )}
                </div>
                <div className="homepage-description-container-link">
                  <a>
                    By subscribing to the newsletter, you accept our Privacy
                    Policy.
                  </a>
                </div>
                <div className="homepage-description-container-oneline">
                  Working together with Top Leaders and Organisations
                </div>
                <div className="homepage-description-container-logos">
                  <img src="/images/popup/nbx.png" />
                  <img src="/images/popup/Institute.png" />
                  <img src="/images/popup/Draper2.png" />
                  <img src="/images/popup/Draper.png" />
                  <img src="/images/popup/women.png" />
                  <img src="/images/popup/worldCup.png" />
                  <img src="/images/popup/TalCatran.png" />
                </div>
              </div>
            </div>
          </Modal>
        </p>
      )} */}
    </div>
  );
};

export default HomePage;
