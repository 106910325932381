import React from "react"
import "../OrganizationPage.css"

const OrganizationDetails=({organization}:any)=>(
  
    <div className="organizationpage-about-org">
                      {organization.isPartner && (
                        <div className="is-organization-partner organization-details-element">
                          <img src="/images/organizations/role.svg" /> Featured with Pozi.io
                        </div>
                      )}
                      {organization.location&&<div className="organization-details-element">
                        <img src="/images/organizations/place.svg" />{" "}
                            <span>{organization.location.country}{organization.location.city && (", " + organization.location.city)}</span>
                      </div>}
                      <div className="organization-details-element">
                        <img src="/images/organizations/user.svg" /> Followers: <a  className="is-organization-partner" href = {`/organizations/${organization.tag}/followers`}>{organization.followersCount}</a>
                      </div>
                      <div className="organization-details-element">
                        <img src="/images/user_info_icons/link.svg" /> Follower invitation link:  <a  className="is-organization-partner" href = {`/organizations/${organization.tag}?action=follow`}> {`https://pozi.io/organizations/${organization.tag}?action=follow`}</a>
                      </div>
                    </div>
)

export default OrganizationDetails;