import React, { useState, useEffect } from "react";
import { Checkbox, Button, DatePicker } from "antd";
import ga4 from "react-ga4";
import { WrappedFormUtils } from "antd/lib/form/Form";
const cities = require("../../../libs/cities/cities.json");
import ImageUploader from "../../common/ImageUploader";
import { LocationSelector2 } from "../../common/form/LocationSelector";
import { Formik } from "formik";
import FormikInput from "../../common/form/FormikInput";
import FormikTextArea from "../../common/form/FormikTextArea";
import SocialLinks from "./SocialLinks";
import _, { after, indexOf } from "lodash";
import moment from "moment";
import RegistrationButtons from "./RegistrationButtons";
import { get, patch } from "../../../libs/utils/request";
import User from "../../../user/User";
import { DATE_FORMAT } from "../../../constants";
import {
  DateTimePicker,
  FormDateTimePicker,
} from "../../common/form/DateTimePicker";

const formatTime = (value: string) => {
  if (value) {
    return moment(value);
  }
  return null;
};

const UserDetails = ({ next, back, submit, error }: Props) => {
  const [profileImage, setProfileImage] = useState("");
  const [coverImage, setCoverImage] = useState("");
  const [user, setUser] = useState(null);
  const [showAge, setShowAge] = useState(true);

  const [socialCount, setSocialCount] = useState(1);

  const [formValue, setFormValues] = useState({
    birthday: null,
    location: {
      placeId: "",
      description: "",
    },
    oneLiner: "",
    bio: "",
    socialLinks: [],
  });

  useEffect(() => {
    setUser(JSON.parse(sessionStorage.getItem("user")));
    ga4.ga('send', { 'hitType': 'pageview', 'page': 'registration/details', 'title': 'Registration details' });
  }, []);



  useEffect(() => {
    if (user) {
      let userLocation = "";
      if (user.location !== null) {
        userLocation += user.location?.country;
      }
      if (user.location !== null) {
        userLocation += ", " + user.location?.city;
      }
      let userSocial = [];
      if (user.socialLinks?.length !== 0) {
        for (let i = 0; i < user.socialLinks?.length; i++) {
          userSocial.push({
            type: user.socialLinks[i].type.name,
            url: user.socialLinks[i].url,
          });
        }
      }
      if (userSocial.length > 0) {
        setSocialCount(userSocial.length);
      } else setSocialCount(1);
      setFormValues({
        birthday: user.birthday ? formatTime(user.birthday) : null,
        location: {
          placeId: user.location ? user.location.placeId : "",
          description: user?.location?.description
            ? user?.location?.description
            : userLocation,
        },
        oneLiner: user.oneLiner ? user.oneLiner : "",
        bio: user.bio !== null ? user.bio : "",
        socialLinks: userSocial,
      });
      setShowAge(user.showAge);
      setProfileImage(user.profileImage);
      setCoverImage(user.coverImage);
    }
  }, [user]);

  const onSubmit = async (values: any, errrors: any) => {
    const userDetails = {
      ...values,
      profileImage: profileImage ? profileImage : "",
      coverImage: coverImage ? coverImage : "",
      showAge,
    };
    submit(userDetails);
  };

  const onProfileImageChange = (url: string) => {
    setProfileImage(url);
  };

  const onCoverImageChange = (url: string) => {
    setCoverImage(url);
  };

  const checkLink = (e: any) => {
    if (
      e.target.value.length > 7 &&
      !/^https?:\/\//.test(e.currentTarget.value)
    ) {
      e.currentTarget.value = "http://" + e.currentTarget.value;
    }
  };

  const locationDisplay = (object: any) => {
    if (object.location && object.location.description) {
      return object.location.description;
    }
    if (object && object.location && object.location.city) {
      return `${object.location.country}, ${object.location.city}`;
    }
    if (object && object.location && !object.location.city) {
      return object.location.country;
    }
    return "";
  };

  const deleteItem = (index: number, values: any) => {
    formValue.socialLinks.splice(index, 1);
    setSocialCount(socialCount - 1);
    if (formValue.socialLinks[index] === undefined) {
      values.socialLinks.splice(index, 1);
    }
  };
  // const checkInitialValue = () => (false ? userValues : formValue);
  const disabledDate = (current: any) => current.year() < 1920 || current.year() > 2017
  return (
    <div className="step step-userinfo">
      <div className="step-userinfo-form">
        <Formik
          enableReinitialize
          initialValues={formValue}
          onSubmit={(values, e) => {
            onSubmit(values, e);
          }}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            setFieldValue,
            validateField,
          }) => (
            <>
              <div className="register-column-container">
                <div className="register-column-firstpage">
                  <div className="register-column-field">
                    <h1 className="item-title-register">Birthdate</h1>
                    <DateTimePicker
                      name="birthday"
                      value={values.birthday}
                      onChange={(val: any) => {
                        setFieldValue("birthday", val);
                      }}
                      required={false}
                      withTime={false}
                      disabledDate={disabledDate}
                      defaultPickerValue={moment('19900101')}
                    />
                    <Checkbox
                      checked={showAge}
                      className="hide-birthday-register"
                      onChange={(e: any) => {
                        setShowAge(!showAge);
                        handleChange(e);
                      }}
                    >
                      I don’t want my birthday to be public
                    </Checkbox>
                  </div>
                  <div className="register-column-field">
                    <h1 className="item-title-register city-image">
                      Your city
                    </h1>
                    <img
                      className="powered-by-google-onepager-edit"
                      src="/images/google/powered_by_google.png"
                      alt="Powered by Google"
                    />
                    <LocationSelector2
                      suffixIcon={
                        <img
                          className="register-input-icon"
                          src="/images/user_info_icons/location.svg"
                          alt=""
                        />
                      }
                      placeholder={"Type your location"}
                      value={locationDisplay(values)}
                      onChange={(e: any) => {
                        console.log(e);
                        setFieldValue(
                          "location.placeId",
                          JSON.parse(e).placeId
                        );
                        setFieldValue(
                          "location.description",
                          JSON.parse(e).description
                        );
                      }}
                    />
                  </div>
                  <div className="oneliner-register">
                    <h1 className="item-title-register">One-liner</h1>
                    <FormikInput
                      type="text"
                      name="oneLiner"
                      value={values.oneLiner}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      placeholder="About you in one line"
                      prefix={
                        <img
                          className="register-input-icon"
                          src="/images/user_info_icons/actual_oneliner.svg"
                          alt=""
                        />
                      }
                      maxLength={140}
                    />
                  </div>
                  <div className="bio-register">
                    <h1 className="item-title-register">Bio - Short intro</h1>
                    <FormikTextArea
                      name="bio"
                      value={values.bio}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      placeholder="Example: Hi, I’m Nelson, but my friends call me Big Head. I’m a tech evangelist, I can help you with your demo, ‘cause I already watched the Facebook movie, A.I., The Net, and half of Tron. I also worked on projects like PiperChat and Nip Alert. And I was also on the cover of Wired. And actually inside too."
                      autoSize={{ minRows: 5, maxRows: 6 }}
                      prefix={
                        <img
                          className="register-input-icon"
                          src="/images/user_info_icons/bio.svg"
                          alt=""
                        />
                      }
                      maxLength={480}
                    />
                    <ul className="ul-register-bio">
                      <li>
                        Introduce yourself (name, nickname, hobbies, funfacts
                        etc.)
                      </li>
                      <li>Tell us about your work experiences</li>
                    </ul>
                  </div>
                  <h1 className="item-title-register">Social media</h1>
                  {_.times(socialCount, (i) => (
                    <>
                      <SocialLinks
                        type="string"
                        name={`socialLinks[${i}].url`}
                        value={values.socialLinks[i]?.url}
                        onChange={(e: any) => {
                          checkLink(e);
                          handleChange(e);
                        }}
                        onBlur={handleBlur}
                        dropName={`socialLinks[${i}].type`}
                        dropvalue={values.socialLinks[i]?.type}
                        deleteItem={() => {
                          deleteItem(i, values);
                        }}
                      />
                    </>
                  ))}
                  {socialCount < 5 && (
                    <div
                      className="more-social-register"
                      onClick={() => setSocialCount(socialCount + 1)}
                    >
                      <img
                        src="/images/user_info_icons/more_social.svg"
                        alt=""
                      />
                    </div>
                  )}
                </div>
                <div className="register-secondcolumn-firstpage">
                  <div className="uploader-with-text">
                    <span className="uploader-title">User profile picture</span>
                    <ImageUploader
                      className="logo-uploader"
                      uploadText="Upload profile picture recommended 230x230"
                      images={profileImage}
                      type="single"
                      displayAllowed={false}
                      onChange={(url: string) => {
                        onProfileImageChange(url);
                      }}
                      showCropper={true}
                      cropperData={{ width: 230, height: 230 }}
                    />
                  </div>
                  <div className="uploader-with-text">
                    <span className="uploader-title">User cover picture</span>
                    <ImageUploader
                      className="logo-uploader"
                      uploadText="Upload cover picture recommended 1300x300"
                      images={coverImage}
                      type="single"
                      displayAllowed={false}
                      onChange={(url: string) => {
                        onCoverImageChange(url);
                      }}
                      showCropper={true}
                      cropperData={{ width: 1300, height: 300 }}
                      cropType='cover'
                    />
                  </div>
                </div>
              </div>
              <div className="errors-frombe-onepager">{error}</div>
              <RegistrationButtons
                roleName="Step1(userDetails)"
                showBackbutton={false}
                showNextbutton={true}
                showSkipButton={true}
                next={next}
                back={back}
                save={() => {
                  onSubmit(values, errors);
                }}
              />
            </>
          )}
        </Formik>
      </div>
    </div>
  );
};

interface Props {
  next?: () => any;
  back?: () => any;
  save?: () => any;
  submit?: (details: any) => any;
  error: string;
}

export default UserDetails;
function setErrorResponse(details: any) {
  // throw new Error("Function not implemented.");
}
