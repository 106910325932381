import React, { CSSProperties } from 'react';
import { Button, Timeline , message} from 'antd';
import Card from '../../common/card/Card';
import '../Event/Event.css';
import ScheduleDot from '../../common/ScheduleDot';
import ScheduleItem from './ScheduleItem';
import { formatDate } from '../Event/Event';
import { user } from '../../../libs/utils/user';

const styles = {
  root: {
    paddingTop: 30,
    paddingBottom: 30,
    paddingLeft: 40,
    paddingRight: 40,
    borderRadius: 10,
  },
  title: {
    fontFamily: 'Inter',
    fontWeight: 800,
    fontStyle: 'normal',
    fontSize: 16,
    marginBottom: 16,
  },
  scrollView: {
    height: 180,
    overflow: 'scroll',
    overflowX: 'hidden' as const,
  },
  btnContainer: {
    marginTop: 20,
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  btn: { fontWeight: 'bold' as const, minWidth: 150 },
  attendingText: {
    fontFamily: 'Inter',
    fontWeight: 400,
    fontStyle: 'italic',
    fontSize: 16,
  },
};
const userFromStorage = sessionStorage.getItem('user')
const profileProgress =  userFromStorage ? JSON.parse(userFromStorage).progress : 0

const ScheduleCard = ({
  schedules, leadButtonText, style, className, timezone,
  onApply, applyButtonVisible, isRegistrationOpen, withdrawButtonVisible, needRedirect, redirectURL
}: Props) => (
  <Card className={className} style={{ ...styles.root, ...style }}>
    <div style={styles.title}>Event schedule</div>
    <div
      className='schedule-scroll'
      style={styles.scrollView}
    >
      <Timeline style={{ marginLeft: 9, marginTop: 9, marginRight: 9 }}>
        {schedules.map((item, i) => (
          <Timeline.Item key={item.id} dot={<ScheduleDot index={i + 1} size={26} fontSize={8} />}>
            <ScheduleItem title={item.description} date={formatDate(item.date, timezone, 'YYYY.MM.DD HH:mm')} />
          </Timeline.Item>
        ))}
      </Timeline>
    </div>
    {(!user() && !needRedirect && isRegistrationOpen) && (
      <div style={styles.btnContainer}>
        <Button style={styles.btn} type='primary' size='large' 
        onClick={()=> 
          onApply()
        }>Pozi Registration</Button>
      </div>
    )}
    {(!user() && needRedirect && isRegistrationOpen) && (
      <div style={styles.btnContainer}>
        <Button style={styles.btn} type='primary' size='large'>
          <a href={redirectURL} target="_blank" >{leadButtonText}</a>
        </Button>
      </div>
    )}
    {(user() && isRegistrationOpen && needRedirect) && (
      <div style={styles.btnContainer}>
        <Button style={styles.btn} type='primary' size='large'>
          <a href={redirectURL} target="_blank" >{leadButtonText}</a>
        </Button>
      </div>
    )}
    {(user() && isRegistrationOpen && !needRedirect) && (
      <div style={styles.btnContainer}>
        <Button style={styles.btn} type='primary' size='large'
        onClick={()=> 
          onApply()
        }>{leadButtonText}</Button>
      </div>
    )}
    {withdrawButtonVisible && (
      <div style={styles.btnContainer}>
        <div style={styles.attendingText}>You are attending</div>
      </div>
    )}
  </Card>
);

export interface IScheduleItem {
  id: string;
  date: string;
  description: string;
}

interface Props {
  schedules: IScheduleItem[];
  leadButtonText: string;
  style?: CSSProperties;
  className?: string;
  timezone: string;
  onApply: () => void;
  applyButtonVisible: boolean;
  withdrawButtonVisible: boolean;
  isRegistrationOpen: boolean;
  needRedirect?: boolean;
  redirectURL?: string;
}

ScheduleCard.defaultProps = {
  style: {},
  className: '',
};

export default ScheduleCard;
