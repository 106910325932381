import { Helmet } from 'react-helmet';
import { message } from 'antd';
import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router'
import { get } from '../../libs/utils/request';
import { Loading } from '../common/Loading'
import { NewsCard } from './NewsCard';


export const NewsCardPage = () => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState(null);

  const { id }: any = useParams();
  useEffect(() => {
    if (id) {
      load(id)
    }
  }, [id])

  const load = async (id: any) => {
    setLoading(true)
    try {
      const data = await get('/news/get/' + id);
      setData(data);
      setLoading(false)
    } catch (e) {
      message.error('This post does not exist!')
      setLoading(false)
    }
  }


  if (loading === true) {
    return <Loading />
  }

  if (data === null) {
    return null
  }
  console.log('data:: ', data);
  return <div style={{
    width: '90vw',
    maxWidth: '550px',
    margin: '64px auto'
  }}>
    <Helmet>
      <meta property="og:image" content={`${data.linkedImage?.url || "/images/profile-background.png"
        }`} />
    </Helmet>

    <NewsCard
      data={data}
      onUpdate={(newData: any) => {
        const tmpdata: any = data
        Object.keys(newData).forEach(key => {
          tmpdata[key] = newData[key]
        })
        setData(tmpdata);
      }}
      onDeleted={() => {
        setData(null)
      }}
    />
  </div>
}



