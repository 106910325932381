import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router';
import ga4 from "react-ga4";
import { message } from 'antd';
import moment from 'moment-timezone';
import { Helmet } from 'react-helmet';
import { SubPageEnum } from '../components/SubPageEnum';
import Header from '../components/Header';
import { get, post, deleteReq } from '../../../libs/utils/request';
import Summary from '../components/Summary';
import Participants from '../components/Participants';
import { Loading } from '../../common/Loading';
import { user } from '../../../libs/utils/user';
import MessageModal from '../components/MessageModal';
import Feed from '../components/Feed';
import ConfirmationDialog from '../../common/ConfirmationDialog';
import LoginWallModal from '../../common/LoginWallModal';
import GenerateSummaryModal from '../components/GenerateSummaryModal';
import ApplicationModal from '../components/ApplicationModal';
import ReactPixel from 'react-facebook-pixel';

const styles = {
  root: {
    flex: 1,
    display: 'flex',
    justifyContent: 'center',
    paddingLeft: 20,
    paddingRight: 20,
    marginBottom: 100,
  },
  notImplementedText: {
    fontSize: 20,
    fontWeight: 'bold' as const,
    textAlign: 'center' as const,
    marginTop: 72,
    color: '#282828',
  },
  errorText: {
    fontSize: 20,
    marginTop: 72,
    textAlign: 'center' as const,
    fontWeight: 600,
    color: '#F00',
  },
  errorButtonText: {
    fontSize: 16,
    marginTop: 10,
    textAlign: 'center' as const,
    fontWeight: 400,
    textDecoration: 'underline',
    cursor: 'pointer',
    color: '#6755CE',
  },
  content: { width: 1300 },
};

export const formatDate = (
  date: string,
  timezone: string,
  format: string,
): string => moment(date).tz(timezone).format(format);

export const havePermission = (target: string[], userRoles: string[]) => {
  if (!target.length) {
    return !userRoles.length;
  }
  for (let i = 0; i < target.length; i += 1) {
    if (userRoles.includes(target[i])) {
      return true;
    }
  }
  return false;
};

const Event = () => {
  const [event, setEvent] = useState(null);
  const [canJoin, setJoinPermission] = useState(false);
  const [userRoles, setUserRoles] = useState([]);
  const [isLoading, setLoading] = useState(true);
  const [errorMessage, setErrorMessage] = useState('');
  const [applicationModalVisible, setApplicationModalVisible] = useState(false);
  const [loginWallVisible, setLoginWallVisible] = useState(false);
  const [messageModalVisible, setMessageModalVisible] = useState(false);
  const [applyButtonVisible, setApplyButtonVisible] = useState(false);
  const [withdrawButtonVisible, setWithdrawButtonVisible] = useState(false);
  const [withdrawDialogVisible, setWithdrawDialogVisible] = useState(false);
  const [isTeamMember, setTeamMember] = useState(true);
  const [downloadModalVisible, setDownloadModalVisible] = useState(false);
  const [isPinned, setIsPinned] = useState(false);

  const { tag, subPage } = useParams();
  const history = useHistory();

  useEffect(() => {
    ReactPixel.fbq('track', 'ViewContent');
  })

  useEffect(() => {
    if (subPage) {
      if (!Object.values(SubPageEnum).includes(subPage)) {
        history.push(`/events/${tag}`);
      }
    }
  }, [history, tag, subPage]);

  useEffect(() => {
    (async () => {
      try {
        setErrorMessage('');
        setLoading(true);
        const event: IEvent = await get<IEvent>(
          `/events/${tag}`,
        );
        setJoinPermission(event.canJoin);

        setEvent({
          ...event,
          interests: event.interests.map((item) => ({
            id: item.id,
            name: item.name,
            icon: item.group.icon,
          })),
        });
        if (user()) {
          const roles: { roles: string[]; isTeamMember: boolean } = await get<{
            roles: string[];
            isTeamMember: boolean;
          }>(`/events/${tag}/my-roles`);
          setUserRoles(roles.roles);
          setTeamMember(roles.isTeamMember);
        }
      } catch (error) {
        if (error.response.status === 404) {
          message.error('We are sorry. This content might be deleted by the owners.', 5)
          history.push('/events')
        }
        if (error.response?.data?.message) {
          setErrorMessage(error.response.data.message);
        } else {
          setErrorMessage(error.message);
        }
      } finally {
        setLoading(false);
      }
    })();
  }, [tag]);

  useEffect(() => {
    window.onpopstate = () => {
      if (applicationModalVisible) {
        setApplicationModalVisible(false);
      }
    };
    return () => {
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      window.onpopstate = () => { };
    };
  }, [applicationModalVisible]);
  useEffect(() => {
    if (event !== null) {
      if (localStorage.url === `${window.location.origin}/events/${tag}` ||
        localStorage.url === `${window.location.origin}/dashboard`
      ) {
        localStorage.removeItem('url')
        if (event.canJoin && JSON.parse(sessionStorage.getItem('user')).progress >= 50) {
          setApplicationModalVisible(true);
        }
      }
    }
  }, [event])

  useEffect(() => {
    if (event !== null) {
      setJoinPermission(
        event.canJoin,
      );
    }

  }, [event]);

  useEffect(() => {
    setWithdrawButtonVisible(
      user()
      && havePermission(['Participant', 'Participant Pending'], userRoles),
    );
  }, [userRoles]);

  const getEvent = async (withLoading = true) => {
    try {
      setErrorMessage('');
      if (withLoading) {
        setLoading(true);
      }
      const event: IEvent = await get<IEvent>(
        `/events/${tag}`,
      );

      setEvent({
        ...event,
        interests: event.interests.map((item) => ({
          id: item.id,
          name: item.name,
          icon: item.group.icon,
        })),
      });
      const roles: { roles: string[]; isTeamMember: boolean } = await get<{
        roles: string[];
        isTeamMember: boolean;
      }>(`/events/${tag}/my-roles`);
      setUserRoles(roles.roles);
      setTeamMember(roles.isTeamMember);
    } catch (error) {
      if (error.response.status === 403 && error.response.data.extendedUser) {
        sessionStorage.setItem("user", JSON.stringify(error.response.data.extendedUser))
        if (!error.response.data.extendedUser.active && !error.response.data.extendedUser.trial) {
          window.location.replace('/trial-ended')
        }
      }
      if (error.response.status === 404) {
        message.error('We are sorry. This content might be deleted by the owners.', 5)
        history.push('/events')
      }
      if (error.response?.data?.message) {
        setErrorMessage(error.response.data.message);
      } else {
        setErrorMessage(error.message);
      }
    } finally {
      setLoading(false);
    }
  };

  const getOrganizers = (): IUser[] => {
    if (event) {
      return [event.createdBy].concat(
        event.coorganizers.map((item: ISomeone) => item.user),
      );
    }
    return [];
  };

  const handleApplicationSubmit = async () => {
    setApplicationModalVisible(false);
    await getEvent();
    message.success('Application Succeeded.');
    ga4.ga('send', { 'hitType': 'pageview', 'page': 'events/success', 'title': 'Applied for event' });
  };

  const handleApply = () => {
    if (user()) {
      setApplicationModalVisible(true);
    } else {
      if (!event.needRedirect)
        localStorage.setItem('url', window.location.href);
      setLoginWallVisible(true);
      ga4.ga('send', { 'hitType': 'pageview', 'page': 'events/regorlogin', 'title': 'To Reg. or Login popup' });
    }
  };

  const handleWithdraw = async () => {
    if (
      user()
      && havePermission(['Participant', 'Participant Pending'], userRoles)
    ) {
      try {
        await deleteReq(`/events/${tag}/apply`);
        await getEvent();
        message.success('Withdraw succeed.');
      } catch (error) {
        message.error('Failed to withdraw application.');
      }
    }
  };
  const handlePin = async () => {
    try {
      setIsPinned(!event.isPinned);
      post(`/events/${tag}/pin`).then(() => {
        getEvent()
      })
    } catch (err) {
      console.log(err);
    }
  };

  const renderSection = () => {
    // const notImplemented = (text: string) => <div style={styles.notImplementedText}>{text}</div>;
    const defaultPage = (
      <Summary
        event={event}
        onApply={handleApply}
        applyButtonVisible={canJoin}
        onWithdraw={() => setWithdrawDialogVisible(true)}
        withdrawButtonVisible={withdrawButtonVisible}
        youtubeVideo={youtubeVideo}
        vimeoVideo={vimeoVideo}
        vimeoEmbed={vimeoEmbed}
        fbVideo={fbVideo}
      />
    );
    const fallback = () => {
      history.push(`/events/${tag}`);
      return defaultPage;
    };
    switch (subPage) {
      case SubPageEnum.participants:
        if (user() && !event.needRedirect) {
          return <Participants tag={event.tag} userRoles={userRoles} />;
        }
        return fallback();

      case SubPageEnum.feed:
        if (user()) {
          return <Feed event={event} tag={event.tag} userRoles={userRoles} />;
        }
        return fallback();

      default:
        return defaultPage;
    }
  };

  if (isLoading) {
    return <Loading />;
  }

  const youtubeVideo = event.videoType === "youtube" && event.videoId ? event.videoId : "";
  const vimeoVideo = event.videoType === "vimeo" && event.videoId ? event.videoId : "";
  const vimeoEmbed = event.videoType === "iframe" && event.videoUrl ? event.videoUrl : "";
  const fbVideo = event.videoType === "facebook" && event.videoUrl ? event.videoUrl : "";

  return (
    <div style={styles.root}>
      <Helmet>
        <title>{`${event.name} | event | pozi.io`}</title>
      </Helmet>
      <div style={styles.content}>
        {errorMessage ? (
          <>
            <div style={styles.errorText}>Failed to load event:</div>
            <div style={{ ...styles.errorText, marginTop: 0, fontWeight: 400 }}>
              {errorMessage}
            </div>
            <div
              style={styles.errorButtonText}
              onClick={() => getEvent()}
              aria-hidden='true'
            >
              retry
            </div>
          </>
        ) : (
          <>
            <Header
              event={event}
              userRoles={userRoles}
              onApply={handleApply}
              applyButtonVisible={canJoin}
              onWithdraw={() => setWithdrawDialogVisible(true)}
              withdrawButtonVisible={withdrawButtonVisible}
              onMessage={() => setMessageModalVisible(true)}
              onGenerateSummary={() => setDownloadModalVisible(true)}
              onAddPin={handlePin}
              pin={event.isPinned}
              isFree={event.isFree}
              fee={event.fee}
            />
            {renderSection()}
            <ApplicationModal
              tag={event.tag}
              visible={applicationModalVisible}
              onSubmitApplication={() => handleApplicationSubmit()}
              onCancel={() => {
                setApplicationModalVisible(false);
              }}
              isPreregistration={event.isPreregistration}
            />

            <LoginWallModal
              title='Log in or Sign up!'
              description='For applying to this event, first, you need to log in. If you do not have a Pozi account yet, feel free to sign up! The Pozi Team'
              visible={loginWallVisible}
              onCancel={() => setLoginWallVisible(false)}
            />
            <MessageModal
              visible={messageModalVisible}
              users={getOrganizers()}
              onCancel={() => setMessageModalVisible(false)}
              eventName={event.name}
              eventType="Event"
            />
            <ConfirmationDialog
              visible={withdrawDialogVisible}
              onCancel={() => setWithdrawDialogVisible(false)}
              onOk={() => {
                setWithdrawDialogVisible(false);
                handleWithdraw();
              }}
              title='Withdraw application'
              description='Are you sure you want to withdraw your application?'
              confirmBtnText='Withdraw'
              confirmBtnType='danger'
            />
            <GenerateSummaryModal
              tag={event.tag}
              visible={downloadModalVisible}
              onCancel={() => setDownloadModalVisible(false)}
            />
          </>
        )}
      </div>
    </div>
  );
};

export interface ILocation {
  id: string;
  streetNumber: string;
  streetName: string;
  city: string;
  country: string;
  state: string;
  postalCode: string;
  placeId: string;
}

interface IInterest {
  id: string;
  name: string;
  icon: string;
  group: any;
}

export interface IPartner {
  id: string;
  name: string;
  partnerUrl: string;
  image: IPartnerImage;
}

export interface IInterestGroup {
  id: string;
  name: string;
  icon: string;
}

interface IRole {
  name: string;
}

export interface IUser {
  id: string;
  firstName: string;
  lastName: string;
  profileImage: string;
  oneLiner: string;
  role: IRole;
  city: string;
  location: ILocation;
  uniqueTag: string;
}

export interface ISomeone {
  id: string;
  user: IUser;
}

interface IPartnerImage {
  filename: string;
}
export interface IEvent {
  redirectURL?: string;
  needRedirect: any;
  isRegistrationOpen: any;
  isOrganization?: any;
  partnerOrganizations: any;
  newsGroup: any;
  organizer: any;
  organization: any;
  id: string;
  name: string;
  tag: string;
  image: string;
  schedules: any[];
  leadButtonText: string;
  city: string;
  country: string;
  deadline: string;
  timezone: string;
  durationFrom: string;
  durationTo: string;
  shortDescription: string;
  description: string;
  maxParticipant: number,
  isFree: string,
  fee: number,
  interestGroups: IInterestGroup[];
  speakers: ISomeone[];
  coorganizers: ISomeone[];
  partnerOrganizers: IPartner[];
  partners: IPartner[];
  isOnline: boolean;
  isPreregistration: boolean;
  interests: IInterest[];
  createdBy: IUser;
  createdAt: string;
  evaluationStatus: 'INIT' | 'OPEN' | 'CLOSED';
  location: ILocation;
  isPinned: boolean;
  canJoin: boolean;
  ratingGroup?: any;
  commentGroup?: any;
  shareCount?: number;
  userRating?: any;
  videoId: string;
  videoUrl: string;
  vimeoVideo: string;
  videoType: string;
  owner?: any;
}

export default Event;
