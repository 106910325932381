import React from "react";
import "./TestimonialCard.css"

const TestimonialCard = ({proposer, navigationTitle}: Props) =>{

    return(
        <div className="testimonial-card">
            <div className="testimonial-about">
                <div className="testimonial-title-part">
                    <img className="testimonial-image" src={proposer.image} />
                    <div>
                        <div className="testimonial-name">
                            {proposer.name}
                        </div>
                        <div className="testimonial-position">
                            {proposer.position}
                        </div>  
                    </div>
                </div>
                <div className="testimonial-description">
                    {proposer.description}
                </div>
            </div>
            <div className="testimonial-navigation">
                <a href={proposer.navigationLink} target="_blank">{navigationTitle}</a>
            </div>
        </div>
    )
}
interface Props {
    proposer: {
        image: string;
        name: string;
        position: string;
        description: string;
        navigationLink: string;
    }
    navigationTitle:string
}
export default TestimonialCard;