import React, { useEffect, useState } from 'react';
import { message, Button } from 'antd';
import { IStartup } from '../Competitions/Competition/components/Startups/Startups';
import { get, post } from '../../libs/utils/request';
import OwnerBox from '../Competitions/components/OwnerBox';
import { IIdea } from '../Competitions/Competition/components/Ideas/Ideas';
import {
  ICompetition,
  IMyProjects,
  IUser,
} from '../Competitions/Competition/Competition';
import Themes, { ITheme } from './components/Themes';
import AdditionalInfos from './components/AdditionalInfos';
import CardTitle from './components/CardTitle';
import Social from './components/Social';
import './ProjectCard.css';
import { IEvent } from '../Events/Event/Event';
import { user } from '../../libs/utils/user';
import ApplicationModal from '../Competitions/Competition/ApplicationModal';
import EventApplicationModal from '../Events/components/ApplicationModal';
import LoginWallModal from '../common/LoginWallModal';
import OrganizationDetails from '../Organization/components/OrganizationDetails';
import { IArticle } from '../Blog/Article';

const shareLinkBase = `${window.location.protocol}//${window.location.host}`;

const ProjectCard = ({
  id,
  image,
  name,
  subtitle,
  description,
  userData,
  createdAt,
  ratingCount,
  averageRating,
  commentsCount,
  shareLink,
  shareCount,
  commentPostUrl,
  commentGetUrl,
  ratingUrl,
  userRating,
  onChange,
  themes,
  pitchLink,
  commentable,
  additionalInfo,
  canJoin,
  type,
  canLeave,
  canWithdraw,
  city,
  country,
  fee,
  deadline,
  ratingGroupId,
  leadButtonText,
  tag,
  isPreregistration,
  competition,
  organization,
  isFollowing,
  canFollow,
  ownerBoxTitle,
  organizer,
  owner,
  needRedirect,
  redirectURL,
  isPinned
}: Props) => {
  const [applicationModalVisible, setApplicationModalVisible] = useState(false);
  const [loginWallVisible, setLoginWallVisible] = useState(false);
  const [myProjects, setMyProjects] = useState({ startups: [], ideas: [] });
  const [applyButtonVisible, setApplyButtonVisible] = useState(false);
  const [userRoles, setUserRoles] = useState([]);
  const userFromStorage = sessionStorage.getItem('user')
  let profileProgress =  userFromStorage ? JSON.parse(userFromStorage).progress : 0
  const onRateChange = async (rating: number) => {
    try {
      if (ratingGroupId) {
        await post('/rating/post', { rate: rating, id: ratingGroupId });
      } else {
        await post(ratingUrl, { rating });
      }
      onChange();
    } catch (err) {
      if(err.response.status === 403){
        message.warning(`${profileProgress}% of your profile is completed. To rate you need to get at least 70%`)
      }
      else{
       message.error('Rating failed');
      }
    }
  };

  const handleApply = () => {
    if (user()) {
      setApplicationModalVisible(true);
    } else {
      localStorage.setItem('url', `${window.location.href}/${tag}`);
      setLoginWallVisible(true);
    }
  };

  const getMyProjects = async () => {
    const myProjectsTmp = await get<IMyProjects>(
      `/competitions/${tag}/my-projects`,
    );
    setMyProjects(myProjectsTmp);
  };

  const handleApplicationSubmit = async () => {
    setApplicationModalVisible(false);
    message.success('Application Succeeded.');
    onChange();
  };
  useEffect(() => {
    if (user() && type === 'competitions' && applicationModalVisible) {
      getMyProjects();
    }
  }, [applicationModalVisible]);

  useEffect(() => {
    async () => {
      try {
        if (user()) {
          const roles: { roles: string[] } = await get<{
            roles: string[];
          }>(`/events/${tag}/my-roles`);
          setUserRoles(roles.roles);
        }
      } catch (err) {
        // console.log(err);
      }
    };
    setApplyButtonVisible(canJoin);
  }, [competition, userRoles]);

  const checkProfile = async () => {
    if(user()){
      try {
        handleApply()
      }catch(err){
        console.log(err)
      }
  }else{
    setLoginWallVisible(true)
  }
  }
  return (
    <div className={isPinned ? 'card_pinned' : 'card'} aria-hidden='true'>
      <div
        className='header-img-holder'
        onClick={() => {
          window.open(shareLink, '_self');
        }}
      >
        {type === 'events' && (
          <div className='fee'>{fee > 0 ? `${fee} $` : 'Free'}</div>
        )}
        <img
          className='header-img'
          src={image || '/images/idea_placeholder.png'}
          alt=''
        />
      </div>
      <div className='content'>
        <CardTitle
          title={name}
          subTitle={subtitle}
          description={description}
          canJoin={canJoin}
          needRedirect={needRedirect}
          redirectURL={redirectURL}
          isFollowing={isFollowing}
          canFollow={canFollow}
          id={id}
          tag={tag}
          type={type}
          canWithdraw={canWithdraw}
          canLeave={canLeave}
          deadline={deadline}
          country={country}
          city={city}
          onChange={onChange}
          applyButtonVisible={applyButtonVisible}
          handleApply={() => type==="competitions" ? checkProfile() : handleApply()}
          leadButtonText={leadButtonText}
        />

        {(themes && themes.length) && <Themes themes={themes} />}

        {(!!pitchLink || !!additionalInfo) && (
          <AdditionalInfos
            additionalInfo={additionalInfo}
            pitchLink={pitchLink}
          />
        )}
        {type==='organization'&&<OrganizationDetails organization={organization} />}
        {type!=='organization'&&<>
        {type !== "homepage" && <OwnerBox owner={owner} organizer={organizer} createdAt={createdAt} ownerBoxTitle={ownerBoxTitle}/>}
        {type !== "homepage" && <Social
          averageRating={averageRating}
          ratingCount={ratingCount}
          commentGetUrl={commentGetUrl}
          commentPostUrl={commentPostUrl}
          commentable={commentable}
          commentsCount={commentsCount}
          onRateChange={onRateChange}
          userRating={userRating}
          shareLink={shareLink}
          ratingUrl={ratingUrl}
          shareCount={shareCount}
          type={type}
        />}</>}
      </div>
      {type === 'competitions'
        && (
          <ApplicationModal
            tag={tag}
            visible={applicationModalVisible}
            onSubmitApplication={() => handleApplicationSubmit()}
            onCancel={() => {
              setApplicationModalVisible(false);
            }}
            isPreregistration={isPreregistration}
            myProjects={myProjects}
          />
        )}
      {type === 'events'
        && (
          <EventApplicationModal
            tag={tag}
            visible={applicationModalVisible}
            onSubmitApplication={() => handleApplicationSubmit()}
            onCancel={() => setApplicationModalVisible(false)}
            key={id}
            isPreregistration={isPreregistration}
          />
        )}
      <LoginWallModal
        title='Log in or Sign up!'
        description='For applying, first, you need to log in. If you do not have a Pozi account yet, feel free to sign up! The Pozi Team '
        visible={loginWallVisible}
        onCancel={() => setLoginWallVisible(false)}
      />
    </div>
  );
};

export const StartupCard = ({ startup, onChange }: StartupProps) => (
  <ProjectCard
    id={startup.id}
    image={startup.image}
    name={startup.name}
    subtitle={startup.subtitle}
    description={startup.description}
    userData={startup.user}
    createdAt={startup.createdAt}
    ratingCount={startup.ratingGroup.ratingCount}
    averageRating={startup.ratingGroup.averageRating}
    ratingGroupId={startup.ratingGroup.id}
    commentsCount={startup.commentGroup.commentsCount}
    shareLink={`${shareLinkBase}/startups/${startup.uniqueTag}`}
    shareCount={startup.shareCount}
    commentPostUrl={`/comment/post/${startup.commentGroup.id}`}
    commentGetUrl={`/comment/get/${startup.commentGroup.id}`}
    ratingUrl={`/projects/${startup.id}/rate`}
    userRating={startup.ratingGroup.userRating}
    onChange={onChange}
    themes={startup.interestGroups ? startup.interestGroups : (startup.interests.length ? startup.interests : null)}
    pitchLink={startup.pitchLink}
    additionalInfo={startup.additionalInfo}
    commentable={startup.commentable ? startup.commentable : startup.commentGroup.commentable}
    canJoin={startup.canJoin}
    type='projects'
    canWithdraw={startup.canWithdraw}
    canLeave={startup.canLeave}
    ownerBoxTitle='Founder'
    owner={startup.owner}
  />
);

export const IdeaCard = ({ idea, onChange }: IdeaProps) => (
  <ProjectCard
    id={idea.id}
    image={idea.image}
    name={idea.name}
    description={idea.details}
    userData={idea.user}
    createdAt={idea.createdAt}
    ratingCount={idea.ratingGroup.ratingCount}
    averageRating={idea.ratingGroup.averageRating}
    ratingGroupId={idea.ratingGroup.id}
    commentsCount={idea.commentGroup.commentsCount}
    shareLink={`${shareLinkBase}/idea/${idea.uniqueTag}`}
    shareCount={idea.shareCount}
    commentPostUrl={`/comment/post/${idea.commentGroup.id}`}
    commentGetUrl={`/comment/get/${idea.commentGroup.id}`}
    ratingUrl={`/ideas/${idea.id}/rate`}
    userRating={idea.ratingGroup.userRating}
    onChange={onChange}
    themes={idea.interestGroups ? idea.interestGroups : (idea.interests.length ? idea.interests : null)}
    pitchLink={idea.pitchLink}
    additionalInfo={idea.additionalInfo}
    commentable={idea.commentable ? idea.commentable : idea.commentGroup.commentable}
    canJoin={idea.canJoin}
    type='ideas'
    canWithdraw={idea.canWithdraw}
    canLeave={idea.canLeave}
    ownerBoxTitle='Founder'
    owner={idea.owner}
  />
);
export const EventCard = ({ event, onChange }: EventProps) => (
  <ProjectCard
    id={event.id}
    name={event.name}
    image={event.image}
    shareLink={`${shareLinkBase}/events/${event.tag}`}
    description={event.shortDescription}
    type='events'
    city={event.location.city}
    country={event.location.country}
    fee={event.fee}
    deadline={event.deadline}
    commentsCount={event.commentGroup.commentsCount}
    commentPostUrl={`/comment/post/${event.commentGroup.id}`}
    commentGetUrl={`/comment/get/${event.commentGroup.id}`}
    shareCount={event.shareCount}
    onChange={onChange}
    commentable
    userData={event.createdBy}
    owner={event.owner}
    ratingUrl='/rating/post'
    userRating={event.ratingGroup.userRating}
    ratingCount={event.ratingGroup.ratingCount}
    averageRating={event.ratingGroup.averageRating}
    ratingGroupId={event.ratingGroup.id}
    leadButtonText={event.leadButtonText}
    canJoin={event.canJoin}
    tag={event.tag}
    isPreregistration={event.isPreregistration}
    ownerBoxTitle='Organizer'
    needRedirect={event.needRedirect}
    redirectURL={event.redirectURL}
    isPinned={event.isPinned}
  />
);
export const CompetitionCard = ({
  competition,
  onChange,
  onClick,
  leadButtonText,
}: CompetitionProps) => (
  <ProjectCard
    id={competition.id}
    name={competition.name}
    image={competition.image}
    shareLink={`${shareLinkBase}/competitions/${competition.tag}`}
    description={competition.shortDescription}
    type='competitions'
    city={competition.location.city}
    country={competition.location.country}
    deadline={competition.deadline}
    commentsCount={competition.commentGroup.commentsCount}
    commentPostUrl={`/comment/post/${competition.commentGroup.id}`}
    commentGetUrl={`/comment/get/${competition.commentGroup.id}`}
    shareCount={competition.shareCount}
    onChange={onChange}
    commentable
    userData={competition.createdBy}
    owner={competition.owner}
    ratingUrl='/rating/post'
    userRating={competition.ratingGroup.userRating}
    ratingCount={competition.ratingGroup.ratingCount}
    averageRating={competition.ratingGroup.averageRating}
    ratingGroupId={competition.ratingGroup.id}
    onClick={onClick}
    leadButtonText={leadButtonText}
    canJoin={competition.canJoin}
    tag={competition.tag}
    isPreregistration={competition.isPreregistration}
    ownerBoxTitle='Organizer'
    isPinned={competition.isPinned}
  />
);
export const OrganizationCard = ({
  organization,
  onChange,
}: OrganizationProps) => (
  <ProjectCard
    organization={organization}
    id={organization.id}
    name={organization.name}
    image={organization.image}
    shareLink={`${shareLinkBase}/organizations/${organization.tag}`}
    description={organization.shortDescription}
    type='organization'
    city={organization.location?.city}
    country={organization.location?.country}
    deadline={organization.deadline}
    commentsCount={organization.commentGroup.commentsCount}
    commentPostUrl={`/comment/post/${organization.commentGroup.id}`}
    commentGetUrl={`/comment/get/${organization.commentGroup.id}`}
    shareCount={organization.shareCount}
    onChange={onChange}
    commentable
    userData={organization.createdBy}
    ratingUrl='/rating/post'
    userRating={organization.ratingGroup.userRating}
    ratingCount={organization.ratingGroup.ratingCount}
    averageRating={organization.ratingGroup.averageRating}
    ratingGroupId={organization.ratingGroup.id}
    canJoin={false}
    isFollowing={organization.isFollowing}
    tag={organization.tag}
    canFollow={organization.canFollow}
    ownerBoxTitle='Organizer'
  />
);

export const ArticleCard = ({
  article,
  onChange,
  onClick,
}: BlogProps) => (
  <ProjectCard
    article={article}
    id={article.id}
    name={article.title}
    image={article.image}
    shareLink={`${shareLinkBase}/blog/${article.tag}`}
    description={article.shortDescription}
    subtitle={article.subTitle}
    type='article'
    commentsCount={article.commentGroup.commentsCount}
    commentPostUrl={`/comment/post/${article.commentGroup.id}`}
    commentGetUrl={`/comment/get/${article.commentGroup.id}`}
    shareCount={article.shareCount}
    onChange={onChange}
    commentable={user() ? true : false}
    userData={article.createdBy}
    owner={article.createdBy}
    ratingUrl='/rating/post'
    userRating={article.ratingGroup.userRating}
    ratingCount={article.ratingGroup.ratingCount}
    averageRating={article.ratingGroup.averageRating}
    ratingGroupId={article.ratingGroup.id}
    tag={article.tag}
    ownerBoxTitle='Written by'
    themes={article.interests.length && article.interests.map((i:any) => i)}
    onClick={onClick}
    createdAt = {article.createdAt}
  />
);

export const HomePageEventCard = ({ event, onChange }: EventProps) => (
  <ProjectCard
    id={event.id}
    name={event.name}
    image={event.image}
    shareLink={`${shareLinkBase}/events/${event.tag}`}
    description={event.shortDescription}
    type='homepage'
    city={event.location.city}
    country={event.location.country}
    deadline={event.deadline}
    tag={event.tag} 
    onChange={onChange} 
    commentable={false} 
    ownerBoxTitle={''}  />
);


interface Props {
  id: string;
  image: string;
  name: string;
  subtitle?: string;
  description: string;
  userData?: IUser;
  createdAt?: string;
  ratingCount?: number;
  averageRating?: number;
  commentsCount?: number;
  shareLink?: string;
  shareCount?: number;
  commentPostUrl?: string;
  commentGetUrl?: string;
  ratingUrl?: string;
  userRating?: number;
  onChange: () => void;
  themes?: ITheme[];
  pitchLink?: string;
  additionalInfo?: string;
  commentable: boolean;
  canJoin?: boolean;
  type: string;
  canLeave?: boolean;
  canWithdraw?: boolean;
  // Event and Competition Card props
  city?: string;
  country?: string;
  fee?: number;
  deadline?: string;
  ratingGroup?: [];
  commentsGroup?: [];
  ratingGroupId?: string;
  onClick?: () => void;
  leadButtonText?: string;
  tag?: string;
  isPreregistration?: boolean;
  competition?: ICompetition;
  article?: IArticle;
  organization?: any;
  isFollowing?: boolean;
  canFollow?: boolean;
  ownerBoxTitle: string;
  organizer?: any;
  owner?:any;
  needRedirect?: boolean;
  redirectURL?: string;
  isPinned?: boolean;
}

interface StartupProps {
  startup: IStartup;
  onChange: () => void;
}

interface IdeaProps {
  idea: IIdea;
  onChange: () => void;
}

interface EventProps {
  event: IEvent;
  onChange: () => void;
}
interface CompetitionProps {
  competition: ICompetition;
  onChange: () => void;
  leadButtonText: string;
  onClick: () => void;
}
interface OrganizationProps {
  organization: any;
  onChange: () => void;
}

ProjectCard.defaultProps = {
  description: '',
  pitchLink: '',
};
interface BlogProps {
  article: IArticle;
  onChange: () => void;
  onClick: () => void;
}